import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDocumentsComponent {

}
