<div class="agreements dialog flex">
   <div mat-dialog-title class="dialog__title" >
      <h2  i18n>OBCHODNÍ PODMÍNKY</h2> 
  </div>
  <div class="dialog__content overflow-auto"> 
    <div class="formated-text  mb-10">
      <h2>I. Pojmy</h2>
      <ul>
        <li><strong>1. Administrační rozhraní</strong> – webové prostředí, prostřednictvím kterého může Uživatel spravovat
        Služby zakoupené v Internetovém obchodě.</li>
        <li><strong>2. Internetový obchod</strong> – internetový obchod https://shop.ismedia.cz</li>
        <li><strong>3. Konečná cena služeb</strong> – celková cena Služeb vybraných Uživatelem (umístěných do Košíku). S
        touto cenou se Uživatel seznámí před dokončením objednávky.</li>
        <li><strong>4. Košík</strong> – Oddíl Internetového obchodu, kde Uživatel vidí seznam Služeb včetně ceny, které si vybral
        v Internetovém obchodě.</li>
        <li><strong>5. OP</strong> – tyto obchodní podmínky</li>
        <li><strong>6. Poskytovatel</strong> – společnost IS Media s.r.o., IČ 292 10 607, sídlem Filmová 689, Kudlov, 760 01 Zlín,
        zapsaná v obchodním rejstříku vedeném Krajským soudem v Brně, oddíl C, vložka 65955.</li>
        <li><strong>7. Smlouva</strong> – smlouva o poskytování služeb uzavřená mezi Poskytovatelem a konkrétním
        Uživatelem, která vznikne přijetím objednávky ze strany Poskytovatele dle těchto OP.</li>
        <li><strong>8. Uživatel</strong> – podnikající či jiná právnická nebo podnikající fyzická osoba, kterou osoba provádějící
        registraci uvede v Internetovém obchodě v sekci „Firemní údaje“ jako osobu, jejímž jménem se
        registruje.</li>
        <li><strong>9. Služby</strong> – služby, které si může Uživatel objednat od Poskytovatele na Internetovém obchodě, tj.
        konkrétně (Dirigent solo, Dirigent opus, Direktor)</li>
        <li><strong>10. Registrační formulář</strong> – webový formulář, dostupný na Internetovém obchodě, který vyplňuje
        osoba, která má zájem objednat si Služby.</li>
        <li><strong>11. Hudební katalog</strong> – Veškerá hudba, kterou poskytovatel nabízí Uživatelům k užití v Administračním
        rozhraní.</li>
    </ul>


    <h2>II. Úvodní ustanovení</h2>
    <p>Tyto OP se týkají výlučně Poskytovatelem nabízených Služeb. OP tvoří součást smluvního ujednání Poskytovatele a Uživatele. Služby nejsou určeny spotřebitelům, a proto vztahy neupravené OP se řídí zákonem č. 513/1991 Sb., (obchodní zákoník) v platném znění.</p>
    <h2>III. Uzavření Smlouvy</h2>
    <ul>
      <li>
        1. Uživatel si vybere některou ze Služeb kliknutím na tlačítko „Předplatit službu“. Uživatel je povinen uvést správný název své provozovny, plochu provozovny, adresu provozovny, délku požadovaného předplatného a den počátku předplatného. Uživatel má možnost označit políčko „Automaticky prodloužit licenci“ v tom případě se po vypršení doby trvání Služby nebo licence sjednané ve Smlouvě Služba a licence (byla-li obsahem původní Smlouvy) automaticky obnoví ve stejné podobě na stejně dlouhé období, jak bylo ujednáno v původní Smlouvě a Uživateli bude naúčtována částka odpovídající poskytnutým službám. (Účtovaná částka se může lišit od částky účtované dle původní Smlouvy. Uživateli bude automaticky naúčtována částka za obnovenou licenci/službu stejným způsobem, jakým Uživatel uhradil Konečnou cenu služeb původní Smlouvy. Výběr služeb Uživatel potvrdí tlačítkem „Další“. Následně Uživatel vyplní své identifikační údaje (fakturační a kontaktní údaje). Vyplnění všech požadovaných údajů je podmínkou platnosti objednávky. Zadané identifikační údaje Uživatel potvrdí tlačítkem „Další“. V dalším kroku Uživatel zkontroluje veškeré vybrané Služby a vše stvrdí tlačítkem „Vložit do košíku“. Objednávka je dokončena tlačítkem „Zaplatit“, která se nachází v Košíku a zaplacením Konečné ceny služeb Uživatelem. V případě přidání další provozovny, k již existujícímu účtu Uživatel opakuje výše uvedený proces objednávky (kromě vyplnění údajů v sekci „Firemní údaje“, ty zůstávají Uživateli uložené) a do Registračního formuláře doplní údaje o další provozovně.
      </li>
      <li>
        2. Uživatel má možnost Služby otestovat na 14 dní zdarma. Test 14 dní zdarma je určen pouze pro test Služeb mimo obchodní prostory. V rámci testu zdarma Poskytovatel neuděluje licenci pro veřejnou produkci. Test zdarma je jednorázový a po vypršení 14 dní od jeho započetí už jej Uživatel nemůže nikdy u příslušné Služby opětovně využít. Test zdarma je Uživateli dostupný v příslušném Administračním rozhraní.
      </li>
      <li>
        3. Před dokončením objednávky může Uživatel libovolně upravovat Služby vložené do Košíku, tzn. může Služby z Košíku odebírat, může do Košíku vkládat další Služby. Disponovat takto se Službami umístěnými do Košíku může Uživatel až do dokončení objednávky. Po dokončení objednávky je tato objednávka pro Uživatele závazná.
      </li>
      <li>
        4. Smlouva je uzavřena přijetím objednávky Poskytovatelem. O uzavření Smlouvy zašle Poskytovatel Uživateli potvrzení na e-mailovou adresu, kterou uvedl uživatel při registraci v Internetovém obchodě, případně na email uvedený na faktuře, pokud tak zvolil Uživatel. Poskytovatel nemá povinnost k žádnému plnění týkající se Služeb, za které Uživatel ještě neuhradil Konečnou cenu služeb.
      </li>
      <li>
        5. Smlouva je uzavírána na dobu určitou, a to vždy na dobu, za kterou Uživatel předem uhradí sjednanou Konečnou cenu služeb dle čl. IV. odst. 3 těchto OP. Před uplynutím doby trvání Smlouvy dle předchozí věty je Uživatel oprávněn Smlouvu ukončit odstoupením od Smlouvy a zaplacením odstupného (storno poplatku) dle čl. IV. odst. 5 a 6 OP.
      </li>
      <li>
        6. Pokud Uživatel uzavře s Poskytovatelem novou Smlouvu do 14 dní poté, co témuž Uživateli vypršelo předplatné Služby vyplývající ze starší Smlouvy, považuje se za den počátku předplatného vyplývajícího z nové Smlouvy den následující po dni ukončení dříve nejblíže předcházející starší Smlouvy.
      </li>
      <li>7. Pro službu Dirigent je dostupná také mobilní aplikace v internetových obchodech Google Play a App Store.</li>
    </ul>
    <h2>IV. Práva a povinnosti Uživatele</h2>
    <ul>
      <li>1. Uživatel je povinen zvolit službu dle skutečné obchodní plochy své provozovny. Za obchodní plochu provozovny se považují veškeré části provozovny, do kterých mají přístup zákazníci Uživatele, a současně je v těchto částech provozovny umožněn poslech hudby poskytnuté Uživateli na základě Smlouvy.
      <li>2. Uživatel je povinen uvést do Registračního formuláře, který vyplňuje na Internetových stránkách při objednávání Služeb, veškeré požadované údaje.</li>
      <li>3. Uživatel je povinen zaplatit Konečnou cenu služeb v souladu se Smlouvu uzavřenou dle OP. Uživatel může v objednávce zvolit fakturaci za období jednoho, tří, šesti nebo dvanácti měsíců. Úplná úhrada Konečné ceny služeb je podmínkou zahájení poskytování služeb Poskytovatelem (vyjma možnosti testu zdarma uvedeného v čl. III. 2. OP). Konečná cena služeb se považuje za uhrazenou okamžikem jejího připsání na bankovní účet Poskytovatele.</li>
      <li>4. Cenu služeb lze uhradit jedním z následujících způsobů:<br><br>
        <ul>
          <li>a) převodem na bankovní účet Poskytovatele prostřednictvím služby GoPay poskytované společností GOPAY s.r.o., IČ 260 46 768, sídlem Planá 67, PSČ 370 01, zapsané v obchodním rejstříku vedeném Krajským soudem v Českých Budějovicích, oddíl C, vložka 11030.,</li>
          <li>b) online platbou platební kartou prostřednictvím služby GoPay poskytované společností GOPAY s.r.o., IČ 260 46 768, sídlem Planá 67, PSČ 370 01, zapsané v obchodním rejstříku vedeném Krajským soudem v Českých Budějovicích, oddíl C, vložka 11030 (Uživatel bude na platební bránu GoPay přesměrován v rámci procesu objednávky služby).</li>
        </ul>
      </li>
      <li>5. Uživatel je oprávněn ukončit Smlouvu písemným odstoupením od Smlouvy doručeným Poskytovateli, a to pouze ke dni uplynutí celého měsíce trvání Smlouvy. V případě odstoupení od Smlouvy je Uživatel povinen uhradit Poskytovateli odstupné (storno poplatek), které se stanoví jako součet následujících částek:
      <ul>
        <li>a) pevné částky ve výši 300,-- Kč, a</li>
        <li>b) částky, která odpovídá slevě z ceníkové ceny Služeb, kterou Poskytovatel Uživateli za dobu faktického trvání Smlouvy poskytl (byla-li taková sleva poskytnuta). Odstupné (storno poplatek) dle tohoto odstavce však bude činit nejvýše cenu Služeb, kterou Uživatel v souladu s odst. 3 tohoto článku předem uhradil za poskytování Služeb za období po ukončení Smlouvy.</li>
      </ul>
      Odstupné (storno poplatek) dle tohoto odstavce však bude činit nejvýše cenu Služeb, kterou
      Uživatel v souladu s odst. 3 tohoto článku předem uhradil za poskytování Služeb za období po
      ukončení Smlouvy.</li>
      <li>6. V případě odstoupení od Smlouvy Uživatelem dle předchozího odstavce Poskytovatel vrátí Uživateli cenu služeb předem uhrazenou Uživatelem za období po ukončení Smlouvy sníženou o odstupné (storno poplatek) stanovené způsobem dle předchozího odstavce.</li>
      <li>7. Osoba provádějící registraci v Internetovém obchodě odpovídá za správnost uvedených údajů a udržování jejich aktuálnosti a v případě, že není zmocněna k registraci jménem jiné osoby, je zavázaná sama a může být vůči ní uplatněn nárok na náhradu škody z toho vyplývající; vystupuje-li jménem více Uživatelů (organizací), je povinna provést samostatnou registraci za každého Uživatele. Uživatel je povinen zajistit, aby nikdo neměl přístup k jeho přihlašovacím údajům, které vyplnil při registraci a které slouží k přístupu do Administračního rozhraní. Případné porušení této povinnosti jde k tíži Uživatele.</li>
      <li>8. Přihlášení pod nepravdivými údaji nebo pod registračními údaji jiné osoby nebo prolamování technické ochrany představuje obcházení technických prostředků ochrany a může být stíháno jako trestný čin porušování autorského práva.</li>
      <li>9. Uživatel není oprávněn postoupit jakoukoli svou pohledávku za Poskytovatelem na třetí osobu.</li>
      <li>10. Uživatel je povinen obstarat si k užívání Služeb vlastní hardware dle objednané Služby, tedy mít zařízení pro přehrávání (PC, Smart TV, telefon/tablet a jiné), zesilovač nebo reproduktory s integrovaným zesilovačem (např. bedničky k PC) a reproduktory, nebude-li mezi Uživatelem a Poskytovatelem výslovně dohodnuto jinak.</li>
      <li class="highligted">Uživatel je povinen zachovávat mlčenlivost o všech údajích, skutečnostech a informacích, které se v souvislosti s plněním Smlouvy dozvěděl, zejména o skutečnostech tvořících předmět obchodního tajemství, jejichž zveřejnění by bylo způsobilé přivodit Uživateli majetkovou újmu, přičemž tato povinnost mlčenlivosti je závazná po celou dobu trvání Smlouvy a 1 kalendářní rok ode dne jejího ukončení. Pro každý jednotlivý případ porušení povinnosti mlčenlivosti se sjednává smluvní pokuta ve výši 20.000,- Kč, kterou je Uživatel povinen druhé smluvní straně uhradit.</li>
      <li>12. Uživatel je povinen poskytovat Poskytovateli bez zbytečného odkladu veškerou součinnost nezbytnou k plnění závazků Poskytovatele dle Smlouvy a těchto OP, zejména, přesně a konkrétně informovat Poskytovatele o svých požadavcích na vytváření a/nebo aktualizaci ,předávat Poskytovateli veškeré podklady, údaje a informace nezbytné k plnění závazků Poskytovatele dle Smlouvy a těchto OP; V případě prodlení s poskytováním součinnosti dle tohoto odstavce, se o tuto dobu prodlení prodlužuje termín splnění závazku Poskytovatele, pokud ke splnění tohoto závazku je součinnost Uživatele nezbytná;</li>
      <li>13. Uživatel je povinen vytvářet pracovníkům Poskytovatele veškeré podmínky potřebné pro řádný výkon jejich práce dle Smlouvy.</li>
      <li>14. Uživatel je povinen užívat Repertoár Poskytovatele jen skrze přehrávač dostupný v jeho uživatelském profilu v Administračním rozhraní.</li>
    </ul>
    <h2> V. Práva a povinnosti Poskytovatele</h2>
    <ul>
      <li>1. Poskytovatel splní závazek poskytnout Služby dle Smlouvy uzavřené na základě OP tak, že zabezpečí technické řešení a technologické zajištění provozování díla ze záznamu případně i přenos a užití hudebních děl dostupných v rámci Repertoáru dle typu objednané Služby.</li>
      <li>2. Poskytovatel se zavazuje dodávat Uživateli Služby s úrovní dostupnosti (SLA) minimálně 99 % ročně.</li>
      <li>3. Uhradí-li Uživatel Konečnou cenu služeb a Poskytovatel následně není schopen zajistit poskytování Služeb v souladu s předchozím odstavcem V. 2. OP, zavazuje se Poskytovatel tuto skutečnost Uživateli oznámit a vrátit mu jím uhrazenou cenu způsobem dohodnutým s Uživatelem. To neplatí v případě, že poskytování Služeb není možné z důvodů na straně Uživatele.</li>
      <li>4. Poskytovatel musí každou registraci schválit, vyhrazuje si právo registraci Uživatele odmítnout. Poskytovatel má právo kdykoliv odstoupit od Smlouvy, a to i bez udání důvodu, zejména však pokud Uživatel neplní svůj závazek zaplatit řádně a včas Konečnou cenu služeb. K tomuto odstoupení postačí přitom informování Uživatele skrze jeho uživatelský profil v Internetovém obchodě nebo Administračním rozhraní.</li>
      <li>5. V případě porušování OP, útoků na službu, zneužívání služby apod. ze strany Uživatele je Poskytovatel oprávněn blokovat IP adresu nebo zařízení, ze které došlo k protiprávnímu jednání.</li>
      <li>6. Poskytovatel vystavuje daňový doklad zpravidla v elektronické podobě a doručuje jej Uživateli elektronicky na registrační nebo jiný kontaktní e-mail, pokud je zaručena věrohodnost původu a neporušitelnost obsahu daňového dokladu elektronickou výměnou informací. Daňový doklad je Uživateli dostupný taktéž v Internetovém obchodě v jeho uživatelském profilu. V případě, že Uživatel má vůči Poskytovateli jakékoli dluhy po splatnosti (ať již z jakéhokoli právního titulu), není oprávněn užívat Služby ani jakkoli nakládat s obsahem po dobu, kdy prodlení Uživatele trvá, v takovém případě má Poskytovatel možnost takovému konkrétnímu Uživateli odepřít přístup k Službám.</li>
      <li>7. V rámci Služeb, se provozovatel zavazuje monitorovat a zajišťovat funkčnost Administračního rozhraní v časovém rozmezí 24/7, včetně pravidelné údržby systému a průběžného upgradování systému na nové verze.</li>
      <li>8. Aktualizovat dle možností průběžně Repertoár, přičemž aktuální podoba Repertoáru bude vždy připravena k nahlédnutí Uživateli mj. v sídle Poskytovatele.;</li>
      <li>9. Každá Služba má svoje administrační rozhraní a opravňuje Uživatele k přístupu. Poskytovatel se zavazuje poskytnout v rámci Administračního rozhraní Uživateli přístup k funkcím dle příslušné Služby objednané Uživatelem.</li>
    </ul>
  <h2>VI. Licence</h2>
  <ul>
    <li>1. Součástí služeb Dirigent solo a Dirigent opus je licence k veřejnému užití Repertoáru (včetně všech předmětů duševního vlastnictví, které jej tvoří), poskytnutá Poskytovatelem. (Služba Direktor tuto licenci neobsahuje!) Na základě této licence získá Uživatel nevýhradní licenci k užití Repertoáru v prostorách provozovny Uživatele v souladu s § 20 a/nebo § 18 odst. 2 autorského zákona (dle specifikace ve Smlouvě), a to po dobu trvání Smlouvy.</li>
      <li>2. Licence specifikovaná v čl. VI. 3. této smlouvy opravňuje Uživatele k využívání všech nahrávek aktuálně Poskytovatelem zařazených do Repertoáru (dále jen společně „Nahrávky“), a to z hlediska následujících druhů práv: <br><br>
        <ul>
          <li>a) práva výrobce zvukového záznamu Nahrávek;</li>
          <li>b) autorská práva autorů hudby a textu, pokud jde o hudební díla zachycená na Nahrávkách;</li>
          <li>c) práva hudebníků, zpěváků a případně dalších výkonných umělců, jejichž umělecké výkony jsou zachyceny na Nahrávkách.</li>
        </ul>
      </li>
      <li>3. Poskytovatel poskytuje Uživateli oprávnění k užití všech Nahrávek zařazených do Repertoáru v rámci služeb Dirigent Solo a Dirigent opus v níže uvedeném rozsahu:
        <ul>
          <li>- k těmto způsobům užití: provozování Nahrávek ze záznamu (§ 20 odst. 1 zák. č. 121/2000 Sb., autorského zákona); / přehrávání Nahrávek z online uložiště Poskytovatele dle volby Uživatele (§ 18 odst. 2 zák. č. 121/2000 Sb., autorského zákona)</li>
          <li>- v tomto časovém rozsahu: na dobu sjednanou ve Smlouvě.</li>
          <li>- bez množstevního omezení;</li>
          <li>- v tomto místě: výlučně v provozovnách objednatele vymezených ve Smlouvě; smluvní strany si ujednaly, že může dojít ke změně velikosti nebo adresy provozovny, v niž je provozována služba Dirigent Solo nebo Dirigent opus dle Smlouvy, tuto změnu adresy nebo velikosti provozovny je Uživatel povinen nahlásit co nejdříve Poskytovateli. Uživatel a Poskytovatel se dohodnou na podmínkách změny Smlouvy a odpovídající změně místního rozsahu licence.</li>
          <li>- nevýhradně, tj. Poskytovatel není omezen v poskytování oprávnění k užití Nahrávek jiným osobám, ani není sám omezen v možnosti Nahrávky dále užívat;</li>
         </ul>
      </li>
      <li class="highligted">4. V případě, že Uživatel využívá jakékoliv jiné hudební nahrávky mimo Repertoár, je povinen práva k takovým hudebním nahrávkám vypořádat zásadně na svoji odpovědnost a náklady u příslušných nositelů práv. Pro případ, že Uživatel nesplní svou povinnost dle předešlé věty a v důsledku toho dojde k poškození dobrého jména nebo pověsti Poskytovatele (například proto, že Uživatel nepravdivě veřejně uvádí, že práva ke všem jím užívaným nahrávkám vypořádal u Poskytovatele), <strong>je Poskytovatel oprávněn po Uživateli požadovat uhrazení smluvní pokuty ve výši 100.000,- Kč v každém takovém jednotlivém případě, a dále náhradu způsobené újmy v plné výši.</strong> </li>
      <li>5. Uživatel bere na vědomí, že přehrávač v uživatelském profilu v Administračním rozhraní zasílá informace (logy) o všech přehrávaných Nahrávkách, a to z důvodu spravedlivého rozúčtování odměn umělcům. Pokud nejsou tyto logy dlouhodobě zasílány je Uživatel povinen zdůvodnit tuto skutečnost a Poskytovatel je u takového Uživatele oprávněn provést kontrolu. Pokud se prokáže při kontrole Poskytovatele, že Uživatel užívá jiné hudební nahrávky mimo Repertoár, ke kterým nemá vypořádána práva, upozorní Poskytovatel Uživatele na tuto skutečnost. Pokud se prokáže, že Uživatel užívá jiné hudební nahrávky mimo Repertoár, ke kterým nemá vypořádaná práva i po upozornění Poskytovatele, má Poskytovatel nárok na okamžité ukončení Služeb tomuto Uživateli a také na okamžitou výpověď Smlouvy s Uživatelem bez nároku Uživatele na jakoukoliv náhradu.</li>
      <li>6. Uživatel bere na vědomí, že licence, která je součástí Služeb Dirigent solo a Dirigent opus se vztahuje pouze na Nahrávky, které jsou aktuálně (tedy v okamžiku, kdy je Uživatel má zájem využít) součástí Repertoáru.</li>
      <li>7. Uživatel není oprávněn do Nahrávek jakkoliv zasahovat ani je užívat jakkoliv jinak, než za shora uvedených podmínek. Uživatel proto mimo jiné bere na vědomí, že udělená licence jej neopravňuje k zařazování jakékoliv Nahrávky do zvukové nebo zvukově obrazové reklamy ani do jiného nově vytvářeného díla.</li>
      <li>8. Uživatel není povinen poskytnutou licenci využít, tím však není dotčen nárok Poskytovatele na finanční odměnu ujednanou ve Smlouvě.</li>
      <li>9. Poskytnutí licence bude ze strany Poskytovatele potvrzeno licenčním certifikátem, který si může Uživatel stáhnout z Internetového obchodu po zaplacení Konečné ceny služeb. Licenční certifikát bude taktéž zaslán na registrační nebo jiný kontaktní email Uživatele.</li>
      <li>10. Odměna za poskytnutí licence je součástí Konečné ceny služeb, kterou Uživatel viděl v Košíku v Internetovém obchodě před dokončením objednávky.</li>
      <li>11. Licenci Poskytovatele dle Smlouvy a OP nelze zaměňovat s povinností Uživatele hradit jiné zákonné poplatky, zejm. rozhlasové nebo televizní poplatky.</li>
      <li>12. V případě, že Uživatel poruší jakýkoliv závazek uvedený v tomto článku VI., zejména bude-li kteroukoliv Nahrávku užívat ve větším než sjednaném rozsahu, je Poskytovatel oprávněn od Smlouvy odstoupit s okamžitým účinkem.</li>
    </ul>

  <h2>VII.
  Reklamační řád</h2>
  <p>
    Technické vady Služeb je Uživatel povinen neprodleně vytknout. Jiné vady (skryté a právní) je Uživatel povinen vytknout do jednoho týdne poté, co vyjdou najevo. Poskytovatel není odpovědný za dočasnou nepřístupnost Služeb vynucenou technickými důvody nebo důvody údržby (včetně aktualizace SW apod.), a neodpovídá ani za funkčnost internetových stránek třetích osob, na které případně na Internetovém obchodě odkazuje. Poskytovatel taktéž není odpovědný za nedostupnost Služeb na starších verzích operačních systémů zejména Android a Apple ani za nedostupnost Služeb na některých konkrétních typech a modelech hardwaru. Poskytovatel není odpovědný ani za pozdější nedostupnost Služeb z důvodu ukončení podpory hardwaru nebo softwaru pro Služby učiněné třetí stranou. Odpovědnost Poskytovatele za škodu způsobenou Uživateli v souvislosti s provozem Internetového obchodu https://shop.ismedia.cz a poskytováním služby dle těchto OP a Smlouvy je omezena na 1.000,- Kč (slovy: jeden tisíc korun českých) za každý případ způsobení škody, v souhrnu však nejvýše 10.000,- Kč (slovy: deset tisíc korun českých) za škodu způsobenou jednomu Uživateli v jednom kalendářním roce.
  </p>
  <h2>IX.
  Ochrana osobních údajů</h2>

  <ul>
    <li>1. Je-li Uživatel fyzickou osobou, platí pro užití jeho osobních údajů tento článek OP.</li>
    <li>2. Poskytovatel shromažďuje a zpracovává veškeré osobní údaje získané od Uživatele v souvislosti s uzavřením a plněním Smlouvy, resp. těchto OP v souladu s nařízením Evropského parlamentu a Rady (EU) č. 2016/679 ze dne 27. 4. 2016 (dále jen <strong>„GDPR“</strong>).</li>
    <li>3. Správcem osobních údajů je Poskytovatel, tj. společnost IS Media s.r.o., IČ 292 10 607, sídlem Filmová 689, Kudlov, 760 01 Zlín, zapsaná v obchodním rejstříku vedeném Krajským soudem v Brně, oddíl C, vložka 65955.
    <li>4. Kontakty Správce jsou následující:<br><br>Písemně:<br>IS Media s.r.o., se sídlem Filmová 689, Kudlov, 760 01 Zlín, Česká republika<br><br>Elektronicky: <br>podpora@ismedia.cz</li>
    <li>5. Poskytovatel zpracovává osobní údaje Uživatele získané v souvislosti s uzavřením Smlouvy výhradně pro účely plnění Smlouvy podle čl. 6 odst. 1 písm. b) GDPR. Pro účely plnění Smlouvy jsou Poskytovatelem zpracovávány osobní údaje v nezbytném rozsahu, a to jméno, příjmení, adresa bydliště/sídla, adresa provozovny, emailová adresa, telefonní číslo, IČ, DIČ.</li>
    <li>6. Zpracování osobních údajů bude prováděno výhradně Poskytovatelem, a to po dobu nezbytnou ve vztahu k účelu zpracování osobních údajů (osobní údaje budou zpracovávány po dobu trvání Smlouvy a dále po ukončení Smlouvy po dobu nezbytnou pro případ uplatnění nároků ze Smlouvy jednou ze smluvních stran nejdéle však po dobu 10 let).</li>
    <li>7. Příjemci osobních údajů: Správce a pouze smluvně pověření zpracovatelé v podobě účetních, daňových, právních poradců a dodavatelů externích IT služeb. Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní organizaci.</li>

    <li>8. Uživatel má v souvislosti se zpracováním osobních údajů zejména následující práva:
      <ul>
        <li>a. právo na přístup k osobním údajům – Uživatel má právo získat potvrzení, zda Poskytovatel zpracovává jeho osobní údaje, a pokud tomu tak je, má právo požadovat zejména informace o účelech zpracování, kategoriích zpracovávaných osobních údajů, příjemcích či kategoriích příjemců osobních údajů, plánované době zpracování;</li>
        <li>b. právo na opravu osobních údajů – Uživatel má právo požadovat opravu nepřesných osobních údajů či doplnění neúplných osobních údajů;</li>
        <li>c. právo na výmaz osobních údajů – Uživatel má právo požadovat výmaz svých osobních údajů v případech stanovených čl. 17 GDPR, tj. zejména nejsou-li již potřebné pro stanovený účel jejich zpracování, vznese-li Uživatel námitky či jsou-li osobní údaje zpracovávány protiprávně;</li>
        <li>d. právo na omezení zpracování osobních údajů – Uživatel má právo požadovat na nezbytnou dobu omezení zpracování svých osobních údajů, (i) popírá-li přesnost osobních údajů, (ii) zpracování je protiprávní a Uživatel odmítá jejich výmaz, (iii) Poskytovatel údaje již nepotřebuje a Uživatel požaduje omezení zpracování pro určení / výkon / obhajobu svých práv, nebo (iv) Uživatel vznesl námitku proti zpracování svých osobních údajů.</li>
      </ul>
      </li>

    <li>9. Uživatel má právo podat stížnost u dozorového orgánu (tj. Úřad pro ochranu osobních údajů), pokud se domnívá, že zpracováním jeho osobních údajů je porušeno GDPR.</li>
  </ul>

  <h2>X.
  Závěrečné ustanovení</h2>
  <p>
    OP jsou platné a účinné od 15.4.2024 OP jsou nedílnou součástí každé Smlouvy. OP jsou k dispozici v sídle Poskytovatele a na internetových stránkách https://shop.ismedia.cz
  </p>

</div>

</div>
  <div class="dialog__footer">
    <button mat-raised-button (click)="confirm(false)" i18n>Zrušit</button>
    <button mat-raised-button color="primary" (click)="confirm(true)">Souhlasím s podmínkami</button>
  </div>
</div>