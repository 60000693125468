import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrderItem } from 'src/app/models/orders.model';
import { Player, PlayerCategory } from 'src/app/models/player.model';
import { License } from 'src/app/models/license.model';
import { Dayjs } from 'dayjs';

@Component({
  selector: 'app-order-new',
  templateUrl: './order-new.component.html',
  styleUrls: ['./order-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderNewComponent implements OnChanges {
  @Input() orderItem!: OrderItem;
  @Output() orderItemUpdate = new EventEmitter<OrderItem>();
  @Output() onValidation = new EventEmitter<any>();
  newFormGroup: any;
  playerCategoryEnum: typeof PlayerCategory = PlayerCategory;
  minDate = new Date();
  maxFuture = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  constructor(private fb: FormBuilder) {
    let now = new Date().toISOString();
    
    this.newFormGroup = this.fb.group({
      subscriptionLength: new FormControl('12', Validators.required),
      autoRenew: new FormControl(false, Validators.required),
      startDate: new FormControl(now, Validators.required),
      isPlayerValid: new FormControl(false, Validators.requiredTrue),
    });
    this.newFormGroup.valueChanges.subscribe((value: any) => {
      this.updateDataModel(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['orderItem']) {
      this.orderItem = changes['orderItem'].currentValue;
    }
  }
  updateDataModel(value: any) {

    if (value.startDate) {
      this.orderItem.license.startDate = new Date(value.startDate).toISOString();
    }
    this.orderItem.license = Object.assign(this.orderItem.license!, value);
    this.orderItemUpdate.emit(this.orderItem);
    this.validateForm();
  }
  onPlayerUpdate(player: any) {
    this.orderItem.license.player = player as Player;
    this.orderItemUpdate.emit(this.orderItem);
  }
  validateForm() {
    this.onValidation.emit(this.newFormGroup.valid);
  }
  validatePlayer(state: any) {
    this.newFormGroup.get('isPlayerValid').setValue(state);
    this.validateForm();
  }
}
