import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import localeCs from '@angular/common/locales/cs';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavigationComponent } from './navigation/main-navigation/main-navigation.component';
import { MatIconModule } from '@angular/material/icon';
import { HomepageComponent } from './homepage/homepage.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopBarComponent } from './navigation/top-bar/top-bar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CompanySelectComponent } from './company/company-select/company-select.component';
import { CompanySelectDialogComponent } from './company/company-select-dialog/company-select-dialog.component';
import { LoginComponent } from './login/login.component';
import { LoginRecoveryComponent } from './login/login-recovery/login-recovery.component';
import { PasswordChangeComponent } from './login/password-change/password-change.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { OrderStepperComponent } from './orders/order-stepper/order-stepper.component';
import { CompanyRegistrationComponent } from './company/company-registration/company-registration.component';
import { MatStepperModule } from '@angular/material/stepper';
import { OrderNewComponent } from './orders/order-new/order-new.component';
import { MatCardModule } from '@angular/material/card';
import { OrderExtendComponent } from './orders/order-extend/order-extend.component';
import { OrderChangeComponent } from './orders/order-change/order-change.component';
import { OrderAddedComponent } from './orders/order-added/order-added.component';
import { OrderRecapitulationComponent } from './orders/order-recapitulation/order-recapitulation.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CartComponent } from './cart/cart.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CartListComponent } from './cart/cart-list/cart-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CartPaymentComponent } from './cart/cart-payment/cart-payment.component';
import { OrdersComponent } from './orders/orders.component';
import { OrdersHistoryComponent } from './orders/orders-history/orders-history.component';
import { PlansComponent } from './plans/plans.component';
import { PlanItemComponent } from './plans/plan-item/plan-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { OrderMonths } from './orders/order-months.pipe';
import { PlayersListComponent } from './players/players-list/players-list.component';
import { PlayersComponent } from './players/players.component';
import { LicensesComponent } from './licenses/licenses.component';
import { LicensesListComponent } from './licenses/licenses-list/licenses-list.component';
import { PlayerNewComponent } from './players/player-new/player-new.component';
import { LicenseFiltersPipe } from './licenses/license-filters.pipe';
import { RemainingDaysPipe } from './shared/remaining-days.pipe';
import { UsersComponent } from './users/users.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LicenseAssignComponent } from './licenses/license-assign/license-assign.component';
import { FirstRegistrationComponent } from './login/first-registration/first-registration.component';
import { AgreementsComponent } from './login/agreements/agreements.component';
import { SupportNewDeviceComponent } from './support/support-new-device/support-new-device.component';
import { PlansListComponent } from './plans/plans-list/plans-list.component';
import { PlansCategoryPipe } from './plans/plans-category.pipe';
import { LanguageMutationPipe } from './shared/language-mutation.pipe';
import { CurrencySelectionPipe } from './shared/currency-selection.pipe';
import { PlayerDetailComponent } from './players/player-detail/player-detail.component';
import { SupportComponent } from './support/support.component';
import { CompanySettingsComponent } from './company/company-settings/company-settings.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { LicenseCancelComponent } from './licenses/license-cancel/license-cancel.component';
import { OrderPaymentStateComponent } from './orders/order-payment-state/order-payment-state.component';
import {MatChipsModule} from '@angular/material/chips';
import { PlayersMapModule } from "./players/players-map/players-map.module";
import { CompanyErrorComponent } from './company/company-error/company-error.component';
import { OrderOpenComponent } from './orders/order-open/order-open.component';
import { OrderCalculatorComponent } from './orders/order-calculator/order-calculator.component';
import { CustomSnackbarComponent } from './utils/custom-snackbar/custom-snackbar.component';
import { PlanDetailDialogComponent } from './plans/plan-detail-dialog/plan-detail-dialog.component';
import { UserDocumentsComponent } from './users/user-documents/user-documents.component';
import { CompanyNewModalComponent } from './company/company-new-modal/company-new-modal.component';
import { UserPasswordChangeComponent } from './users/user-password-change/user-password-change.component';
import {OverlayModule} from '@angular/cdk/overlay';
import { TagsComponent } from './tags/tags.component';
import { StateTagComponent } from './tags/state-tag/state-tag.component';
import { PlanBenefitsComponent } from './plans/plan-benefits/plan-benefits.component';
import { LoginIllustrationComponent } from './login/login-illustration/login-illustration.component';
import { UserCookiesConsentComponent } from './users/user-cookies-consent/user-cookies-consent.component';
import { AboutUsComponent } from './about/about-us/about-us.component';
import { LoginContactComponent } from './login/login-contact/login-contact.component';
import { TraceService } from '@sentry/angular';
import { Router } from '@angular/router';
import { APP_INITIALIZER } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { Error505Component } from './errors/error-505/error-505.component';

registerLocaleData(localeCs);

@NgModule({
  declarations: [
    AppComponent,
    MainNavigationComponent,
    HomepageComponent,
    DashboardComponent,
    TopBarComponent,
    CompanySelectComponent,
    LoginComponent,
    LoginRecoveryComponent,
    PasswordChangeComponent,
    LoadingComponent,
    CompanySelectDialogComponent,
    OrderStepperComponent,
    CompanyRegistrationComponent,
    OrderNewComponent,
    OrderExtendComponent,
    OrderChangeComponent,
    OrderAddedComponent,
    OrderRecapitulationComponent,
    CartComponent,
    CartListComponent,
    CartPaymentComponent,
    OrdersComponent,
    OrdersHistoryComponent,
    PlansComponent,
    PlanItemComponent,
    OrderMonths,
    PlayersListComponent,
    PlayersComponent,
    LicensesComponent,
    LicensesListComponent,
    PlayerNewComponent,
    LicenseFiltersPipe,
    RemainingDaysPipe,
    UsersComponent,
    UsersListComponent,
    UserSettingsComponent,
    LicenseAssignComponent,
    FirstRegistrationComponent,
    AgreementsComponent,
    SupportNewDeviceComponent,
    PlansListComponent,
    PlansCategoryPipe,
    LanguageMutationPipe,
    CurrencySelectionPipe,
    PlayerDetailComponent,
    SupportComponent,
    CompanySettingsComponent,
    FileUploadComponent,
    LicenseCancelComponent,
    OrderPaymentStateComponent,
    CompanyErrorComponent,
    OrderOpenComponent,
    OrderCalculatorComponent,
    CustomSnackbarComponent,
    PlanDetailDialogComponent,
    UserDocumentsComponent,
    CompanyNewModalComponent,
    UserPasswordChangeComponent,
    TagsComponent,
    StateTagComponent,
    PlanBenefitsComponent,
    LoginIllustrationComponent,
    UserCookiesConsentComponent,
    AboutUsComponent,
    LoginContactComponent,
    Error505Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatMenuModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableModule,
    MatSlideToggleModule,
    MatIconModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatExpansionModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatChipsModule,
    PlayersMapModule,
    OverlayModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'cs-CZ', // 'de' for Germany, 'fr' for France ...
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    MatDatepickerModule,
    MatNativeDateModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
