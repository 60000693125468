import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestResponse } from '../models/request-status';
import { UserService } from './user.service';
import { LOCALE_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private apiServer: string;
  private headers: HttpHeaders;
  private accesToken: string = "";

  constructor(private http: HttpClient, private userService: UserService, @Inject(LOCALE_ID) public locale: string) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;
    this.headers = new HttpHeaders();
    
    
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId + ''
    );

    this.headers = this.headers.set(
      'Content-Type', 'application/json',
    )
  }

  public get ServerUrl(): string {
    return this.apiServer;
  }

  public get accessTokenValue(): string | null {
    return this.userService.user!.accessToken;
  }

  public get isAuthenticated(): boolean {
    return this.userService.user!.accessToken !== null;
  }

  updateToken(token: string) {
    if(this.accessTokenValue) this.accesToken = token;
  }

  login(username: string, password: string) {
    const httpOptions = {
      headers: this.headers,
    };
    const url = `${this.apiServer}/persons/auth`;
    return this.http
      .post<RequestResponse>(
        url,
        { type: 'person', login: username, password },
        httpOptions
      )
  }

  registration(firstname: string, lastname: string,email: string, password: string) {
    const httpOptions = {
      headers: this.headers,
    };
    const url = `${this.apiServer}/persons?createDemo=1`;
    return this.http
      .post<RequestResponse>(
        url,
        { firstname: firstname, lastname: lastname, email: email, password: password },
        httpOptions
      );
  }

  reloginPersonbByToken(token: string) {
    const url = `${this.apiServer}/persons/relogin-person/${token}`;
    return this.http
      .get<RequestResponse>(
        url,
        {headers: this.headers}
      )
  }

  getTemporaryToken() {
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId ? this.userService.selectedCompanyId : 'none' + ''
    );

    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.accessTokenValue
    );
    const url = `${this.apiServer}/persons/relogin-token?ttl=10`;
    return this.http
      .get<RequestResponse>(
        url,
        {headers: this.headers}
      )
  }

  loginRecovery(login: string) {
    const url = `${this.apiServer}/persons/password-request`;
    let reloadPage = environment.appUrl;
    
    if(environment.buildType == "serve") {
      reloadPage = "https://dev-mediacloud.ismedia.cz"
    }
    return this.http
      .post<RequestResponse>(
        url,
        {
          login: login,
          resetFormUrl: reloadPage + '/password-change',
          lang: this.locale,
          companyID:""
        },
        {headers: this.headers}
      )
  }
  changePassword(login: string, token: string, password: string) {
    const httpOptions = {
      headers: this.headers,
    };
    const url = `${this.apiServer}/persons/password-reset`;
    return this.http
      .post<RequestResponse>(
        url,
        {
          login: login,
          token: token,
          password: password,
        },
        httpOptions
      )
  }

  logout() {
    localStorage.removeItem('shopUser');
    localStorage.removeItem('placedOrder');
    window.location.replace('/');
  }

  redirectToOtherProduct(url: string) {
    this.getTemporaryToken().subscribe(
      {next: (data)=> {
          let token = data.data.reloginToken;
          let redirectUrl = url+"?token=" + token;
          console.log(redirectUrl);
          document.location.href = redirectUrl;
        }, error :(error) =>  {
          console.log("redirect error");
        }
      })
  }

  redirectToDirigent(url: string) {
    const server = `${environment.dirigentUrl}/admin`;
    this.getTemporaryToken().subscribe(
      {next: (data)=> {
          let token = data.data.reloginToken;
          let redirectUrl = server + url+"?token=" + token;
          console.log(redirectUrl);
          document.location.href = redirectUrl;
        }, error :(error) =>  {
          console.log("redirect error");
        }
      })
  }
}
