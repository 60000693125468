import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function PassMismatchValidator(pass: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) return null;
    const password = control.parent.get(pass)!.value;
    const confirmPassword = control.value;
    return password !== confirmPassword ? { mismatchedPasswords: true } : null;
  };
}
