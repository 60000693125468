import { Component, ViewEncapsulation } from '@angular/core';
import { OrderService } from '../orders/order.service';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LicensesComponent {
  constructor(private orderService: OrderService) {

  }
  newOrder() {
    this.orderService.openOrderDialog(null);
  }
}
