import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-plan-benefits',
  templateUrl: './plan-benefits.component.html',
  styleUrls: ['./plan-benefits.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanBenefitsComponent {

}
