import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-company-select-dialog',
  templateUrl: './company-select-dialog.component.html',
  styleUrls: ['./company-select-dialog.component.scss'],
})
export class CompanySelectDialogComponent implements OnInit {
  public selectedCompany: Company | null = null;
  constructor(
    public userService: UserService,
    public companyService: CompanyService
  ) {}

  ngOnInit() {}

  companySelect(company: any) {
    this.selectedCompany = company;
  }
  saveCompany() {
    this.userService.activeCompany = this.selectedCompany;
  }
}
