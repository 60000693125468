import { Component, ViewEncapsulation } from '@angular/core';
import { CartService } from './cart.service';
import { MatDialog } from '@angular/material/dialog';
import { CartPaymentComponent } from './cart-payment/cart-payment.component';
import { OrderService } from '../orders/order.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartComponent {
  cartItemsCount: number = 0;
  constructor(private cartService: CartService) {}
  ngOnInit() {
    this.cartService.cartItemsCount$.subscribe((count) => {
      this.cartItemsCount = count;
    });
  }
}
