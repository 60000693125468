import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-login-illustration',
  templateUrl: './login-illustration.component.html',
  styleUrls: ['./login-illustration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginIllustrationComponent {

}
