import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageMutation'
})
export class LanguageMutationPipe implements PipeTransform {

  transform(object: any, langCode: string, key?: string): string {
    if (key) {

      if ( object[0].lang.split("-").length==1 ) {
        let founded = object.find((item: any) => item.lang == langCode.split("-")[1])[key];
        return founded;
      }
      return object.find((item: any) => item.lang == langCode)[key];
    }
    return object.find((item: any) => item.lang == langCode).value;

  }

}
