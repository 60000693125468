<div class="user-password-change dialog">
  <div mat-dialog-title class="dialog__title" >
      <h2 i18n>Změna hesla</h2> 
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
  </div>
  <div class="dialog__content mb-10">
    <form  [formGroup]="passwordFormGroup">
      <div class="u__form-row  flex-col" >
        <mat-form-field class="u__flex--halfrow" >
          <mat-label i18n>Původní heslo</mat-label>
          <input type="password" matInput i18n-placeholder placeholder="Původní heslo" formControlName="oldPassword"  >
          <mat-error class="login-screen__error" *ngIf="f['oldPassword'].hasError('require')" i18n>Vyžadováno</mat-error>
          <mat-error class="login-screen__error" *ngIf="f['oldPassword'].hasError('incorrect')" i18n>Špatně zadané heslo</mat-error>

        </mat-form-field>
        <mat-form-field class="u__flex--halfrow"  >
          <mat-label i18n>Nové heslo</mat-label>
          <input type="password" matInput i18n-placeholder placeholder="Nové heslo" formControlName="newPassword" >
          <mat-error class="login-screen__error" *ngIf="f['newPassword'].hasError('require')" i18n>{{ errorMessage }}</mat-error>
          <mat-error class="login-screen__error" *ngIf="f['newPassword'].hasError('minlength')" i18n>Minimální počet znaků je 8</mat-error>

        </mat-form-field>
        <mat-form-field class="u__flex--halfrow"  >
          <mat-label i18n>Potvrzení nového hesla</mat-label>
          <input type="password" matInput i18n-placeholder placeholder="Potvrzení hesla" formControlName="confirmPassword" >
          <mat-error class="login-screen__error" *ngIf="f['confirmPassword'].hasError('require')"></mat-error>
          <mat-error class="login-screen__error" *ngIf="f['confirmPassword'].hasError('mismatchedPasswords')" i18n>Hesla se neshodují</mat-error>
        </mat-form-field>
      </div>
  </form>
  </div>
  <div class="dialog__footer dialog__footer--reverse license-cancel__footer">
    <button [disabled]="!isValid" (click)="changePassword()" color="primary" mat-raised-button i18n>Změnit heslo</button>
  </div>
</div>
