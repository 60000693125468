import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Order, OrderCoupon, OrderPrice } from 'src/app/models/orders.model';
import { OrderService } from '../order.service';
import { RequestResponse } from 'src/app/models/request-status';
import { CustomUtils } from 'src/app/utils/custom-utils.component';

@Component({
  selector: 'app-order-calculator',
  templateUrl: './order-calculator.component.html',
  styleUrls: ['./order-calculator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderCalculatorComponent implements OnChanges {
  @Input() order?: Order;
  @Input() discountCoupon?: OrderCoupon | null = null;
  @Input() theme?: string | null = "default";
  @Output() onPriceChanged = new EventEmitter<OrderPrice>();

  orderPrice?: OrderPrice;
  constructor(private orderService: OrderService) { }
  totalBasePrice: number = 0;
  ngOnChanges(changes: SimpleChanges) {

    if (changes['order']) {
      this.order = changes['order'].currentValue;
      if (this.order) {
        this.calculatePrice();
      }
    }
    if (changes['discountCoupon']) {
      this.discountCoupon = changes['discountCoupon'].currentValue;
      if (this.order) {
        this.calculatePrice();
      }
    }
  }
  calculatePrice() {
    if (!this.order) {
      return
    }
    if (this.order?.orderItems.length > 0) {
      this.orderService.getOrderPrice(this.order.orderItems, this.order.currency, this.discountCoupon?._id).subscribe({
        next: (data: RequestResponse) => {
          if (data.isSuccess) {
            this.orderPrice = data.data
            this.totalBasePrice = 0;
            this.orderPrice?.orderItems.forEach((item) => { if (item.priceSummary?.basePrice) this.totalBasePrice += item.priceSummary?.basePrice });
            this.onPriceChanged.emit(data.data);
          }
        },
        error: (HttpErrorResponse) => {
          console.log(HttpErrorResponse.message);
        },
      });
    }
  }
  getVatMultiplayer(percent: number) {
    return CustomUtils.getPercentMultiplayer(percent);
  }
}
