import { Component, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { AgreementsComponent } from '../agreements/agreements.component';
import { UserService } from 'src/app/services/user.service';
import { LoggedUser, User } from 'src/app/models/user.model';
import { RequestResponse } from 'src/app/models/request-status';
import { BackendErrorValidator } from 'src/app/shared/backend-errors.directive';
import { PassMismatchValidator } from 'src/app/shared/pass-mismatch.directive';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-first-registration',
  templateUrl: './first-registration.component.html',
  styleUrls: ['./first-registration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FirstRegistrationComponent {
  loading: boolean = false;
  errorMessage: string = '';
  registration: FormGroup;
  registrationSuccessfull = false;
  user: User | null = null;
  emailIsExist = false;
  errorCode: number | null = null;
  formError = false;

  constructor(
    private authorizationService: AuthorizationService,
    private analyticsService: AnalyticsService,
    public dialog: MatDialog
  ) {
    this.registration = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, BackendErrorValidator(this.errorCode)]),
      newPassword: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl('', {
        validators: [Validators.required, PassMismatchValidator('newPassword')],
        updateOn: 'change',
      }),
      agreements: new FormControl(false, Validators.requiredTrue),
    });
  }

  ngOnInit() {}

  get f() {
    return this.registration.controls;
  }


  onSubmit() {
    this.loading = true;
    if (!this.registration.valid) {
      this.loading = false;
      return;
    }
    this.authorizationService
      .registration(this.f['firstname'].value,this.f['lastname'].value, this.f['email'].value, this.f['newPassword'].value)
      .subscribe({
        next: (data: RequestResponse) => {
          if(data.isSuccess) {
            this.registrationSuccessfull = true;
            this.user = data.data.person;

          }
        },
        error: (error: any) => {
          this.formError = true;
          
          setTimeout(()=> {
            switch(error.error.code) {
              case 400: 
              this.registration.get("email")?.setErrors({'incorrect': true});
              break;
            }
            this.errorCode = error.error.code;
          },1)
         
          this.loading = false;
        },
      });
  }
  getErrorMessage() {
    if (this.registration.get('email')!.hasError('email')) {
      return $localize`Váš email není platný`;
    }

    if (this.registration.hasError('mismatchedPasswords')) {
      return $localize`Vaše hesla se neshodují`;
    }
    return '';
  }

  openAgreementsModal() {
    const dialogRef = this.dialog.open(AgreementsComponent, {
      maxWidth: '1000px',
      width: '100%',
      height: '90vh',
      panelClass: '',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.registration.get('agreements')?.setValue(true);
      }
    });
  }
}
