<div class="login-screen login">
    <div class="login-screen__illustration" >
      <app-login-illustration></app-login-illustration>
    </div>
    <div class="login-screen__forms">
          <form class="login-screen__container" [formGroup]="login" *ngIf="!loading; else loadingTemplate" (ngSubmit)="onSubmit()">
            <div  *ngIf="origin=='dirigent'" class="login-screen__logo">
                <img src="assets/img/logos/dirigent_logo.png">
            </div>
            <h2 class="text-center">Příhlášení</h2>
            <div *ngIf="email" class="login-screen__confirmation">
              <h2>Děkujeme za vaši registraci.</h2>
              <p> Váše emailová adresa <strong>{{email}}</strong> byla potvrzena. Nyní se můžete přihlásit do administrace.</p>
            </div>
            <mat-form-field  appearance="outline" class="login-screen__input mat-mdc-form-field--fullwidth mat-mdc-form-field--rounded">
                <mat-label i18n>Přihlašovací email</mat-label>
                <input autocomplete="username" matInput type="email"  i18n-placeholder placeholder="Přihlašovací email" name="firstName" formControlName="loginName">
                <mat-error   class="login-screen__error" *ngIf="loginError && errorCode == 401" i18n>Přihlašovací údaje nejsou správné</mat-error>
                <mat-error class="login-screen__error" *ngIf="loginError && errorCode == 403" i18n>Váš email není aktivovaný.</mat-error>
            </mat-form-field>

            <mat-form-field  appearance="outline" class="login-screen__input  mat-mdc-form-field--fullwidth  mat-mdc-form-field--rounded">
                <mat-label i18n>heslo</mat-label>
                <input autocomplete="current-password" type="password" matInput i18n-placeholder placeholder="Heslo"  formControlName="password">
                <mat-error class="login-screen__error" *ngIf="f['password'].hasError('require')" i18n>Zadejte vaše heslo.</mat-error>
              </mat-form-field>

            <div  class="login-screen__actions">
               <button type="submit" mat-raised-button color="primary" i18n>Přihlásit se</button>
               <button class="mt-2" mat-button (click)="resetPassword()" i18n>Zapoměli jste heslo?</button>
            </div>

            <div *ngIf="!email">
              <div class="login-screen__divider mb-5 mt-5 "><span></span><p>nebo</p><span></span></div>
              <div class="login-screen__registration flex">
                  <button (click)="registration()" mat-raised-button i18n>Vytvořit nový účet</button>
              </div>
            </div>
        </form>
        <app-login-contact class="login-screen__contact"></app-login-contact>
    </div>
</div>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
    