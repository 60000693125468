import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';
import { License } from '../models/license.model';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { RequestResponse } from '../models/request-status';

@Injectable({
  providedIn: 'root',
})
export class LicensesService {
  private headers: HttpHeaders;
  private apiServer: string;
  serverUrl: string;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;

    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId + ''
    );
  }
  fetchLicenses(): Observable<RequestResponse> {
    //let url = `assets/testingData/licenses-list-demo.json`;
    return this.http.get<RequestResponse>( this.apiServer+"/licenses", { headers: this.headers });
  }
}
