import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayersService } from '../players.service';
import { RequestResponse } from 'src/app/models/request-status';
import { Player } from 'src/app/models/player.model';

@Component({
  selector: 'app-player-detail',
  templateUrl: './player-detail.component.html',
  styleUrls: ['./player-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlayerDetailComponent {
  private playerId: string;
  public playerDetail: Player | null = null;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private playersService: PlayersService) {
    console.log(data);
    this.playerId = data.playerId;
  }
  ngOnInit() {
    this.fetchPlayer();
  }
  fetchPlayer() {
    this.playersService.fetchPlayerDetail(this.playerId).subscribe({
      next: (data: RequestResponse) => {
        this.playerDetail = data.data.player;
        console.log(this.playerDetail);
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
}
