
<div class="top-bar">
    <div>
        <div *ngIf="companyId" class="top-bar__logo" >
            <img [src]="logoSrc">
        </div>
    </div>
    <div class="top-bar__user-menu">
        <a href="#" (click)="goToDirigent($event, dirigentUrl)" class="top-bar__dirigent" target="_blank"><img src="assets/img/logos/dirigent_horizontal.svg"></a>
        <span class="top-bar__user-name" *ngIf="user" [matTooltip]="user.person.email"> {{ user.person.firstname }} {{ user.person.lastname.substring(0, 1) }}. </span>
        
        <button  mat-icon-button [matMenuTriggerFor]="profileMenu"  class="top-bar__avatar" i18n-matTooltip  [matTooltip]="'Nastavení'">
            <svg
                id="svg8"
                version="1.1"
                viewBox="0 0 67.733332 67.733335"
                height="256"
                width="256"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:dc="http://purl.org/dc/elements/1.1/">
                <defs
                    id="defs2" />
                <g
                    transform="translate(0,-229.26666)"
                    id="layer1">
                    <path
                    id="path4678"
                    style="color:#000000;stroke-width:1.06909;-inkscape-stroke:none;fill-opacity:1"
                    d="m 56.968964,291.36521 c 2.349363,-0.656 2.401811,-1.64014 3.228079,-2.51373 -1.95814,-2.5128 -10.375356,-4.98527 -12.185776,-5.73594 -1.810417,-0.75069 -3.035388,-1.37411 -4.086616,-4.33302 -0.248148,-0.69846 -0.19706,-0.86981 -0.19005,-0.89777 0.0071,-0.028 0.06439,-0.17316 0.449157,-0.60992 0.769544,-0.87349 2.456849,-2.54664 3.787731,-5.77331 0.562967,-1.36487 0.984421,-2.07904 1.267366,-2.4723 0.282943,-0.39324 0.413643,-0.47885 0.787269,-0.85191 0.373626,-0.37306 0.896818,-1.07869 1.192229,-1.91486 0.295413,-0.83616 0.494409,-1.84068 0.714344,-3.42255 0.250018,-1.79823 -0.02884,-3.21604 -0.474019,-4.16948 -0.445177,-0.95343 -0.718007,-1.24819 -0.806053,-1.57896 -0.39975,-1.5018 -0.367831,-4.80323 -0.565729,-9.72402 -0.16519,-4.10751 -2.628211,-7.17944 -5.721375,-8.91189 -3.093163,-1.73244 -6.815372,-2.39605 -10.342505,-2.39604 -3.527133,1e-5 -7.248792,0.6636 -10.341955,2.39604 -3.093164,1.73245 -5.556184,4.80438 -5.721374,8.91189 -0.197898,4.92079 -0.165978,8.22222 -0.565729,9.72402 -0.08804,0.33077 -0.360877,0.62553 -0.806053,1.57896 -0.445175,0.95344 -0.724038,2.37125 -0.474019,4.16948 0.219935,1.58187 0.418379,2.58639 0.713791,3.42255 0.295413,0.83617 0.818604,1.5418 1.192229,1.91486 0.373626,0.37306 0.504324,0.45867 0.787269,0.85191 0.282945,0.39326 0.702742,1.10743 1.265708,2.4723 1.330883,3.22667 3.020398,4.89982 3.789941,5.77331 0.38477,0.43676 0.441595,0.58195 0.448605,0.60992 0.0071,0.028 0.05811,0.19931 -0.190049,0.89777 -1.051228,2.95891 -2.275646,3.58233 -4.086065,4.33302 -1.810418,0.75067 -10.0371228,3.22314 -11.995264,5.73594 1.2651524,2.16179 2.176238,1.84512 3.228079,2.51373 9.271611,3.19752 35.819467,2.63162 45.700834,0 z" />
                    <g
                    id="g6230" />
                </g>
            </svg>

        </button>
        <button disableTooltipInteractivity="true" #cartTooltip [matTooltipHideDelay]="cartTooltipHide" [matTooltip]="cartTooltipMessage"  [matBadgeHidden]="cartItemCount==0" class="mat-badge--offset-12-12 top-bar__cart"  matBadgeSize="small" [matBadge]="cartItemCount" matBadgePosition="above" matBadgeColor="accent"  mat-icon-button routerLink="/cart">
            <svg
            id="svg8"
            version="1.1"
            viewBox="0 0 67.733332 67.733335"
            height="256"
            width="256"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/">
            <defs
                id="defs2" />
            
            <path
                style="color:#000000;fill:#000000;stroke-width:4.60037;stroke-linecap:round;stroke-linejoin:round;-inkscape-stroke:none"
                d="m 51.385202,38.682626 c -43.9142919,-36.3337572 -21.957145,-18.166879 0,0 z"
                id="path3829" />
            <path
                style="color:#000000;fill:fill-opacity:1;stroke-width:1.33748;stroke-linecap:round;stroke-linejoin:round;-inkscape-stroke:none"
                d="M 17.285983,11.707236 C 16.630764,10.809456 15.653221,8.7615307 14.758943,7.2125961 14.365181,6.5305796 13.690673,5.9478458 13.06162,5.8421092 11.020482,5.4990174 6.7284102,5.1228919 5.232324,5.0415396 3.7362378,4.9601872 2.7791817,5.6891984 2.4903625,7.2214357 2.2015434,8.753673 2.9865055,10.365215 4.2419626,10.56509 l 5.7069603,0.767778 1.6775321,2.910721 z"
                id="path11499" />
            <path
                style="color:#000000;fill-opacity:1;stroke-width:1.33748;stroke-linecap:round;stroke-linejoin:round;-inkscape-stroke:none"
                d="m 11.585059,14.220248 3.944346,14.56546 c 0.02061,0.07521 0.04566,0.149385 0.07379,0.222112 0.480189,1.249338 1.025072,2.572399 1.911055,3.77888 -0.0015,0.0023 -0.0029,0.0044 -0.0044,0.0067 l -6.09467,16.793017 c -0.812234,1.53215 0.298416,3.377937 2.032547,3.377887 h 44.818864 c 1.269144,-8.56e-4 2.297775,-1.029487 2.298633,-2.298632 7.89e-4,-1.27031 -1.028324,-2.300758 -2.298633,-2.301615 H 17.269777 L 21.82975,35.463202 c 0.525474,0.144488 1.079763,0.227328 1.656149,0.227328 h 27.899642 c 2.091108,0 3.932138,-1.009875 5.107079,-2.34857 1.174938,-1.338696 1.841536,-2.931337 2.306831,-4.506334 l 4.30211,-14.563204 c 0.435331,-1.474329 -0.669698,-2.953044 -2.206956,-2.952298 H 17.025509"
                id="path3831" />
            <path
                style="color:#000000;stroke-width:1.33748;stroke-linecap:round;stroke-linejoin:round;-inkscape-stroke:none;fill-opacity:1"
                d="m 15.639474,51.650368 c 0,0 -1.920419,0.861642 -1.920017,1.922629 -1e-6,3.658332 3.005562,6.663892 6.663894,6.663892 3.658332,0 6.666509,-3.00556 6.666506,-6.663892 4.02e-4,-1.062006 -1.922629,-1.922629 -1.922629,-1.922629 z"
                id="path2633" />
            <path
                style="color:#000000;stroke-width:1.33748;stroke-linecap:round;stroke-linejoin:round;fill-opacity:1"
                d="m 46.103734,51.650368 c 0,0 -1.920419,0.861642 -1.920016,1.922629 -2e-6,3.658332 3.005561,6.663892 6.663893,6.663892 3.658333,0 6.666507,-3.00556 6.666506,-6.663892 4.03e-4,-1.062006 -1.922629,-1.922629 -1.922629,-1.922629 z"
                id="path3303" />
            </svg>

        </button>
        <mat-menu #profileMenu="matMenu">
            <button  mat-menu-item routerLink="/company-settings" i18n>Nastavení společnosti</button>
            <button  mat-menu-item routerLink="/user-settings" i18n>Nastavení uživatele</button>
            <button  mat-menu-item routerLink="/user-documents" i18n>Dokumenty</button>
            <!--button  mat-menu-item routerLink="/support" i18n>Podpora</button-->
            <button  mat-menu-item (click)="logout()" i18n>Odhlášení</button>
        </mat-menu>
        <button  mat-icon-button routerLink="/about-us" i18n-matTooltip  [matTooltip]="'O nás'" class="top-bar__avatar">

            <svg
            id="svg8"
            version="1.1"
            viewBox="0 0 67.733328 67.733335"
            height="256"
            width="255.99998"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"><defs
                id="defs2" /><g
                id="g4"
                transform="matrix(1.1484378,0,0,1.1484378,-77.003526,21.082912)"><path
                id="path4"
                style="fill:#1a1a1a;fill-rule:evenodd;stroke:#000000;stroke-width:0.264583"
                transform="rotate(30,95.666034,10.400709)"
                d="M 102.7786,26.73023 C 93.645969,33.018625 80.957993,30.006027 74.861003,20.982546 67.933216,10.729492 71.33763,-3.4223094 81.475089,-10.168507 92.847786,-17.73672 108.46501,-13.939953 115.8596,-2.6880191 124.069,9.8037603 119.87949,26.88757 107.51271,34.929949" /><circle
                style="fill:#ffffff;fill-opacity:1;stroke-width:0.172599"
                id="path2-8"
                cx="95.956635"
                cy="-1.1128051"
                r="3.0978601" /><rect
                style="fill:#ffffff;fill-opacity:1;stroke-width:0.172599"
                id="rect3-6"
                width="5.9783278"
                height="14.782771"
                x="92.762291"
                y="3.9499509"
                rx="1.1413169"
                ry="1.1413169" /></g>
            </svg>

        </button>
        <mat-menu #about="matMenu">
            <button  mat-menu-item routerLink="/about-us" i18n>Kontakty</button>
        </mat-menu>
        <button mat-icon-button class="top-bar__langmenu" [matMenuTriggerFor]="menu" i18n-matTooltip  [matTooltip]="'Jazyk'"><mat-icon color="primary">translate</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="selectLanguage('cs')">
                <img  class="top-bar__langmenu__flag" src="assets/img/flags/cz.svg">
                <span i18n>Čeština</span></button>
            <button mat-menu-item (click)="selectLanguage('en')"><img class="top-bar__langmenu__flag" src="assets/img/flags/gb.svg"><span i18n>Angličtina</span></button>
        </mat-menu>
    </div>
</div>