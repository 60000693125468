import { Component } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  constructor(private analyticsService: AnalyticsService ) {
    
  }
  sendDataLayer(event: string) {
    this.analyticsService.addDataLayer(event)
  }
}
