import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cart-payment',
  templateUrl: './cart-payment.component.html',
  styleUrls: ['./cart-payment.component.scss']
})
export class CartPaymentComponent {
 redirectToPay= true;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.redirectToPay = data.pay;
  }
}
