import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { Player } from 'src/app/models/player.model';
import { GoogleMapsModule } from '@angular/google-maps';

interface Marker {
  title?: string;
  label?: string;
  infoContent: string;
  position: { lat: number; lng: number };
  options: {
    draggable: boolean;
    animation: any;
    icon: string;
  };
}


@Component({
  selector: 'app-players-map',
  templateUrl: './players-map.component.html',
  styleUrls: ['./players-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class PlayersMapComponent {
  @Input() showPlayers?: Array<string> = [];
  @Input() player?: Player;
  @Input() lat?: number = 49.8545376;
  @Input() lng?: number = 15.5714816;

  styledMapType = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#404040",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffe8b8",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ededed",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#c7e5ff",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#c7e5ff",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  zoom = 7;
  center?: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: "roadmap",
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    styles: this.styledMapType,
    disableDefaultUI: true,
  };

  markers: Marker[] = [];
  players: Player[] = []
  title = "My first AGM project";
  apiLoaded: boolean = false;
  infoContent: string = "";
  
  @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;
  constructor(httpClient: HttpClient) {
    if (
      "google" in window &&
      typeof google === "object" &&
      typeof google.maps === "object"
    ) {
      console.log("google map api already loaded");
      this.apiLoaded = true;
      this.getDevicesList();
    } else {
      httpClient
        .jsonp(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyAT1a8Taad8YNHJAJn6am6H7YHGUmg1_gM",
          "callback"
        )
        .subscribe((result) => {
          this.apiLoaded = true;
          this.getDevicesList();
        });
    }
    this.center = {
      lat: this.lat || 49.8545376,
      lng: this.lng || 15.5714816,
    };
  }

  ngOnInit() {
   
  }
  getDevicesList() {
    if ( this.player && this.apiLoaded) {
       this.players.push( this.player);
       this.addMarkers();;
    }
    /*this.devicesSubscription = this.devicesService.devicesSubject.subscribe(
      (devicesList: DevicePreview[]) => {
        if (this.showDevices.length > 0 && this.apiLoaded) {
          this.devices = devicesList.filter((item) => {
            return this.showDevices.includes(item.id);
          });
          this.addMarkers();
        } else {
          this.devices = devicesList;
          this.addMarkers();
        }
      }
    );*/
  }

  addMarkers() {
    this.markers = [];
    this.players.forEach((player) => {
      if (player.gps) {
        const marker = {
          label: player.name,
          title: player.name,
          infoContent: "Zařízení je právě online",
          position: { lat: player.gps.lat, lng: player.gps.lng },
          options: {
            draggable: false,
            animation: google.maps.Animation.DROP,
            icon: "assets/img/icons/online-pin.png",
          },
        };
        this.markers.push(marker);
      }
    });
  }

  openInfoWindow(marker: MapMarker, data: Marker) {
    this.infoContent = `<h3>${data.title}</h3>`;
    this.infoWindow?.open(marker);
  }
}
