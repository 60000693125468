

<div class="main-navigation__list">
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="white" routerLink="/dashboard" ><mat-icon class="mat-icon--white" >dashboard</mat-icon><span i18n>Nástěnka</span></button>
    <!--button class="main-navigation__item" routerLinkActive="is-active" mat-button color="primary" routerLink="/branches" ><mat-icon color="primary">location_city</mat-icon><span i18n>Pobočky</span></button-->
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="white" routerLink="/licenses" ><mat-icon  class="mat-icon--white" >smart_display</mat-icon><span i18n>Licence</span></button>
    <button class="main-navigation__item" routerLinkActive="is-active" mat-button color="white" routerLink="/orders" ><mat-icon  class="mat-icon--white"  >list</mat-icon><span i18n>Objednávky</span></button>
    <!--button class="main-navigation__item" routerLinkActive="is-active" mat-button color="white" routerLink="/users" ><mat-icon  class="mat-icon--white"  >group</mat-icon><span i18n>Úživatelé</span></button-->
</div>

