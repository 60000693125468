import { Component, ViewEncapsulation } from '@angular/core';
import { CompanyRegistrationComponent } from '../company-registration/company-registration.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from '../company.service';
import { RequestResponse } from 'src/app/models/request-status';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanyErrorComponent } from '../company-error/company-error.component';

@Component({
  selector: 'app-company-new-modal',
  templateUrl: './company-new-modal.component.html',
  styleUrls: ['./company-new-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanyNewModalComponent {
  private company: Company | null = null;
  companyRegLoading = false;
  companyRegError = false;
  isValid = false;
  constructor(private dialog: MatDialog, private userService: UserService, private companyService: CompanyService, private snackBar: MatSnackBar,
    ) {

  }
  onCompanyUpdate(event: any) {
    this.company = event;
  }
  saveCompany() {
     if (this.company) {
      this.company.vatnum = this.company.vatnum ? this.company.vatnum : null;
      this.companyRegLoading = true;
      this.companyService.createCompany(this.company).subscribe({
        next: (data: RequestResponse) => {
          if (data.data.company) this.userService.setActiveCompany(data.data.company, true);
          let companyCreatedMessage = $localize`Firma byla úspěšně vytvořena`;
          this.openSnackBar(companyCreatedMessage);
          this.companyRegLoading = false;
        },
        error: (error) => {
          console.log(error);
          
          if (error.error.code == 409) { // for duplicated company
            this.companyError();
            this.validateCompany(false);
          }
          if (error.error.code == 400) { // for duplicated company

          }
          if(error.status == 500) {
            this.companyRegLoading = false;
            this.companyRegError = true;
          }
        }
      })
    }
  }
  validateCompany(state: boolean) {
    console.log(state);
    this.isValid = state
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'save__snack',
    });
  }
  companyError() {
    this.dialog.open(CompanyErrorComponent, {
      disableClose: true,
      width: '400px',
      maxHeight: '100vh',
      maxWidth: '95vw',
      panelClass: 'company-error__dialog'
    });
  }
}
