import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company.model';
import { UserService } from '../services/user.service';
import { CompanyStats } from '../models/company-stats.model';
import { retry } from 'rxjs';
import { RequestResponse } from '../models/request-status';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  apiServer: string;
  serverUrl: string;
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;

    this.serverUrl = `${environment.serverProtocol}://${environment.serverAddress}:${environment.serverPort}`;
    this.headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId ? this.userService.selectedCompanyId : 'none' + ''
    );
  }

  fetchAllCompanies() {
    const url = `${this.apiServer}/companies`;
    return this.http
      .get<RequestResponse>(url, { headers: this.headers })
      .pipe(retry(3));
  }

  fetchCompanyStatistics() {
    const url = `${this.serverUrl}/api2/company/stats`;
    return this.http
      .get<CompanyStats>(url, { headers: this.headers })
      .pipe(retry(3));
  }

  get logo(): string | null {
    if (!this.userService.activeCompany) {
      return null;
    }
  
    if(this.userService.activeCompany.logo) {
      return this.userService.activeCompany.logo as string;
    }
    else {
      return null;
    }
  }

  get companyId(): string | null {
    if (this.userService.activeCompany) {
      return this.userService.activeCompany.ISUID!;
    } else {
      return null;
    }
  }

  set activeCompany(company: Company | null) {
    this.userService.activeCompany = company;
  }

  get activeCompany(): Company | null {
    return this.userService.activeCompany;
  }

  fetchCompanyDetail(id: string) {
    return this.http.get<RequestResponse>(this.apiServer + "/companies/" + id, { headers: this.headers });

  }
  updateCompanyDetail(id: string, userData: any) {
    return this.http.put<RequestResponse>(this.apiServer + "/companies/" + id, userData, { headers: this.headers });
  }

  createCompany(company: Company) {
    return this.http.post<RequestResponse>(this.apiServer + "/companies", company, { headers: this.headers });
  }
  findCompanyByRegnum(regnum:string) {
    this.headers = this.headers.set(
      'x-company-id', 'unknown'
    );
    return this.http.get<any>(this.apiServer + "/companies/regnum-info/" + regnum, { headers: this.headers });
  }
}

