import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function BackendErrorValidator(code: number | null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) return null;
    if (code == 400) {
      return { backendError: true }
    }
    return null
  };
}
