import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'OrderMonths',
})
export class OrderMonths implements PipeTransform {
  transform(n: number) {
    if (n == 1) {
      return n + ' ' + $localize`měsíc`;
    } else if (n < 5 && n !== 0) {
      return n + ' ' + $localize`měsíce`;
    } else {
      return n + ' ' + $localize`měsíců`;
    }
  }
}
