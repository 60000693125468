
<div class="license-cancel dialog">
  <div mat-dialog-title class="dialog__title" >
      <h2 i18n>Zrušení licence</h2> 
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
  </div>
  <div class="dialog__content mb-10">
    <div >
      <p i18n>Pro zrušení licence prosím kontaktujte podporu.</p>
      <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
    </div>
  </div>
  <div class="dialog__footer dialog__footer--reverse license-cancel__footer">
    <button mat-raised-button color="primary" mat-dialog-close i18n>Rozumím</button>
  </div>
</div>
