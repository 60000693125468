import { Company, CompanyUser } from './company.model';

export type User = {
  ISUID: string;
  companies: CompanyUser[];
  email: string;
  login: string;
  firstname: string;
  lastname: string;
  permissions: string[];
  personId: string;
  players: [];
  type: string;
};

export type LoggedUser = {
  accessToken: string;
  person: User;
  selectedCompany?: Company | null;
}

export enum UserRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  USER = 'USER',
}
