
<div class="login-screen login-recovery">
  <div class="login-screen__illustration" >
    <app-login-illustration></app-login-illustration>
  </div>
  <div class="login-screen__forms"  *ngIf="!loading; else loadingTemplate">
    <div *ngIf="!loading; else loadingTemplate" class="login-screen__container">
      <form  *ngIf="!succesfullyChanged && !invalidToken"  [formGroup]="passwordForms" (ngSubmit)="onSubmit()">
        <h2 class="login-screen__title" i18n>Reset hesla</h2>
        <p i18n>Zadejte emailovou adresu pro reset hesla</p>
        <mat-form-field appearance="outline" class="login-screen__input mat-mdc-form-field--fullwidth">
          <input matInput required  type="password" i18n-placeholder placeholder="Nové heslo" name="newPassword" formControlName="newPassword">
            <mat-error class="login-screen__error" *ngIf="f['newPassword'].hasError('minlength')" i18n>Zadejte heslo minimálně 8 znaků</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="login-screen__input mat-mdc-form-field--fullwidth">
            <input matInput required  type="password" i18n-placeholder placeholder="Potvrzení nového hesla" name="confirmPassword" formControlName="confirmPassword">
            <mat-error *ngIf="f['confirmPassword'].hasError('mismatchedPasswords')" class="login-screen__error"  i18n>Hesla se neshodují</mat-error>
          </mat-form-field>
        <div class="login-screen__actions">
          <button type="submit" mat-raised-button color="primary" i18n>Zaslat žádost o nové heslo</button>
        </div>
      </form>
   
      <div *ngIf="succesfullyChanged" class="login-screen__response-status">
        <h2 class="login-screen__title" i18n>Reset hesla</h2>
        <p  class="mb-5" i18n>Reset hesla proběhl úspěšně</p>
        <button  (click)="router.navigate(['/login']);" type="button" mat-raised-button color="primary" i18n>Přihlásit se</button>
      </div>
      <div *ngIf="invalidToken" class="login-screen__response-status">
        <h2 i18n>Neplatná změna</h2>
        <p class="mb-5" i18n>Platnost vašeho odkazu již vypršela. Přesměrujeme vás proto na přihlašovací obrazovku.</p>
        <button type="button" (click)="redirectToLogin(0)"  mat-raised-button color="primary" i18n>Rozumím</button>
      </div>
    </div>
    <app-login-contact class="login-screen__contact"></app-login-contact>

  </div>
</div>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
