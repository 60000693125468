import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { LoggedUser} from '../../models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { CartService } from 'src/app/cart/cart.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from 'src/app/company/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopBarComponent implements OnInit {
  user!: LoggedUser;
  companyId!: string | null;
  pageTitle = '';
  languagesIcons: any = {
    en: 'assets/img/flags/gb.svg',
    cs: 'assets/img/flags/cz.svg',
  };
  activeLangugeCode = 'en';
  cartItemCount: number = 0;
  cartItemCountCache: number = 0;
  cartItemsCountSubscription!: Subscription;
  cartTooltipMessage!: string;
  cartTooltipHide = 1000;
  cartAddMessage: string = $localize`Do košíku byly přidány nové položky.`;
  cartRemoveMessage: string = $localize`Z košíku byly odebrány položky.'`;
  cartEmptyMessage: string = $localize`Košík je nyní prázdný.'`;
  dirigentUrl:string;
  firstInit = true;
  logoSrc:string | null = null;

  constructor(
    public titleService: Title,
    private userService: UserService,
    private authorizationService: AuthorizationService,
    private cartService: CartService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar
  ) {
    this.dirigentUrl = environment.dirigentUrl;
    this.cartItemsCountSubscription =
      this.cartService.cartItemsCount$.subscribe((itemsCount: number) => {
        this.cartItemCount = itemsCount;
        if (!this.firstInit) {
          if (this.cartItemCount > 0) {
            if (this.cartItemCountCache < this.cartItemCount) {
              this.openSnackBar(this.cartAddMessage);
            } else {
              this.openSnackBar(this.cartRemoveMessage);
            }
          } else {
            this.openSnackBar(this.cartEmptyMessage);
          }
        }
        this.cartItemCountCache = this.cartItemCount;
        this.firstInit = false;
      });
  }

  ngOnInit() {
    this.activeLangugeCode = localStorage.getItem('language')!;
    if (!this.activeLangugeCode) {
      this.activeLangugeCode = CustomUtils.getBrowserLanguageCode();
    }
    this.companyId = this.userService.selectedCompanyId;
    this.user = this.userService.user!;
    this.logoSrc = this.companyService.logo;
  }
  ngOnDestroy() {
    this.cartItemsCountSubscription.unsubscribe();
  }

  getTitle() {
    return this.pageTitle;
  }
  selectLanguage(code: string) {
    this.activeLangugeCode = code;
    localStorage.setItem('language', this.activeLangugeCode);
    window.location.replace('/' + this.activeLangugeCode + '/index.html');
  }
  getLanguageTitleByCode(code: string) {
    return CustomUtils.getLanguageTitleByIsoCode(code);
  } 
  getLanguageCodeByIsoCode(code: string) {
    return CustomUtils.getLanguageCodeByIsoCode(code);
  }
  logout() {
    this.authorizationService.logout();
  }

  public get languageIcon(): string {
    return this.languagesIcons[this.activeLangugeCode];
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'top-bar__snack',
    });
  }
  goToDirigent(e: any, url: string) {
    e.preventDefault();
    this.authorizationService.redirectToDirigent("/");
  }
}
