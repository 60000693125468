import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AgreementsComponent {
  constructor(public dialogRef: MatDialogRef<AgreementsComponent>) {}
  confirm(isAccepted: boolean) {
    this.dialogRef.close(isAccepted);
  }
}
