<div class="order-new" >
  <app-player-new [player]="orderItem.license.player" [plan]="orderItem.license.plan" *ngIf="orderItem.license.playerCategory === playerCategoryEnum.RADIO" (playerUpdate)="onPlayerUpdate($event)" (onValidation)="validatePlayer($event)" ></app-player-new>
  
  <form [formGroup]="newFormGroup" class="flex order-new__container">
    <mat-form-field class="flex-1">
      <mat-label i18n>Vyberte začátek</mat-label>
      <input  [min]="minDate" [max]="maxFuture" formControlName="startDate"  matInput [matDatepicker]="picker">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label i18n>Délka licence</mat-label>
      <mat-select formControlName="subscriptionLength" >
        <mat-option value="1" i18n>1 měsíc</mat-option>
        <mat-option value="3" i18n>3 měsíce</mat-option>
        <mat-option value="6" i18n>6 měsíců</mat-option>
        <mat-option value="12" i18n>1 rok (sleva 10%)</mat-option>
      </mat-select>
    </mat-form-field>

    <!--div class="flex items-center">
      <mat-slide-toggle matTooltip="Aktivuje automatické prodlužování a placení služby" i18n-matTooltip color="primary" formControlName="autoRenew" i18n>Automaticky obnovit licenci</mat-slide-toggle>
    </div-->
  </form>
</div>