import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remainingDays'
})
export class RemainingDaysPipe implements PipeTransform {

  transform(days: number): string {

    if(days == 0) {
      return days + $localize` dnů`;
    }
    if(days == 1) {
      return days + $localize` den`;
    }
    if(days > 1 && days < 5) {
      return days + $localize` dny`;
    }

    return days +  $localize` dnů`;
  }

}
