export class Company {
  ISUID?: string;
  companyId?: string;
  name: string;
  street?: string;
  city?: string;
  zip?: string;
  regnum: string;
  vatnum?: string | null;
  note?: string;
  tags?: [];
  logo?: string | ArrayBuffer | null;
  gps?: {
    lat: number;
    lng: number;
  };
  music?: {};
  shownName?: string;
  country: string;
  currency: string;
  branch?: string;
  emailPrimary?: string;
  emailInvoice?: string;
  constructor(name: string) {
    this.name = name;
    this.regnum = "";
    this.currency = "CZK";
    this.country = "CZ";
  }
};

export type CompanyUser = {
  companyISUID: string;
  permissions: [];
  company: Company;
};

