<div class="dialog ">
  <div mat-dialog-title class="dialog__header">
      <h2 i18n>Platba objednávky</h2>
  </div>
  <div class="dialog__content "> 
    <p *ngIf="redirectToPay" class="mb-10" i18n>Během několika sekund budete přesměrování na platební bránu.</p>
    <p *ngIf="!redirectToPay" class="mb-10" i18n>Děkujeme Vám za předplatné našich služeb.</p>

    <app-loading [size]="'medium'"></app-loading>
  </div>
  <div class="dialog__footer dialog__footer--reverse">
  </div>
</div>