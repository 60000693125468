import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'is-shop';
  constructor() {
    console.log("IS media cloud - 7.11.2024");
  }
  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }
  
}
