import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output, ViewEncapsulation } from '@angular/core';
import { PlanItem, PlanTemplate } from 'src/app/models/plan.model';
import { PlansService } from '../plans.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { PlayerCategory } from 'src/app/models/player.model';

@Component({
  selector: 'app-plans-list',
  templateUrl: './plans-list.component.html',
  styleUrls: ['./plans-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlansListComponent {
  plans: PlanItem[] = [];
  @Input() template?: PlanTemplate = PlanTemplate.FULL;
  @Output() planSelection = new EventEmitter<PlanItem>();

  playerCategoryEnum: typeof PlayerCategory = PlayerCategory;

  constructor(private plansService: PlansService, @Inject(LOCALE_ID) public locale: string) {}
  ngOnInit() {
    this.fetchPlans();
  }
  fetchPlans() {
    let countryCode = CustomUtils.getCountryCode(this.locale);
   
    this.plansService.fetchPlans(countryCode, "unknown").subscribe({
      next: (data) => {
        this.plans = data.data.plans as PlanItem[];
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
  onPlanSelection(plan: PlanItem) {
    this.planSelection.emit(plan);
  }
}
