import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../services/authorization.service';
import { PlanItem } from '../models/plan.model';
import { Observable, Subject, catchError, map } from 'rxjs';
import { UserService } from '../services/user.service';
import { RequestResponse } from '../models/request-status';
import { Dialog } from '@angular/cdk/dialog';
import { PlanDetailDialogComponent } from './plan-detail-dialog/plan-detail-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  private apiServer: string;
  private headers: HttpHeaders;
  public planDetailDialog = new Subject<any>();
  public planBenefitsDialog = new Subject<any>();

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService,

    private dialog: Dialog, 
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;

    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId + ''
    );
  }
  fetchPlansByCategory(category: string): Observable<PlanItem[]> {
    let url = `assets/testingData/plans.json`;
    return this.http.get<PlanItem[]>(url, { headers: this.headers }).pipe(
      map((res) => {
        return res.filter((item) => item.playerCategory == category);
      })
    );
  }

  fetchPlanById(id: string): Observable<PlanItem[]> {
    let url = `assets/testingData/plans.json`;
    return this.http.get<RequestResponse>(url, { headers: this.headers }).pipe(
      map((res) => {
        return res.data.plans.filter((item: PlanItem) => item.id !== id);
      })
    );
  }

  fetchPlans(country: string, companyID:string): Observable<RequestResponse> {
    this.headers = this.headers.set(
      'x-company-id', companyID + ''
    );
    let planCountry = country? `?country=${country}` : `?country=CZ`
    return this.http.get<RequestResponse>(this.apiServer + "/plans" + planCountry , { headers: this.headers });
  }
  openDetailDialog(plan: PlanItem) {
    this.planDetailDialog.next(plan);
  }

  openBenefitsDialog() {
    this.planBenefitsDialog.next(true);
  }
}
