<div class="login-contact">
    <div class="login-contact__container">
        <div class="login-contact__contact">
            <div class="flex flex-col">
                <h5>Kontakt</h5>
                <p>
                    <strong>IS Media s.r.o </strong><br>
                    Filmová 689, Kudlov
                    760 01 Zlín
                </p>
            </div>
            <div class="flex flex-col">
                <p>
                    IČO: 29210607<br>
                    DIČ: CZ29210607
                </p>
            </div>
        </div>
        <div class="login-contact__support">
            <h5>Podpora</h5>
            <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
            <a href="tel:+420 603 910 110">+420 603 910 110</a>
            <a class="login-contact__agreements"  target="_blank"  href="assets/documents/obchodni_podminky.pdf">
                <mat-icon>download</mat-icon>
                <span i18n>Obchodní podmínky</span>
            </a> 
        </div>
       
    </div>
    <div class="login-contact__gopay">
        <div class="flex">
            <img src="assets/img/banners/gopay.png">
            <img src="assets/img/banners/mastercard.png">
        </div>
    </div>
</div>



