export const testEnvironment = {
  production: false,
  serverProtocol: 'http',
  serverAddress: 'localhost',
  serverPort: '4201',
};

export const environment = {
  buildType: "development",
  production: true,
  serverProtocol: 'https',
  serverAddress: 'aws-market.ismedia.cz',
  apiAddress: 'dev-api.ismedia.cz',
  apiVersion: 'v2',
  serverPort: '443',
  cdnAddress: 'https://dev-market.iscdn.cz',
  appUrl: 'https://dev-mediacloud.ismedia.cz',
  dirigentUrl:  'https://dev-dirigent.ismedia.cz',
  radioUrl:  'https://dev-play.ismedia.cz'
};
