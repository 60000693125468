import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { PassMismatchValidator } from 'src/app/shared/pass-mismatch.directive';
import { UsersService } from '../users.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SaveSnackbarComponent } from 'src/app/utils/save-snackbar/save-snackbar.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-password-change',
  templateUrl: './user-password-change.component.html',
  styleUrls: ['./user-password-change.component.scss']
})
export class UserPasswordChangeComponent {
  passwordFormGroup: FormGroup;
  isValid = false;
  errorMessage = "";
  constructor( private dialogRef: MatDialogRef<UserPasswordChangeComponent>, private snackBar: MatSnackBar, private fb: FormBuilder, private usersService: UsersService, private userService: UserService) {
    this.passwordFormGroup = this.fb.group({
      oldPassword: new FormControl(''),
      newPassword: new FormControl('', {
        validators: [Validators.required, Validators.minLength(8)],
        updateOn: 'change',
      }),
      confirmPassword: new FormControl('', {
        validators: [Validators.required, PassMismatchValidator('newPassword')],
        updateOn: 'change',
      }),
    });
    this.passwordFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  get f() {
    return this.passwordFormGroup.controls;
  }

  updateDataModel(value: any) {
    this.isValid = this.passwordFormGroup.valid;
  }
  changePassword() {
    if(this.userService.userISUID) {
      this.usersService.passwordChange(
        this.userService.userISUID, 
        this.passwordFormGroup.get("oldPassword")?.value, 
        this.passwordFormGroup.get("newPassword")?.value 
        ).subscribe({
          next: (data) => {
            this.dialogRef.close();
            this.snackBar.openFromComponent(SaveSnackbarComponent, {
              duration: 4000,
            });
          },
          error: (error: any) => {
            console.log(error);
            switch(error.error.code) {
              case 401: 
                this.errorMessage = $localize`Špatně zadané původní heslo`;
                this.passwordFormGroup.get("oldPassword")?.setErrors({'incorrect': true});
                break;
              case 403: 
                this.errorMessage = $localize`Heslo je Příliš slabé`;
                this.passwordFormGroup.get("newPassword")?.setErrors({'incorrect': true});
                break;
            }
          },
        })
    }
    
  }
}
