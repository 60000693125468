<div *ngIf="plan">
  <mat-card class="mdc-card--blue-shadow flex flex-1 plan-item plan" *ngIf="template == planTemplateEnum.FULL"  [ngClass]="'plan-item--'+plan.planType">

      <div class="plan-item__subtitle"  *ngIf="plan.planType == planTypeEnum.SOLO" i18n>zákadní</div>
      <div class="plan-item__subtitle"  *ngIf="plan.planType == planTypeEnum.OPUS" i18n>doporučujeme</div>
      <div class="plan-item__subtitle"  *ngIf="plan.planType == planTypeEnum.MAGNUM" i18n>individuální</div>
      <div (click)="selectionAction()" class="plan-item__title flex flex-col"> 
        <span i18n>Tarif</span>
        <h2><span>{{ plan.title }}</span></h2>
      </div>

      <div *ngIf="plan.image" class="plan-item__illustration ">
        <img  [src]="plan.image">
      </div>
    <mat-card-content>


      <ul class="plan-item__features" >
        <li *ngFor="let feature of plan.features">
          <img *ngIf="plan.planType == planTypeEnum.SOLO" src="assets/img/icons/note-simple.svg" class="plan-item__features__icon"  >
          <img *ngIf="plan.planType == planTypeEnum.OPUS" src="assets/img/icons/note-medium.svg" class="plan-item__features__icon"  >
          <img *ngIf="plan.planType == planTypeEnum.MAGNUM" src="assets/img/icons/note-double.svg" class="plan-item__features__icon"  >
          {{ feature.title | languageMutation:locale }}
        </li>
      </ul>

      <div class="plan-item__price">
        <span i18n> od </span>
        <h3>{{ plan.prices  | currencySelection: companyCurrency }}</h3>
        <span i18n> / měsíc </span>
      </div>
      <div class="plan-item__price-vat">
        <h3>{{ calculateVatPrice(plan.prices)  | currencySelection: companyCurrency }} </h3> <span>&nbsp;s DPH </span>
      </div>
    </mat-card-content>
    <button *ngIf="template == planTemplateEnum.FULL" class="plan-item__order mat-mdc-raised-button--shadow" mat-raised-button color="primary" (click)="openOrderStepper()" i18n>Předplatit službu</button>
    <button mat-button  style="width: 150px;" class="ml-auto mr-auto mt-2" (click)="openDetailDialog()" i18n>Více informací</button>

  </mat-card>

  <mat-card class="mdc-card--blue-shadow flex flex-1 plan-item plan" *ngIf="template == planTemplateEnum.COMPACT || template ==  planTemplateEnum.SELECTION "  [ngClass]="'plan-item--'+plan.planType">

    <div class="plan-item__subtitle"  *ngIf="plan.planType == planTypeEnum.SOLO" i18n>zákadní</div>
    <div class="plan-item__subtitle"  *ngIf="plan.planType == planTypeEnum.OPUS" i18n>doporučujeme</div>
    <div class="plan-item__subtitle"  *ngIf="plan.planType == planTypeEnum.MAGNUM" i18n>individuální</div>
    
    <div (click)="selectionAction()" class="plan-item__title flex flex-col"> 
      <span i18n>Tarif</span>
      <h2><span>{{ plan.title }}</span></h2>
    </div>

    <div *ngIf="plan.image" class="plan-item__illustration ">
      <img  [src]="plan.image">
    </div>
  <mat-card-content>


    <div class="plan-item__price">
    
      <span i18n> od </span> <h3>{{ plan.prices  | currencySelection: companyCurrency }}</h3> <span i18n> / měsíc </span>
    </div>
    <div class="plan-item__price-vat">
      <h3>{{ calculateVatPrice(plan.prices)  | currencySelection: companyCurrency }} </h3> <span i18n>&nbsp;s DPH </span>
    </div>
  </mat-card-content>
  <button class="plan-item__order " mat-raised-button color="primary" (click)="selectionAction()"><span *ngIf="template == planTemplateEnum.SELECTION" i18n>Vybrat tarif</span>  <span *ngIf="template != planTemplateEnum.SELECTION" i18n>Předplatit službu</span> </button>

  <button mat-button  style="width: 150px;" class="ml-auto mr-auto mt-2" (click)="openDetailDialog()" i18n>Více informací</button>

</mat-card>

</div>