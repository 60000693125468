

<mat-card class="about-us card card--large card--soft-shadow">
    <mat-card-title><mat-icon color="primary">help</mat-icon><span i18n>Podpora</span></mat-card-title>
    <div class="about-us__container">
        <mat-card class="about-us__card">
            <mat-card-header>
                <mat-card-title>IS Media s.r.o.</mat-card-title>
            </mat-card-header>
            <mat-card-content class="about-us__contacts">
                <div>
                    <mat-icon class="about-us__icon" aria-hidden="false" aria-label="Contact" fontIcon="contact_page"></mat-icon>
                </div>
                <div>
                    <h4 i18n>Kontaktní údaje:</h4>
                    <p>Filmová 689</p>
                    <p>760 01 Zlín</p>
                    <p>Loc: 49°12'2.744"N, 17°40'14.915"E</p>
                    <br>
                    <h4 i18n>Fakturační údaje:</h4>
                    <p>Filmová 689, Kudlov</p>
                    <p>760 01 Zlín</p>
                    <p i18n>IČO: 29210607</p>
                    <p i18n>DIČ: CZ29210607</p>
                </div>
              
            </mat-card-content>
           
        </mat-card>

        <mat-card class="about-us__card">
            <mat-card-header>
                <mat-card-title i18n>Technická podpora</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div>
                    <mat-icon class="about-us__icon" aria-hidden="false" aria-label="Contact" fontIcon="contact_support"></mat-icon>
                </div>
                <div>
                    <h4>Martin Tomášek</h4>
                    <p i18n>podpora Web Rádio, administrativa IS systémů</p>
                    <p (focus)="sendDataLayer('click_phone')" i18n>Tel: +420 603 910 110</p>
                    <a (focus)="sendDataLayer('click_mail')" class="mail-link" href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
                </div>
            </mat-card-content>
           
        </mat-card>
        

    </div>
  </mat-card>
  