import { Pipe, PipeTransform } from '@angular/core';
import { PlanItem } from '../models/plan.model';
import { PlayerCategory } from '../models/player.model';

@Pipe({
  name: 'plansCategory',
})
export class PlansCategoryPipe implements PipeTransform {
  transform(items: PlanItem[], category: string): PlanItem[] {
    let categoryEnum =
      PlayerCategory[category as keyof typeof PlayerCategory];
    return items.filter((item) => item.playerCategory == categoryEnum);
  }
}
