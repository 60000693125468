 <div class="order-stepper dialog">
  <div mat-dialog-title class="dialog__title" >
      <h2 i18n>Jak přidat nové zařízení</h2> 
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
  </div>
  <div class="dialog__content">
    <mat-stepper  linear #supportNewDevice>
      <mat-step label="Instalace WebTV">
        <div class="mb-10 mt-10">
          <h3 class="mt-10">Pro televize LG signage</h3>
          <p>Televize LG mají operační sytém WebOS. Podrobný návod na instalaci aplikace naleznete na: </p>
          <a href="https://blog.ismedia.cz/webtv/webos/lg-webos" target="_blank">Instalace WebTV na WebOS</a>
          
          <h3  class="mt-10">Pro televize Samsung signage</h3>
          <p>Televize Samsung mají operační sytém Tizen. Podrobný návod na instalaci aplikace naleznete na: </p>
          <a href="https://blog.ismedia.cz/webtv/tizen/tizen" target="_blank">Instalace WebTV na Tizen</a>
      
          <h3  class="mt-10">Pro televize s android TV</h3>
          <p>Televize LG mají operační sytém WebOS. Podrobný návod na instalaci aplikace naleznete na: </p>
          <a href="https://blog.ismedia.cz/webtv/android/android" target="_blank">Instalace WebTV na Android TV</a>
        </div>
      </mat-step>
      <mat-step label="Aktivace TV">
        <div class="mb-10 mt-10">
            <p>Spusťe aplikaci a zadejte 8 mistný kód</p>
            <form class="flex">
              <mat-form-field class="flex-1 mat-mdc-form-field--fullwidth">
                <mat-label i18n>Aktivační kód</mat-label>
                <input matInput i18n-placeholder placeholder="Aktivační kód"   >
              </mat-form-field>
            </form>
            <p>Vyberte uložiště na keré se budou videa ukládat</p>
          </div>
      </mat-step>
      <mat-step label="Přiřazení k licenci">
        <div class="mb-10 mt-10">
          <p>Přiřaďte aktivované zařízení k vaší licenci</p>
        </div>
      </mat-step>
    </mat-stepper>
  </div>

</div>
