import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from '../services/authorization.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { RequestResponse } from '../models/request-status';
import { AnalyticsService } from '../services/analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  loading: boolean = false;
  loginError: boolean = false;
  errorCode: number | null = null;
  login: FormGroup = new FormGroup({
    loginName: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  isConfirmation = false;
  email: string | null = null;
  origin: string | null = null;
  constructor(
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    
    this.route.queryParams.subscribe(params => {
      this.email = params['emailConfirmed'];
      this.origin = params['origin'];
      this.login.get("loginName")?.setValue(this.email);
    });
  }

  ngOnInit() {
    this.login.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
    if (this.email) this.analyticsService.addDataLayer("registration");
  }

  updateDataModel(value: any) {
    if( this.login.get("loginName")?.hasError('incorrect') ) {
      this.loginError = false;
      this.login.get("loginName")?.setErrors({'incorrect': false});
      this.login.get("loginName")?.updateValueAndValidity();
    }
  }


  get f() {
    return this.login.controls;
  }


  resetPassword() {
    this.router.navigate(['/login-recovery']).then(() => {});
  }

  registration() {
    this.router.navigate(['/registration']).then(() => {});
  }

  onSubmit() {
    this.loading = true;
    if (this.login.invalid) {
      this.loginError = true;
      this.loading = false;
      return;
    }

    this.authorizationService
      .login(this.f['loginName'].value, this.f['password'].value)
      .subscribe({
        next: (response: RequestResponse) => {
          this.userService.saveLocalUser(response.data);
          this.authorizationService.updateToken(response.data?.accessToken);
          this.router.navigate(['/dashboard']);
        },
        error: (error: any) => {
          console.log(error);
          this.loading = false;

          setTimeout(()=> {
            switch(error.error.code) {
              case 401: 
              this.login.get("loginName")?.setErrors({'incorrect': true});
              break;
              case 403: 
              this.f['password'].setValue('',  { emitEvent: false });
              this.login.get("loginName")?.setErrors({'incorrect': true});
              break;
            }
            this.loginError = true;
            this.errorCode = error.error.code;
          },1)
         
        },
      });
  }
}
