import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-login-contact',
  templateUrl: './login-contact.component.html',
  styleUrls: ['./login-contact.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class LoginContactComponent {

}
