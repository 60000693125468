<div class="player-detail dialog">
  <div mat-dialog-title class="dialog__title" >
      <h2 i18n>Detail přehrávače / provozovny</h2> 
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
  </div>
  <div class="dialog__content mb-10 flex" *ngIf="playerDetail; else loadingTemplate">
    <div  class="player-detail__info  flex-1">
      <div class="player-detail__row">
        <h4 i18n>Název provozovny:</h4><span> {{ playerDetail.name}}</span>
      </div>
      <div class="player-detail__row">
        <h4 i18n>Označení provozovny:</h4><span> {{ playerDetail.branch}}</span>
      </div>
      <div class="player-detail__row">
        <h4 i18n>Adresa:</h4><span> {{ playerDetail.street}}, {{ playerDetail.zip}} {{ playerDetail.city}}</span>
      </div>
      <div class="player-detail__row">
        <h4 i18n>Telefon:</h4><span> {{ playerDetail.tel}}</span>
      </div>
      <div class="player-detail__row">
        <h4 i18n>Poznámka:</h4><span> {{ playerDetail.note }}</span>
      </div>
      <div class="player-detail__row">
        <h4 i18n>Štítky:</h4>
        <mat-chip-listbox class="mt-3" >
          <mat-chip *ngFor="let tag of playerDetail.tags">
            {{ tag }}
          </mat-chip>
        </mat-chip-listbox>
      </div>

    </div>

    <div class="player-detail__location">
      <mat-form-field class="mat-mdc-form-field--fullwidth">
        <mat-label i18n>Odkaz na přehrávač</mat-label>
        <input matInput i18n-placeholder placeholder="odkaz" >
      </mat-form-field>
      
      <app-players-map [player]="playerDetail"></app-players-map>
    </div>
  </div>
  <div class="dialog__footer dialog__footer--reverse">
    <button mat-raised-button mat-dialog-close class="mat-mdc-raised-button--shadow" color="primary" i18n>Zavřít</button>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loading></app-loading>
</ng-template>