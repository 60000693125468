
<mat-card class="licenses card card--large card--soft-shadow">
  <div class="licenses__top-menu" >
    <mat-card-title>
      <mat-icon color="primary">smart_display</mat-icon><span i18n>Aktuální licence</span>
    </mat-card-title>
    <button mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
      (click)="newOrder()" ><span i18n class="u__v-mobile-hidden">Koupit novou licenci</span> <span  class="u__up-v-mobile-hidden" i18n>Nová</span></button>
  </div>
  <div class="licenses__container">
    <app-licenses-list [template]="'accordion'" [assignable]="false" ></app-licenses-list>
  </div>
</mat-card>