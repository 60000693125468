import { Component, ViewEncapsulation } from '@angular/core';
import { CartService } from '../cart.service';
import { Order, OrderCoupon, OrderItem, OrderPrice, OrderType } from 'src/app/models/orders.model';
import { OrderService } from 'src/app/orders/order.service';
import { CartPaymentComponent } from '../cart-payment/cart-payment.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RequestResponse } from 'src/app/models/request-status';
import { CustomUtils } from 'src/app/utils/custom-utils.component';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlayersService } from 'src/app/players/players.service';
import { PlansService } from 'src/app/plans/plans.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

export interface CartItem {
  itemId: number;
  product: string;
  branchName: string;
  startDate: string;
  endDate: string;
  price: number;
}

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CartListComponent {
  displayedColumns = [
    'orderType',
    'playerName',
    'licenseName',
    'startDate',
    'endDate',
    'actions',
    'price',
    'vat',
    'priceWithVat',
  ];
  order: Order | null = null;
  orderTypeEnum: typeof OrderType = OrderType;
  vatRate: number = 0;
  hasDiscountCode = false;
  discountCodeForm: FormControl;
  discountCoupon: OrderCoupon | null = null;
  couponErrorMessage: string = "";

  constructor(
    private cartService: CartService,
    private playerService: PlayersService,
    private dialog: MatDialog,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    private plansService: PlansService,
    private analyticsService: AnalyticsService
  ) {
    this.discountCodeForm = new FormControl("");
  }
  ngOnInit() {
    this.cartService.cartContent$.subscribe((order?: Order) => {
      if (order) { this.order = order } else return;

     if (this.order.orderItems.length > 0) {
        this.orderService.getOrderPrice(this.order.orderItems, this.order.currency).subscribe({
          next: (data: RequestResponse) => {
            if (data.isSuccess) {
              if (this.order) {
                this.order!.totalPrice = data.data.totalPrice;
                this.onPriceChanged(data.data);
              }
            }
          }
        });
      }
    });
  }

  activateDiscount() {
    let code = this.discountCodeForm.value.trim();
    this.orderService.checkDiscountCode(code).subscribe({
      next: (response: RequestResponse) => {
        if (response.data) {
          switch (response.data.state) {
            case "APPLICABLE":
              this.discountCoupon = response.data.coupon as OrderCoupon;
              this.applyDiscount();
              break;
            case "NOT_FOUND":
              this.couponErrorMessage = $localize`Neplatný slevový kód.`;
              this.discountCodeForm.setErrors({ 'incorrect': true });
              break;
            case "TOO_MUCH_USAGE":
              this.couponErrorMessage = $localize`Slevový kód byl již vyčerpán.`;
              this.discountCodeForm.setErrors({ 'incorrect': true });
              break;
            case "INVALID_PERIOD":
              this.couponErrorMessage = $localize`Slevový kód není platný k dnešnímu datu.`;
              this.discountCodeForm.setErrors({ 'incorrect': true });
              break;
          }
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });

  }
  applyDiscount() {
    if (this.order) this.order.discountCode = this.discountCoupon?._id;
    let companyCreatedMessage = $localize`Kupon je aktivní a byl započítán do ceny`;
    this.openSnackBar(companyCreatedMessage);
  }

  resetDiscount() {
    if (this.order) this.order.discountCode = null;
    this.discountCodeForm.setValue("");
    this.discountCoupon = null;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'save__snack',
    });
  }
  onPriceChanged(orderPrice: OrderPrice) {
    this.vatRate = orderPrice.vatRate;

    this.order?.orderItems.forEach((item: OrderItem, index) => {
      var calculatedItem = orderPrice.orderItems.find(calculatedItem => calculatedItem.license.id == item.license.id);
      if(calculatedItem) {
        item.price = calculatedItem.price;
        item.priceSummary =calculatedItem.priceSummary;
        item.license.endDate = calculatedItem.newLicense?.endDate ? calculatedItem.newLicense?.endDate : item.license.endDate ;
        item.license.startDate = calculatedItem.newLicense?.startDate ? calculatedItem.newLicense?.startDate : item.license.startDate ;
      }
    })
  }
  clearCart() {
    this.order = null;
    this.cartService.clearCart();
  }
  removeItemFromCart(index: number) {
    if (this.order) {
      this.order.orderItems.splice(index, 1);
      this.order.orderItems = [...this.order.orderItems];
      if (this.order.orderItems.length == 0) {
        this.clearCart();
      }
      this.cartService.updateCart(this.order);
    }
  }
  payOrder() {
    if (this.order) {
      this.order.returnUrl = environment.appUrl + '/payment-state';
  
      this.orderService.createNewOrder(this.order).subscribe({
        next: (response: any) => {
          if (response.isSuccess) {
            this.order?.orderItems.forEach((orderItem: OrderItem) => {
              if(orderItem.orderType == OrderType.CHANGE) {
                if(orderItem.license.player) {
            

                  this.playerService.updatePlayerInfo(orderItem.license.player).subscribe({
                    next: (response: any) => {},
                    error: (error: any) => {
                      console.log(error);
                    }
                  })
                }
              }
            })
              this.dialog.open(CartPaymentComponent, {
                disableClose: true,
                width: '500px',
                maxHeight: '100vh',
                maxWidth: '800px',
                panelClass: 'cart-payment__addeddialog',
                data: {pay: response.data.order.totalPrice > 0}
              });
            
            let redirect = response.data.order.totalPrice > 0 ? 
              response.data.order.payment.gw_url : response.data.order.payment.gw_url+"&success=true";

            setTimeout(() => {
              this.clearCart();
              window.location.href = redirect;
            }, 3000);

            this.analyticsService.addDataLayer("redirect_to_payment");

          }
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    }
  }

  showDiscountCode() {
    this.hasDiscountCode = true;
  }
  getVatMultiplayer(percent: number) {
    return CustomUtils.getPercentMultiplayer(percent);
  }
  openBenefitsDetail() {
    this.plansService.openBenefitsDialog();
  }
}
