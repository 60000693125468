<div class="order-stepper dialog">
  <div mat-dialog-title class="dialog__title">
    <h2 *ngIf="plan" i18n>Nová objednávka - {{ plan.title }}</h2>
    <button class="dialog__close" mat-icon-button (click)="closeStepper()" i18n-aria-label aria-label="Zavřít dialog">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="order-stepper__stepper" [formGroup]="orderForms">
   
    <mat-stepper linear #orderStepper>
      <mat-step  i18n-label label="Firemní údaje" [stepControl]="f['isCompanyValid']" [completed]="isCompanyStepCompleted">
        <ng-template matStepLabel i18n>Firemní udaje</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__forms" *ngIf="!companyRegLoading">
            
            <div class="order-stepper__step-container">

              <div class="pictogram pictogram--vertical">
                <div>
                  <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <defs>
                      <style>
                  
                        .opacity-25 {
                          opacity: .25;
                        }
                  
                        .opacity-0 {
                          opacity: 0;
                        }
                  
                        .opacity-50 {
                          opacity: .5;
                        }
                        
                      </style>
                    </defs>
                    <g id="Vrstva_1-2" data-name="Vrstva 1">
                      <g>
                        <rect class="opacity-0" width="512" height="512"/>
                        <g class="opacity-50">
                          <path class="cls-3 pictogram--primary" d="m379.81,201.09c2.47,0,4.47-2,4.47-4.47s-2-4.47-4.47-4.47-4.47,2-4.47,4.47,2,4.47,4.47,4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m379.94,331.11c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m137.94,361.23c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m133.47,226.13c0,2.47,2,4.47,4.47,4.47s4.47-2,4.47-4.47-2-4.47-4.47-4.47-4.47,2-4.47,4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m131.41,183.15c2.47,0,4.47-2,4.47-4.47s-2-4.47-4.47-4.47-4.47,2-4.47,4.47,2,4.47,4.47,4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m147.22,321.56l-25-14.43c-1.15-.66-2.56-.66-3.7,0-1.15.66-1.85,1.88-1.85,3.21v28.87c0,1.32.71,2.54,1.85,3.21.57.33,1.21.5,1.85.5s1.28-.17,1.85-.5l25-14.43c1.15-.66,1.85-1.88,1.85-3.21s-.71-2.54-1.85-3.21Zm-23.15,11.23v-16.04l13.89,8.02-13.89,8.02Z"/>
                          <path class="cls-3 pictogram--primary" d="m384.26,114.58l-25-14.43c-1.15-.66-2.56-.66-3.7,0-1.15.66-1.85,1.88-1.85,3.21v28.87c0,1.32.71,2.54,1.85,3.21.57.33,1.21.5,1.85.5s1.28-.17,1.85-.5l25-14.43c1.15-.66,1.85-1.88,1.85-3.21s-.71-2.54-1.85-3.21Zm-23.15,11.23v-16.04l13.89,8.02-13.89,8.02Z"/>
                        </g>
                        <g>
                          <path class="cls-3 pictogram--primary" d="m397.96,249.86c-3.9-3.41-7.26-4.8-11.63-4.8s-8.39,1.57-11.49,4.67l-15.97,15.98v-101.41c0-.6-.56-1.81-1.1-2.39-.29-.44-.67-.83-1.11-1.12l-43.06-43.06c-1.02-1.02-1.82-1.16-2.47-1.16h-148.14c-1.89,0-3.62,1.73-3.62,3.62v269.87c0,1.89,1.73,3.62,3.62,3.62h191.75c1.88,0,3.73-1.75,4.12-3.85v-78.4l38.82-38.57c3.18-3.64,4.8-7.56,4.8-11.63s-1.74-8.56-4.52-11.35Zm-46.33,137.06h-185.02V123.8h140.4v39.99c0,1.89,1.73,3.62,3.62,3.62h40.99v105.53l-56.47,56.48c-.2.23-.6,1.02-.88,1.59l-8.1,23.81h-98.11c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62h100.13c.13.02.26.02.39.02h1.53l25.92-8.8c.56-.28,1.36-.69,1.58-.88l34.01-33.79v68.32Zm-37.37-258.7l31.95,31.95h-31.95v-31.95Zm40.45,177.74l-41,41-19.68,6.56,6.56-19.68,54.12-54.12,9.32-9.32,13.12,13.12-22.44,22.44Zm38.19-38.19l-10.82,10.82-13.03-13.04,10.32-10.32c2-2.2,4.43-3.35,6.95-3.35,2.35,0,4.66,1.01,6.5,2.85,1.82,1.82,2.66,4.03,2.66,6.97,0,.94-.13,1.86-.35,2.74-.48,1.2-1.22,2.31-2.22,3.32Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,266.81h141.62c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-141.62c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,285.86h141.62c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-141.62c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,304.91h122.43c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-122.43c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,323.96h104.16c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-104.16c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,342.82h60.42c1.51,0,3.62-1.82,3.62-3.62,0-1.89-1.73-3.62-3.62-3.62h-60.42c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m220.93,238.14c.57.33,1.21.5,1.85.5s1.28-.17,1.85-.5l77.33-44.64c1.15-.66,1.85-1.88,1.85-3.21s-.71-2.54-1.85-3.21l-77.33-44.64c-1.15-.66-2.56-.66-3.7,0-1.15.66-1.85,1.88-1.85,3.21v89.29c0,1.32.71,2.54,1.85,3.21Zm5.55-86.08l66.22,38.23-66.22,38.23v-76.46Z"/>
                        </g>
                        <path class="opacity-25" d="m188.06,354.82h98.11l8.1-23.81c.29-.57.69-1.36.88-1.59l56.47-56.48v-105.53h-40.99c-1.89,0-3.62-1.73-3.62-3.62v-39.99h-140.4v263.12h185.02v-68.32l-34.01,33.79c-.22.19-1.02.6-1.58.88l-25.92,8.8h-1.53c-.12,0-.25,0-.39-.02h-100.13c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm31.01-209.17c0-1.32.71-2.54,1.85-3.21,1.15-.66,2.56-.66,3.7,0l77.33,44.64c1.15.66,1.85,1.88,1.85,3.21s-.71,2.54-1.85,3.21l-77.33,44.64c-.57.33-1.21.5-1.85.5s-1.28-.17-1.85-.5c-1.15-.66-1.85-1.88-1.85-3.21v-89.29Zm-31.01,113.92h141.62c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-141.62c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,19.05h141.62c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-141.62c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,19.05h122.43c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-122.43c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,19.05h104.16c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-104.16c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,18.86h60.42c1.89,0,3.62,1.73,3.62,3.62,0,1.8-2.11,3.62-3.62,3.62h-60.42c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Z"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="pictogram__text">
                  <h3 i18n>Vaše údaje o společnosti.</h3>
                  <div *ngIf="company" i18n>Zkontrolujte prosím správnost údajů pro vytvoření licenční smlouvy.</div>
                  <div *ngIf="!company" i18n>Zadejte prosím údaje o Vaší společnosti, abychom mohli vytvořit licenční smlouvu.</div>
                </div>
              </div>
              <div *ngIf="!companyRegError" class="overflow-hidden">
                <mat-form-field class="mat-mdc-form-field--fullwidth ">
                  <mat-label i18n>Objednávku zadává</mat-label>
                  <input matInput i18n-placeholder placeholder="Objednávku zadává" formControlName="orderAuthor">
                </mat-form-field>
                <app-company-registration [company]="company" (companyUpdate)="onCompanyUpdate($event)"
                  (onValidation)="validateCompanyStep($event)"></app-company-registration>
              </div>
              <div *ngIf="companyRegError">
                <p i18n>Nepodařilo se zaregistrovat Vaši společnost. Zkuste prosím později, nebo kontaktujte podporu na  <a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>.</p>
              </div>
            </div>
          </div>
          <div class="order-stepper__saving" *ngIf="companyRegLoading">
            <app-loading></app-loading>
          </div>
          <div class="dialog__stepper-footer justify-end">
            <button *ngIf="isNewCompany && companyRegError" mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
            (click)="resetCompany()" i18n>Zadat jinou firmu</button>

            <button *ngIf="isNewCompany" mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
              [disabled]="!f['isCompanyValid'].valid || companyRegLoading" (click)="saveCompany()" i18n>Uložit
              firmu</button>
            <button *ngIf="!isNewCompany" mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
              [disabled]="!f['isCompanyValid'].valid" (click)="nextStep()" i18n>Další</button>
          </div>
        </ng-template>
      </mat-step>
      <mat-step [stepControl]="f['isProductValid']">
        <ng-template matStepLabel i18n>Produkt</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__forms" >
            <!--h3 class="mb-5" *ngIf="orderType==null" i18n>Vyberte typ objednávky</h3-->
            <div class="order-stepper__step-container">
              <div  class="pictogram pictogram--vertical"  *ngIf="plan">
                <div>
                  <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <defs>
                      <style>
                  
                        .opacity-25 {
                          opacity: .25;
                        }
                  
                        .opacity-0 {
                          opacity: 0;
                        }
                  
                        .opacity-50 {
                          opacity: .5;
                        }
                        
                      </style>
                    </defs>
                    <g id="Vrstva_1-2" data-name="Vrstva 1">
                      <g>
                        <rect class="opacity-0" width="512" height="512"/>
                        <g class="opacity-50">
                          <path class="cls-3 pictogram--primary" d="m379.81,201.09c2.47,0,4.47-2,4.47-4.47s-2-4.47-4.47-4.47-4.47,2-4.47,4.47,2,4.47,4.47,4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m379.94,331.11c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m137.94,361.23c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m133.47,226.13c0,2.47,2,4.47,4.47,4.47s4.47-2,4.47-4.47-2-4.47-4.47-4.47-4.47,2-4.47,4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m131.41,183.15c2.47,0,4.47-2,4.47-4.47s-2-4.47-4.47-4.47-4.47,2-4.47,4.47,2,4.47,4.47,4.47Z"/>
                          <path class="cls-3 pictogram--primary" d="m147.22,321.56l-25-14.43c-1.15-.66-2.56-.66-3.7,0-1.15.66-1.85,1.88-1.85,3.21v28.87c0,1.32.71,2.54,1.85,3.21.57.33,1.21.5,1.85.5s1.28-.17,1.85-.5l25-14.43c1.15-.66,1.85-1.88,1.85-3.21s-.71-2.54-1.85-3.21Zm-23.15,11.23v-16.04l13.89,8.02-13.89,8.02Z"/>
                          <path class="cls-3 pictogram--primary" d="m384.26,114.58l-25-14.43c-1.15-.66-2.56-.66-3.7,0-1.15.66-1.85,1.88-1.85,3.21v28.87c0,1.32.71,2.54,1.85,3.21.57.33,1.21.5,1.85.5s1.28-.17,1.85-.5l25-14.43c1.15-.66,1.85-1.88,1.85-3.21s-.71-2.54-1.85-3.21Zm-23.15,11.23v-16.04l13.89,8.02-13.89,8.02Z"/>
                        </g>
                        <g>
                          <path class="cls-3 pictogram--primary" d="m397.96,249.86c-3.9-3.41-7.26-4.8-11.63-4.8s-8.39,1.57-11.49,4.67l-15.97,15.98v-101.41c0-.6-.56-1.81-1.1-2.39-.29-.44-.67-.83-1.11-1.12l-43.06-43.06c-1.02-1.02-1.82-1.16-2.47-1.16h-148.14c-1.89,0-3.62,1.73-3.62,3.62v269.87c0,1.89,1.73,3.62,3.62,3.62h191.75c1.88,0,3.73-1.75,4.12-3.85v-78.4l38.82-38.57c3.18-3.64,4.8-7.56,4.8-11.63s-1.74-8.56-4.52-11.35Zm-46.33,137.06h-185.02V123.8h140.4v39.99c0,1.89,1.73,3.62,3.62,3.62h40.99v105.53l-56.47,56.48c-.2.23-.6,1.02-.88,1.59l-8.1,23.81h-98.11c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62h100.13c.13.02.26.02.39.02h1.53l25.92-8.8c.56-.28,1.36-.69,1.58-.88l34.01-33.79v68.32Zm-37.37-258.7l31.95,31.95h-31.95v-31.95Zm40.45,177.74l-41,41-19.68,6.56,6.56-19.68,54.12-54.12,9.32-9.32,13.12,13.12-22.44,22.44Zm38.19-38.19l-10.82,10.82-13.03-13.04,10.32-10.32c2-2.2,4.43-3.35,6.95-3.35,2.35,0,4.66,1.01,6.5,2.85,1.82,1.82,2.66,4.03,2.66,6.97,0,.94-.13,1.86-.35,2.74-.48,1.2-1.22,2.31-2.22,3.32Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,266.81h141.62c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-141.62c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,285.86h141.62c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-141.62c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,304.91h122.43c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-122.43c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,323.96h104.16c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-104.16c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m188.06,342.82h60.42c1.51,0,3.62-1.82,3.62-3.62,0-1.89-1.73-3.62-3.62-3.62h-60.42c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                          <path class="cls-3 pictogram--primary" d="m220.93,238.14c.57.33,1.21.5,1.85.5s1.28-.17,1.85-.5l77.33-44.64c1.15-.66,1.85-1.88,1.85-3.21s-.71-2.54-1.85-3.21l-77.33-44.64c-1.15-.66-2.56-.66-3.7,0-1.15.66-1.85,1.88-1.85,3.21v89.29c0,1.32.71,2.54,1.85,3.21Zm5.55-86.08l66.22,38.23-66.22,38.23v-76.46Z"/>
                        </g>
                        <path class="opacity-25" d="m188.06,354.82h98.11l8.1-23.81c.29-.57.69-1.36.88-1.59l56.47-56.48v-105.53h-40.99c-1.89,0-3.62-1.73-3.62-3.62v-39.99h-140.4v263.12h185.02v-68.32l-34.01,33.79c-.22.19-1.02.6-1.58.88l-25.92,8.8h-1.53c-.12,0-.25,0-.39-.02h-100.13c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm31.01-209.17c0-1.32.71-2.54,1.85-3.21,1.15-.66,2.56-.66,3.7,0l77.33,44.64c1.15.66,1.85,1.88,1.85,3.21s-.71,2.54-1.85,3.21l-77.33,44.64c-.57.33-1.21.5-1.85.5s-1.28-.17-1.85-.5c-1.15-.66-1.85-1.88-1.85-3.21v-89.29Zm-31.01,113.92h141.62c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-141.62c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,19.05h141.62c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-141.62c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,19.05h122.43c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-122.43c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,19.05h104.16c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-104.16c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,18.86h60.42c1.89,0,3.62,1.73,3.62,3.62,0,1.8-2.11,3.62-3.62,3.62h-60.42c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Z"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="pictogram__text">
                  <h3 i18n>Nová licence</h3>
                  <div i18n>Vyplňte údaje o prodejně, abychom Vám mohli připravit nejlepší nabídku.</div>
                </div>
              </div>

              <div  class="overflow-hidden flex w-full flex-col">
                <div *ngIf="orderType === orderTypeEnum.NEW">
                  <app-order-new *ngIf="plan" [orderItem]="newOrder.orderItems[newOrder.orderItems.length-1]" (orderItemUpdate)="onOrdersItemUpdate($event)"
                    (onValidation)="validateProductStep($event)"></app-order-new>
                  <app-plans-list (planSelection)="onPlanSelection($event)" *ngIf="!plan" [template]="planTemplateEnum.SELECTION"></app-plans-list> 
                </div>

                <div *ngIf="orderType === orderTypeEnum.EXTEND">
                  <h3 class="mb-5" i18n>Prodloužení licence</h3>
                  <app-order-extend [plan]="plan" [orderItems]="newOrder.orderItems"
                    (orderItemUpdate)="onOrdersItemsUpdate($event)"
                    (onValidation)="validateProductStep($event)"></app-order-extend>
                </div>

                <div *ngIf="orderType === orderTypeEnum.CHANGE" class="w-full">
                  <h3 class="mb-5" i18n>Změna licence <span *ngIf="newOrder.orderItems[0].license.plan">na : {{ newOrder.orderItems[0].license.plan.title}}</span></h3>
                  <app-order-change [plan]="plan" [orderItem]="newOrder.orderItems[newOrder.orderItems.length-1]" (orderItemUpdate)="onOrdersItemUpdate($event)"  (onValidation)="validateProductStep($event)"></app-order-change>
                </div>
              </div>
            </div>

            <div *ngIf="newOrder.orderItems.length>0" class="order-stepper__calculator">
              <app-order-calculator *ngIf="f['isProductValid'].valid" (onPriceChanged)="onPriceChanged($event)"
                [theme]="'highlighted'" [order]="newOrder"></app-order-calculator>
            </div>

          </div>
          <div class="dialog__stepper-footer justify-end">
            <button mat-raised-button class="mat-mdc-raised-button--shadow" color="primary"
              [disabled]="!f['isProductValid'].valid" (click)="nextStep()" i18n>Další</button>
          </div>

        </ng-template>
      </mat-step>
      
      <mat-step i18n-label label="Rekapitulace">
        <ng-template matStepLabel i18n>Rekapitulace</ng-template>
        <ng-template matStepContent>
          <div class="order-stepper__forms mb-10">
            <div class=" w-full">
              <app-order-recapitulation [order]="newOrder"></app-order-recapitulation>
              <app-order-calculator *ngIf="f['isProductValid'].valid" (onPriceChanged)="onPriceChanged($event)"
                [theme]="'highlighted'" [order]="newOrder"></app-order-calculator>
            </div>
          </div>
          <div class="dialog__stepper-footer justify-end">
            <button mat-raised-button (click)="previousStep()" i18n>Zpět</button>
            <button mat-raised-button class="mat-mdc-raised-button--shadow" color="primary" (click)="addToCart()"
              i18n>Vložit do košíku</button>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
</div>