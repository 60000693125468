import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-state-tag',
  templateUrl: './state-tag.component.html',
  styleUrls: ['./state-tag.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class StateTagComponent {
  @Input() label: string = '';
  @Input() color?: string = 'grey';
} 
