import { Component } from '@angular/core';

@Component({
  selector: 'app-license-cancel',
  templateUrl: './license-cancel.component.html',
  styleUrls: ['./license-cancel.component.scss']
})
export class LicenseCancelComponent {

}
