<div class="players-map" *ngIf="apiLoaded">
  <google-map
  height="100%"
  width="100%"
  [zoom]="zoom"
  [center]="center!"
  [options]="options"
  >
      <map-marker 
          #marker="mapMarker"
          *ngFor="let markerData of markers; let i = index" 
          (mapClick)="openInfoWindow(marker, markerData)"
          [position]="markerData.position"
          [options]="markerData.options"
          [title]="markerData.title || ''"
          >
      </map-marker>
      <map-info-window>
          <div [innerHTML]="infoContent"></div>
      </map-info-window>
  </google-map>
</div>