import { Injectable } from '@angular/core';

declare var gtag : any;
declare var dataLayer : any; 

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  
  trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    gtag('event', eventName, {
    // event Type - example: 'SCROLL_TO_TOP_CLICKED'
    'event_category': eventCategory,
    // the label that will show up in the dashboard as the events name
    'event_label': eventName,
    // a short description of what happened
    'value': eventDetails
    })
  }

  addDataLayer(event: string) {
    dataLayer.push({
      'event': event,
    })
  }
 

  setConsent(permisions: any) {

    gtag('consent', 'update', {
      'ad_storage': permisions.advertisement ? 'granted' : 'denied' ,
      'ad_user_data': permisions.advertisement ? 'granted' : 'denied',
      'ad_personalization': permisions.advertisement ? 'granted' : 'denied',
      'analytics_storage': permisions.analytics ? 'granted' : 'denied',
      'functionality_storage': permisions.functional ? 'granted' : 'denied',
      'personalization_storage': permisions.functional ? 'granted' : 'denied'
    });
    dataLayer.push({
      event: "update_consent"
   });
  }
}
