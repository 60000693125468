import { Injectable } from '@angular/core';
import { LoggedUser, User } from '../models/user.model';
import { Company } from '../models/company.model';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private loggedUser: LoggedUser | null = null;
  public companyChangeSubject = new Subject<Company>();

  constructor(private router: Router) {
    try {
      let localUser = localStorage.getItem('shopUser');
      if (localUser) {
        this.loggedUser = JSON.parse(localUser);
        if (!this.loggedUser?.person) { // logout user if there is old user saved;
          localStorage.removeItem('shopUser');
          this.router.navigate(['/login']);
        }
      }
    } catch (error) {
      console.log("Can't find user");
      this.router.navigate(['/login']);
    }
  }

  get user(): LoggedUser | null {
    return this.loggedUser;
  }

  get userISUID(): string | null {
    return this.loggedUser?.person.ISUID ? this.loggedUser?.person.ISUID : null;
  }

  set user(user: LoggedUser | null) {
    this.loggedUser = user;
  }

  get fullName(): string {
    return this.loggedUser?.person.firstname + " " + this.loggedUser?.person.lastname;
  }

  get firstName(): string | null {
    return this.loggedUser!.person.firstname;
  }
  get lastName(): string | null {
    return this.loggedUser!.person.lastname;
  }

  public saveLocalUser(user: LoggedUser) {
    this.loggedUser = user;
    if (user.person.companies.length > 0) {
      this.loggedUser.selectedCompany = user.person.companies[0].company;
    }
    localStorage.setItem('shopUser', JSON.stringify(this.loggedUser));
  }



  public hasRoles(roles: string[]): boolean {
    // TODO
    let hasPermission = false;
    roles.forEach((role) => {
      if (this.loggedUser!.person.permissions.includes(role)) {
        hasPermission = true;
        return;
      }
    });

    // return hasPermission;
    //TODO
    return true;
  }

  public hasRole(role: string): boolean {
    // TODO
    //return this.loggedUser!.person.permissions.includes(role);
    return true;
  }

  public hasPrivilege(privilege: string): boolean {
    // TODO
    //return this.loggedUser!.person.permissions.includes(privilege);
    return true;
  }

  public updatePerson(userPerson: User) {
    if (this.loggedUser) {
      this.loggedUser.person.firstname = userPerson.firstname;
      this.loggedUser.person.lastname = userPerson.lastname;
      this.saveLocalUser(this.loggedUser);
    }
  }

  get selectedCompanyId(): string | null {
    if (this.loggedUser?.selectedCompany) {
      return this.loggedUser.selectedCompany.ISUID ? this.loggedUser.selectedCompany.ISUID : null;
    }
    return null;
  }

  get isCompanySelected(): boolean {
    return this.loggedUser!.selectedCompany !== null;
  }

  get isAdmin() {
    // TODO
    return false;
  }
  get activeCompany(): Company | null {
    return this.loggedUser!.selectedCompany!;
  }
  set activeCompany(company: Company | null) {
    if (this.loggedUser && company) {
      this.loggedUser.selectedCompany = company;
      this.companyChangeSubject.next(company);
      localStorage.setItem('shopUser', JSON.stringify(this.loggedUser));
    }
  }

  get activeCompanyISUID(): string | null {
    return this.loggedUser!.selectedCompany!.ISUID ? this.loggedUser!.selectedCompany!.ISUID : null;
  }
  get activeCompanyCurrency(): string {
    let currency = "CZK";

    if (this.loggedUser?.selectedCompany) {
      currency = this.loggedUser!.selectedCompany.currency!;
    }
    return currency;
  }
  public setActiveCompany(company: Company | null, reload?: boolean) {
    this.activeCompany = company;
    if (reload) location.replace("/");
  }

}
