<mat-card class="user-documents card card--large card--soft-shadow">
    <mat-card-title><mat-icon color="primary">description</mat-icon><span i18n>Stažení licencí</span></mat-card-title>
    <div class="user-documents__container">
        <div class="user-documents__cards">
            <mat-card class="user-documents__card">
                <mat-card-header>
                    <mat-card-title i18n>Poskytování licence</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div i18n>Poskytování licence pro službu dirigent.</div>
                </mat-card-content>
                <mat-card-actions>
                    <a mat-fab extended color="primary" target="_blank"  href="assets/documents/poskytovani_licence.pdf">
                        <mat-icon>picture_as_pdf</mat-icon>
                        <span i18n>Otevřít v PDF</span>
                    </a> 
                </mat-card-actions>
            </mat-card>
            <mat-card class="user-documents__card">
                <mat-card-header>
                    <mat-card-title i18n>Obchodní podmínky</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div i18n>Obchodní podmínky pro službu dirigent.</div>
                </mat-card-content>
                <mat-card-actions>
                    <a mat-fab extended color="primary" target="_blank" href="assets/documents/obchodni_podminky.pdf">
                        <mat-icon>picture_as_pdf</mat-icon>
                        <span i18n>Otevřít v PDF</span>
                    </a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</mat-card>
