import { OrderState } from './orders.model';
import { PlanItem } from './plan.model';
import { Player, PlayerCategory } from './player.model';

export class License {
  id: string;
  licenseName: string;
  planId: string;
  playerId?: string | null;
  player: Player | null;
  subscriptionLength: number;
  autoRenew: boolean;
  subscriptionAlign: boolean;
  startDate: string;
  endDate: string;
  alignGroup?: string;
  alignDate?: string;
  playerCategory: PlayerCategory | null;
  licenseState: LicenseState;
  licenseType: LicenseType;
  _position: number;
  isExtendable?: boolean;
  extendableMessage?: string | null;
  plan?: PlanItem;
  companyISUID? :string | null;
  previousLicenses: License[];
  lastOrder?: {
    orderState: OrderState,
    _id: string;
  };
  
  constructor(planId: string, playerCategory: PlayerCategory) {
    this.id = '0';
    this.player = null;
    this.planId = planId;
    this.licenseName = '';
    this.subscriptionLength = 0;
    this.autoRenew = true;
    this.subscriptionAlign = false;
    this.startDate = '';
    this.endDate = '';
    this.playerCategory = playerCategory;
    this.licenseState = LicenseState.NEW;
    this.licenseType = LicenseType.REGULAR;
    this._position = 0;
    this.alignGroup = '';
    this.previousLicenses = [];
  }
}

export enum LicenseState {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  EMPTY = 'EMPTY',
  EXPIRED = 'EXPIRED',
  EXTENDED = 'EXTENDED',
}

export enum LicenseType {
  REGULAR = 'REGULAR',
  DEMO = 'DEMO',
}


export type NewLicenseCalc = {
    startDate: string;
    endDate: string;
    alignDate?: string;
}