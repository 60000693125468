import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { forEach } from 'lodash';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileUploadComponent  {
  private file: any;
  @Output() fileChange = new EventEmitter<any>();
  @Input() progress = null;
  @Input() acceptFiles: string | null = "";
  @Input() placeholder?: string = $localize`Vyberte soubor`;
  @Input() preview?: string | null = "";
  accpetAttribute = "";
  fileUri:  string = "";


  constructor( ) {
  }
  ngOnChanges(changes: SimpleChanges) {

    if(changes['preview']) {
      this.preview = changes['preview'].currentValue
    }
    
  }
  async addFile(event: any) {
    let file = event.target.files[0];
    this.fileUri = await this.fileToUri(file);
    this.preview = this.fileUri;
    this.fileChange.emit( this.fileUri);
  }

  
  fileToUri(file: any) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
          if(e.target.result) {
            resolve(e.target.result)
          }
      };
      
      if (file) {
        reader.readAsDataURL(file);
      }
    });
  }
}
