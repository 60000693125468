import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../services/authorization.service';
import { CompanyUser } from '../models/company.model';
import { UserService } from '../services/user.service';
import { RequestResponse } from '../models/request-status';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private headers: HttpHeaders;
  apiServer: string;

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId ? this.userService.selectedCompanyId : "none" + ''
    );
  }
  fetchCompanyUsers(): Observable<CompanyUser[]> {
    let url = `assets/testingData/users-list.json`;
    return this.http.get<CompanyUser[]>(url, { headers: this.headers });
  }
  fetchUserDetail(id: string) {
    return this.http.get<RequestResponse>(this.apiServer + "/persons/" + id, { headers: this.headers });

  }
  updateUserDetail(id: string, userData: any) {
    return this.http.put<RequestResponse>(this.apiServer + "/persons/" + id, userData, { headers: this.headers });
  }
  passwordChange(id: string, oldPassword: string, password: string) {
    let userData = {oldPassword, password }
    return this.http.put<RequestResponse>(this.apiServer + "/persons/" + id, userData, { headers: this.headers });
  }

  init2FA() {
    const url = `${this.apiServer}/api2/user/otp`;
    return this.http
      .get<any>(url, {headers: this.headers})
  }
}
