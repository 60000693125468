import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-order-added',
  templateUrl: './order-added.component.html',
  styleUrls: ['./order-added.component.scss'],
})
export class OrderAddedComponent {
  constructor(
    private dialogRef: MatDialogRef<OrderAddedComponent>,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}
  ngOnInit() {
    this.analyticsService.addDataLayer("add_to_cart");
  }
  goToCart() {
    this.router.navigate(['/cart']).then(() => {});
    this.dialogRef.close();
  }

  close() {
    this.router.navigate(['/']).then(() => {});
    this.dialogRef.close();
  }
}
