
<div *ngIf="plan" class="plan-detail-dialog__body"  [ngClass]="'plan-detail-dialog__body--'+plan.planType">

  <div class="plan-detail-dialog__subtitle"  *ngIf="plan.planType == planTypeEnum.SOLO" i18n>zákadní</div>
  <div class="plan-detail-dialog__subtitle"  *ngIf="plan.planType == planTypeEnum.OPUS" i18n>doporučujeme</div>
  <div class="plan-detail-dialog__subtitle"  *ngIf="plan.planType == planTypeEnum.MAGNUM" i18n>individuální</div>

  <div class="plan-detail-dialog__plan" >
    <div>
      <div class="plan-detail-dialog__title flex flex-col"> 
        <span>Tarif</span>
        <h2><span>{{ plan.title }}</span></h2>
      </div>

      <div *ngIf="plan.image" class="plan-detail-dialog__illustration ">
        <img  [src]="plan.image">
      </div>
    
      <ul class="plan-item__features">
        <li *ngFor="let feature of plan.features">
          <img *ngIf="plan.planType == planTypeEnum.SOLO" src="assets/img/icons/note-simple.svg" class="plan-item__features__icon"  >
          <img *ngIf="plan.planType == planTypeEnum.OPUS" src="assets/img/icons/note-medium.svg" class="plan-item__features__icon"  >
          <img *ngIf="plan.planType == planTypeEnum.MAGNUM" src="assets/img/icons/note-double.svg" class="plan-item__features__icon"  >
          {{ feature.title | languageMutation:locale }}
        </li>
      </ul>
    </div>
    <div>
      <div class="plan-detail-dialog__price">
        <span i18n> od </span> <h3>{{ plan.prices | currencySelection: companyCurrency }}</h3> <span i18n> / měsíc </span>
      </div>
      <div class="plan-detail-dialog__price-vat">
        <h3>{{ calculateVatPrice(plan.prices) | currencySelection: companyCurrency }}</h3> <span i18n>&nbsp;s DPH </span>
      </div>
      <button class="plan-detail-dialog__order mat-mdc-raised-button--shadow  u__bg--primary-dirigent" mat-raised-button color="primary" (click)="openOrderStepper()" i18n>Předplatit službu</button>
    </div>
  </div>
  <div class="plan-detail-dialog__detail">
    <div class="plan-detail-dialog__detail__container">
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
      <!--[innerHTML]="plan.detailHtml | languageMutation:locale"</-->
      <div class="plan-detail-dialog__detail__content" [innerHTML]="plan.detailHtml | languageMutation:locale">
      </div>

      <!--div class="plan-detail-dialog__detail__content">
        <h2>Buďte dirigentem vlastního podniku!</h2>
        <h3>Proč SOLO?</h3>
        <p>
            Kadeřnictví, butik, nebo fitness centrum - SOLO je jako
            stvořené pro menší provozovny, které chtějí vytvořit
            osobitou hudební atmosféru, aniž by se musely starat o
            licenční poplatky.
        </p>
        <h3>Co dostanete?</h3>
        <ul class="plan-detail-dialog__detail__features">
            <li>
              <img src="assets/img/icons/note-simple.svg" class="plan-item__features__icon">
              <strong>14 dní ZDARMA</strong> na vyzkoušení Dirigenta.
            </li>
            <li>
              <img src="assets/img/icons/note-simple.svg" class="plan-item__features__icon">
              <strong>Snadná online smlouva.</strong> Žádné komplikace, vše zařídíte jednoduše online.
            </li>
            <li>
              <img src="assets/img/icons/note-simple.svg" class="plan-item__features__icon">
              <strong>Ušetříte na poplatcích.</strong> S naší licencovanou hudbou můžete ušetřit až 80 % na poplatcích OSA a Intergram.
            </li>
            <li>
              <img src="assets/img/icons/note-simple.svg" class="plan-item__features__icon">
              <strong>Skvělou hudební atmosféru.</strong> Mějte vše pod taktovkou a sestavujte vlastní playlisty, které vám a vašim zákazníkům budou vyhovovat.
            </li>
            <li>
              <img src="assets/img/icons/note-simple.svg" class="plan-item__features__icon">
              <strong>E-mailovou servisní podporu</strong>
            </li>
        </ul>
      </div-->
      <div  class="mt-10">
        <p i18n>Ceník podle plochy provozovny:</p>
        <div class="plan-detail-dialog__detail__table">
          <div class="plan-detail-dialog__detail__table__header">
            <div class="plan-detail-dialog__detail__table__area" i18n>Plocha</div>
            <div class="plan-detail-dialog__detail__table__price" i18n>Cena</div>
            <div class="plan-detail-dialog__detail__table__price" i18n>Cena s DPH</div>
          </div>
          <div class="plan-detail-dialog__detail__table__header">
            <div class="plan-detail-dialog__detail__table__area" i18n>Plocha</div>
            <div class="plan-detail-dialog__detail__table__price" i18n>Cena</div>
            <div class="plan-detail-dialog__detail__table__price" i18n>Cena s DPH</div>
          </div>
        </div>
        <div  class="plan-detail-dialog__detail__table">
          <div *ngFor="let price of plan.areaPrices; let i = index" class="plan-detail-dialog__detail__table__row">
            <div class="plan-detail-dialog__detail__table__area" *ngIf=" i !== plan.areaPrices.length - 1" i18n>Plocha do {{ price.to}} m2</div>
            <div class="plan-detail-dialog__detail__table__area" *ngIf=" i == plan.areaPrices.length - 1" i18n>Plocha nad {{ price.to}} m2</div>
            <div class="plan-detail-dialog__detail__table__price"><strong >{{ price.price[companyCurrency] + plan.servicePrices[companyCurrency]  }} Kč</strong></div>
            <div class="plan-detail-dialog__detail__table__price"><strong >{{ calculateVatPriceNumber(price.price[companyCurrency] + plan.servicePrices[companyCurrency]) }}</strong></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>