import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { LoginRecoveryComponent } from './login/login-recovery/login-recovery.component';
import { PasswordChangeComponent } from './login/password-change/password-change.component';
import { GuardsComponent } from './guards/auth.guard';
import { CartComponent } from './cart/cart.component';
import { OrdersComponent } from './orders/orders.component';
import { LicensesComponent } from './licenses/licenses.component';
import { UsersComponent } from './users/users.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';
import { FirstRegistrationComponent } from './login/first-registration/first-registration.component';
import { SupportComponent } from './support/support.component';
import { CompanySettingsComponent } from './company/company-settings/company-settings.component';
import { OrderPaymentStateComponent } from './orders/order-payment-state/order-payment-state.component';
import { OrderOpenComponent } from './orders/order-open/order-open.component';
import { UserDocumentsComponent } from './users/user-documents/user-documents.component';
import { AboutUsComponent } from './about/about-us/about-us.component';
import { Error505Component } from './errors/error-505/error-505.component';
const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    canActivate: [GuardsComponent],
    children: [
      {
        path: '',
        component: DashboardComponent,
        outlet: 'content',
        data: { title: $localize`Dashboard` },
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: $localize`Přihlášení` },
  },
  {
    path: 'registration',
    component: FirstRegistrationComponent,
    data: { title: $localize`Registrace` },
  },

  {
    path: 'login-recovery',
    component: LoginRecoveryComponent,
    data: { title: $localize`Obonova hesla` },
  },
  {
    path: 'password-change',
    component: PasswordChangeComponent,
    data: { title: $localize`Změna hesla` },
  },
  {
    path: 'dashboard',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Dashboard` },
      },
    ],
  },
  {
    path: 'cart',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: CartComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Košík` },
      },
    ],
  },
  {
    path: 'support',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: SupportComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Podpora` },
      },
    ],
  },
  {
    path: 'about-us',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: AboutUsComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`O nás` },
      },
    ],
  },
  {
    path: 'company-settings',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: CompanySettingsComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Podpora` },
      },
    ],
  },
  {
    path: 'payment-state',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: OrderPaymentStateComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Stav platby za objednávku` },
      },
    ],
  },
  {
    path: 'orders',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: OrdersComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Objednávky` },
      },
    ],
  },
  {
    path: 'licenses',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: LicensesComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Licence` },
      },
    ],
  },
  {
    path: 'users',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: UsersComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Uživatelé` },
      },
    ],
  },
  {
    path: 'user-settings',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: UserSettingsComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Nastavení uživatele` },
      },
    ],
  },
  {
    path: 'user-documents',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: UserDocumentsComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Dokumenty ke stažení` },
      },
    ],
  },
  {
    path: 'buy-license',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: OrderOpenComponent,
        canActivate: [GuardsComponent],
        outlet: 'content',
        data: { title: $localize`Nová licence` },
      },
    ],
  },
  {
    path: 'error',
    component: HomepageComponent,
    children: [
      {
        path: '',
        component: Error505Component,
        outlet: 'content',
        data: { title: $localize`Chyba` },
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", enableTracing: false  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
