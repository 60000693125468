import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PlanFeature, PlanItem, PlanTemplate, PlanType } from 'src/app/models/plan.model';
import { OrderService } from 'src/app/orders/order.service';
import { LOCALE_ID, Inject  } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Dialog } from '@angular/cdk/dialog';
import { PlanDetailDialogComponent } from '../plan-detail-dialog/plan-detail-dialog.component';
import { PlansService } from '../plans.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';


@Component({
  selector: 'app-plan-item',
  templateUrl: './plan-item.component.html',
  styleUrls: ['./plan-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanItemComponent implements OnChanges {
  @Input() plan!: PlanItem;
  @Input() template?: PlanTemplate = PlanTemplate.FULL;
  @Output() planSelection = new EventEmitter<PlanItem>();

  public companyCurrency: string  = "CZK";
  isOpen: boolean = false;
  
  planTypeEnum: typeof PlanType = PlanType;
  planTemplateEnum: typeof PlanTemplate = PlanTemplate;

  constructor(public dialog: Dialog, private plansService: PlansService, private userService: UserService, @Inject(LOCALE_ID) public locale: string, private orderService: OrderService, ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.companyCurrency = this.userService.activeCompanyCurrency;
    if (changes['plan']) {
      this.plan = changes['plan'].currentValue;
    }
  }
  selectionAction() {
    if (this.template == PlanTemplate.FULL || this.template == PlanTemplate.COMPACT) {
      this.openOrderStepper();
    }

    if (this.template == PlanTemplate.SELECTION) {
      this.onPlanSelection();
    }
  }
  openOrderStepper(): void {
    this.orderService.openOrderDialog(this.plan);
  }

  onPlanSelection(): void {
    this.planSelection.emit(this.plan);
  }
  
  openDetailDialog() {
    this.plansService.openDetailDialog(this.plan)
  }

  calculateVatPrice(prices: any):any {
    if (this.companyCurrency) {
      let vatPrices = Object.assign({}, prices)
      vatPrices[this.companyCurrency] = (vatPrices[this.companyCurrency] * CustomUtils.getPercentMultiplayer(this.plan.vatRate)).toFixed(2);
      return vatPrices;
    }
    return prices;
  }
}
