
<div class="company-error dialog">
  <div mat-dialog-title class="dialog__title" >
      <h2 i18n>Chyba</h2> 
      <button class="dialog__close" mat-icon-button mat-dialog-close  i18n-aria-label aria-label="Zavřít dialog">
          <mat-icon>clear</mat-icon>
      </button>
  </div>
  <div class="dialog__content mb-10">
    <div *ngIf="errorCode == 409">
      <p i18n>Vaše firma je již u nás registrovaná. Zkontrolujte správnost zadného IČO, nebo požádejte správce o členství ve firmě prostřednictvím naší podpory.</p>
      <a a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
      <!--button class="mt-10 flex w-full" mat-raised-button color="primary" i18n>Odeslat žádost o členství</button-->
      
      <button class="mt-10 mb-10 flex w-full" (click)="isOwner = !isOwner" mat-raised-button color="transparent" i18n>Jsem majitel společnosti</button>
      <div *ngIf="isOwner">
        <p i18n>Pokud je vaše firma již registrovaná a vy jste majitelem společnosti, kontaktujte prosím podporu na: <a a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a></p>
      </div>
    </div>

    <div *ngIf="errorCode !== 409">
      <p i18n>Nepodařilo se najít vaši firmu podle zadaného IČ/DIČ. Překontrolujte prosím údaje a pokuste se firmu opět zaregistrovat.</p>
      <br><br>
      <p i18n>Pokud potíže přetrvávají napište nám na:</p>
      <a a href="mailto:podpora@ismedia.cz">podpora@ismedia.cz</a>
    </div>
  </div>
  <div class="dialog__footer dialog__footer--reverse license-cancel__footer">
    <button mat-raised-button mat-dialog-close  i18n>Rozumím</button>
  </div>
</div>
