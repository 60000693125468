<form [formGroup]="companySelectFormGroup"  *ngIf="companies" >
    <mat-form-field  *ngIf="companies.length > 1" appearance="outline" class="mat-mdc-form-field--white  mat-mdc-form-field--rounded  mat-mdc-form-field--fullwidth">
        <input type="text"
            i18n-placeholder
            i18n-aria-label
            placeholder="Vyberte společnost"
            aria-label="Společnost"
            matInput
            formControlName="selectedCompanyName"
            [matAutocomplete]="auto"
            (focus)="clearInput()"
            >
      <mat-autocomplete [panelWidth]="300" class="company-select__autocomplete" autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectCompany($event.option.value)">
        <mat-option  class="company-select__option" *ngFor="let company of companiesFiltered | async" [value]="company.companyId">
          <h4>{{company.name}}</h4>
          <p *ngIf="company.shownName">{{company.shownName}}</p>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="company-select__company-title" *ngIf="companies.length == 1">
      <h4>{{companies[0].name}}</h4>
      <p *ngIf="companies[0].shownName">{{companies[0].shownName}}</p>
    </div>
  </form>