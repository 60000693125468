import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Player } from '../models/player.model';
import { UserService } from '../services/user.service';
import { RequestResponse } from '../models/request-status';
import { Company } from '../models/company.model';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root',
})
export class PlayersService {
  private headers: HttpHeaders;
  apiServer: string;
  public playerDetailDialog = new Subject<string>();

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) {
    this.apiServer = `${environment.serverProtocol}://${environment.apiAddress}:${environment.serverPort}/${environment.apiVersion}`;
    this.headers = new HttpHeaders();
    this.headers = this.headers.set(
      'Authorization',
      'Bearer ' + this.authorizationService.accessTokenValue
    );
    this.headers = this.headers.set(
      'x-company-id',
      this.userService.selectedCompanyId ? this.userService.selectedCompanyId : 'none' + ''
    );
    this.userService.companyChangeSubject.subscribe((company: Company) => {
      this.headers = this.headers.set(
        'x-company-id',
        company.companyId ?  company.companyId : 'none'
      );
    })
  }
  fetchPlayerDetail(playerId: string) {
    return this.http.get<RequestResponse>(this.apiServer+"/players/"+playerId, { headers: this.headers });
  }

  updatePlayerInfo(player: Player) {
    let url = this.apiServer+"/players/" + player.playerId;

    let payload: any = {};
    payload['name'] = player.name;
    payload['branch'] = player.branch;
    payload['place'] = player.place;
    payload['street'] = player.street;
    payload['city'] = player.city;
    payload['zip'] = player.zip;
    payload['country'] = player.country;
    payload['tel'] = player.tel;
    payload['tags'] = player.tags;
    payload['note'] = player.note;
    payload['area'] = player.area;
    payload['gps'] = player.gps;

    return this.http.put<RequestResponse>(url, payload, { headers: this.headers });
  }

  getPlayerDetailUrl(playerId: string):string {
    return environment.dirigentUrl + "/admin/tabs/devices/" + playerId;
  }

  getPlayerToken(playerId: string) {
    let url = environment.dirigentUrl+"/api/devices/" + playerId +"/tokens";
    return this.http.post<RequestResponse>(url, {}, { headers: this.headers });
  }

  getStoreTypes() {
    return this.http.get<RequestResponse>(this.apiServer+"/players/storetypes", { headers: this.headers });
  }
  getPlayerCertificate(isuid: string, licenseId: string | null) {
    var parram = ""
    if(licenseId)  parram = '?licenseId='+licenseId;
    console.log(parram);

    return this.http.get<any>(this.apiServer+"/players/"+isuid+"/certificate"+parram, { headers: this.headers });
  }

  downloadPlayerCertificate(isuid: string, licenseId: string | null) {
    var parram = ""
    if(licenseId)  parram = '&licenseId='+licenseId

    
    var headers  = this.headers;
    headers = headers.set('Accept', 'application/pdf');
    headers = headers.set('Content-Type', 'application/pdf');
    const httpOptions: Object = {
      responseType:'blob' as 'json',
      headers: headers
    };
    return this.http.get(this.apiServer+"/players/"+isuid+"/certificate?download=1"+parram, httpOptions);
  }
}
