import { Component } from '@angular/core';

@Component({
  selector: 'app-error-505',
  templateUrl: './error-505.component.html',
  styleUrls: ['./error-505.component.scss']
})
export class Error505Component {

  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }
}
