import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'src/app/models/company.model';
import { UsersService } from 'src/app/users/users.service';
import { CompanyService } from '../company.service';
import { SaveSnackbarComponent } from 'src/app/utils/save-snackbar/save-snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { CompanyNewModalComponent } from '../company-new-modal/company-new-modal.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompanySettingsComponent {
  @Input() companyId?: Company | null = null;
  companyFormGroup: FormGroup;
  companySettings: Company | null = null;
  newCompanySettings: any = {};
  progress: any;
  step = 0;
  fileUri: string | ArrayBuffer | null = null;

  constructor(private userService: UserService, public dialog: MatDialog, private snackBar: MatSnackBar, private fb: FormBuilder, private companyService: CompanyService) {
    this.fetchCompanyDetail();

    this.companyFormGroup = this.fb.group({
      name: new FormControl({value: "", disabled: true}),
      regnum: new FormControl({value: "", disabled: true}),
      vatnum: new FormControl({value: "", disabled: true}),
      street: new FormControl({value: "", disabled: true}),
      city: new FormControl({value: "", disabled: true}),
      zip: new FormControl({value: "", disabled: true}),
      emailPrimary: new FormControl({value: "", disabled: false}),
      emailInvoice: new FormControl({value: "", disabled: false}),
    });

    
    this.companyFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }

  onLogoChange(uri: any) {
    this.fileUri = uri
    if( this.newCompanySettings) {
      this.newCompanySettings.logo =  this.fileUri;
    }
  }

  updateDataModel(value: any) {

    if(this.companySettings) {
      this.companySettings = Object.assign(this.companySettings, value);
    }
    this.newCompanySettings = value;
  }

  fetchCompanyDetail() {
    if(this.companyService.companyId) {
      this.companyService.fetchCompanyDetail(this.companyService.companyId).subscribe({
        next: (data) => {
          if (data) {
            this.companySettings = data.data.company;
            this.companyFormGroup.get("regnum")?.setValue(this.companySettings?.regnum, {emitEvent: false});
            this.companyFormGroup.get("vatnum")?.setValue(this.companySettings?.vatnum, {emitEvent: false});
            this.companyFormGroup.get("name")?.setValue(this.companySettings?.name, {emitEvent: false});
            this.companyFormGroup.get("street")?.setValue(this.companySettings?.street, {emitEvent: false});
            this.companyFormGroup.get("city")?.setValue(this.companySettings?.city, {emitEvent: false});
            this.companyFormGroup.get("zip")?.setValue(this.companySettings?.zip, {emitEvent: false});
            this.companyFormGroup.get("emailPrimary")?.setValue(this.companySettings?.emailPrimary, {emitEvent: false});
            this.companyFormGroup.get("emailInvoice")?.setValue(this.companySettings?.emailInvoice, {emitEvent: false});

          }
        },
        error: (HttpErrorResponse: any) => {
          console.log(HttpErrorResponse.message);
        },
      })
    }
  }

  updateCompanyData() {
    if(this.companyService.companyId) {

      this.companyService.updateCompanyDetail(this.companyService.companyId,  this.newCompanySettings).subscribe({
        next: (data) => {
          this.snackBar.openFromComponent(SaveSnackbarComponent, {
            duration: 4000,
          });

          if(data.code == 200) {
            this.userService.setActiveCompany(data.data.company, true);
          }
        },
        error: (HttpErrorResponse: any) => {
          console.log(HttpErrorResponse.message);
        },
      })
      this.newCompanySettings = null;
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  openCompanyRegistration() {
    this.dialog.open(CompanyNewModalComponent, {
      disableClose: true,
      width: '1200px',
      maxHeight: '100vh',
      maxWidth: '95vw',
      panelClass: 'company-new__dialog'
    });
  }
}
