import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  public newDeviceDialog = new Subject<boolean>();

  constructor() {}

  openNewDeviceSupport() {
    this.newDeviceDialog.next(true);
  }
}
