export class Player {
  playerId: string;
  companyISUID: string;
  name: string;
  branch: string;
  place: string;
  street: string;
  city: string;
  zip: number;
  gps: { lat: number; lng: number };
  country: string;
  tel: string;
  tags: string[];
  note: string;
  area: number;
  validTo: string;
  category: PlayerCategory;
  playerSubcategory: PlayerSubcategory;
  _position: number;
  licenseId?: string;
  storetypeId?: number;
  constructor() {
    this.companyISUID = '';
    this.playerId = '';
    this.name = '';
    this.branch = '';
    this.place = '';
    this.street = '';
    this.city = '';
    this.zip = 0;
    this.country = '';
    this.tel = '';
    this.tags = [];
    this.note = '';
    this.area = 0;
    this.validTo = '';
    this.category = PlayerCategory.RADIO;
    this.playerSubcategory = PlayerSubcategory.RADIO;
    this._position = 0;
    this.gps = { lat: 0.0, lng: 0.0 };
  }
}

export enum PlayerCategory {
  TV = 'TV',
  RADIO = 'RADIO',
}

export enum PlayerSubcategory {
  TV = 'TV',
  RADIO = 'RADIO',
}
