
<div class="users-list">
  <table mat-table [dataSource]="dataSource" >
  <!-- Checkbox Column -->

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef i18n>Jméno</th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef i18n>Email</th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>

    <ng-container matColumnDef="userRole">
    <th mat-header-cell *matHeaderCellDef class="text-right"> Role</th>
    <td mat-cell *matCellDef="let element" class="text-right" > 
      <mat-form-field class="users-list__role-select mat-mdc-form-field--nohint" >
        <mat-label>Role</mat-label>
        <mat-select>
          <mat-option [value]="userRole.OWNER" i18n>Vlastník</mat-option>
          <mat-option [value]="userRole.ADMIN" i18n>Administrator</mat-option>
          <mat-option [value]="userRole.USER" i18n>Uživatel</mat-option>
        </mat-select>
        <mat-hint style="display: none;"></mat-hint>
      </mat-form-field>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</div>