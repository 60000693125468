<div class="dialog plan-benefits">
    <div mat-dialog-title class="dialog__title">
      <h2 i18n>Výhody předplatného</h2>
      <button  mat-dialog-close class="dialog__close" mat-icon-button i18n-aria-label aria-label="Zavřít dialog">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="dialog__content plan-benefits__content">
        <div  class="pictogram pictogram--hcentered" >
            <div>
              <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <defs>
                  <style>
                    .cls-1 {
                      opacity: 0;
                    }
              
                    .cls-3 {
                      opacity: .5;
                    }
              
                    .cls-4 {
                      opacity: .25;
                    }
                  </style>
                </defs>
                <g id="Vrstva_1-2" data-name="Vrstva 1">
                  <g>
                    <rect class="cls-1 pictogram--primary" width="512" height="512"/>
                    <g>
                      <path class="cls-2 pictogram--primary" d="m376.32,215.48c-3.8-4.03-9.14-6.35-14.65-6.35h-150.11c-.37-2.41-1.1-4.7-2.14-6.81l128.22-47.86c1.92-.71,2.89-2.85,2.17-4.76-.71-1.92-2.85-2.89-4.76-2.17l-130.04,48.54c-4.38-4.5-10.5-7.31-17.26-7.31-12.02,0-22.01,8.85-23.8,20.38h-28.12c-11.35,0-20.24,8.89-20.24,20.24v148.83c0,11.35,8.89,20.24,20.24,20.24h225.32c11.35,0,20.24-8.89,20.24-20.24v-149.25c.26-4.95-1.54-9.73-5.07-13.48Zm-2.33,13.16v149.57c0,7.2-5.64,12.83-12.84,12.83h-225.32c-7.32,0-12.83-5.52-12.83-12.83v-148.83c0-7.32,5.52-12.83,12.83-12.83h225.83c3.48,0,6.86,1.46,9.26,4.02,2.15,2.28,3.23,5.15,3.06,8.08Zm-186.23-32.48c3.45,0,6.67,1.06,9.33,2.86,1.16.79,2.21,1.73,3.14,2.78.85.96,1.6,2,2.21,3.13.71,1.31,1.23,2.72,1.57,4.21h-32.5c.05-.21.11-.42.16-.63,1.91-7.1,8.39-12.35,16.09-12.35Z"/>
                      <path class="cls-2 pictogram--primary" d="m326.53,232.2c-16.29,0-29.54,13.25-29.54,29.54s13.25,29.54,29.54,29.54,29.54-13.25,29.54-29.54-13.25-29.54-29.54-29.54Zm0,51.68c-12.21,0-22.14-9.93-22.14-22.13s9.93-22.14,22.14-22.14,22.14,9.93,22.14,22.14-9.93,22.13-22.14,22.13Z"/>
                      <path class="cls-2 pictogram--primary" d="m201.44,240.86c-34.04,0-61.73,27.69-61.73,61.73s27.69,61.73,61.73,61.73,61.73-27.69,61.73-61.73-27.69-61.73-61.73-61.73Zm-54.32,61.73c0-29.95,24.37-54.32,54.32-54.32s54.32,24.37,54.32,54.32-24.37,54.32-54.32,54.32-54.32-24.37-54.32-54.32Z"/>
                      <path class="cls-2 pictogram--primary" d="m234.58,312.25c-1.66-1.33-4.31-1-5.64,1-5.97,8.96-16.59,14.6-27.55,14.6s-21.24-5.31-27.55-14.6c-1-1.66-3.65-2.32-5.64-1-1.66,1.33-2.32,3.65-1,5.64,7.63,11.62,20.58,18.59,34.52,18.59s26.55-6.97,33.85-18.59c1.33-1.66,1-4.31-1-5.64Z"/>
                      <path class="cls-2 pictogram--primary" d="m318.22,263.88c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m217.95,303.14c2.04,0,3.7-1.66,3.7-3.7v-13.96c0-2.04-1.66-3.7-3.7-3.7s-3.7,1.66-3.7,3.7v13.96c0,2.04,1.66,3.7,3.7,3.7Z"/>
                      <path class="cls-2 pictogram--primary" d="m184.12,303.14c2.04,0,3.7-1.66,3.7-3.7v-13.96c0-2.04-1.66-3.7-3.7-3.7s-3.7,1.66-3.7,3.7v13.96c0,2.04,1.66,3.7,3.7,3.7Z"/>
                      <polygon class="cls-2 pictogram--primary" points="307.08 309.67 303.38 309.67 299.67 309.67 299.67 312.95 286.46 312.95 286.46 320.36 299.67 320.36 299.67 323.63 307.08 323.63 307.08 320.36 354.12 320.36 354.12 316.65 354.12 312.95 307.08 312.95 307.08 309.67"/>
                      <polygon class="cls-2 pictogram--primary" points="307.08 331.66 299.67 331.66 299.67 334.93 286.46 334.93 286.46 342.34 299.67 342.34 299.67 345.62 303.38 345.62 307.08 345.62 307.08 342.34 354.12 342.34 354.12 338.64 354.12 334.93 307.08 334.93 307.08 331.66"/>
                      <polygon class="cls-2 pictogram--primary" points="307.08 353.64 299.67 353.64 299.67 356.92 286.46 356.92 286.46 364.32 299.67 364.32 299.67 367.6 303.38 367.6 307.08 367.6 307.08 364.32 354.12 364.32 354.12 360.62 354.12 356.92 307.08 356.92 307.08 353.64"/>
                    </g>
                    <g class="cls-4">
                      <path class="cls-2 pictogram--primary" d="m370.94,220.56c-2.41-2.56-5.79-4.02-9.26-4.02h-225.83c-7.32,0-12.83,5.52-12.83,12.83v148.83c0,7.32,5.52,12.83,12.83,12.83h225.32c7.2,0,12.83-5.64,12.83-12.83v-149.57c.18-2.93-.91-5.8-3.05-8.08Zm-84.47,92.39h13.21v-3.28h7.41v3.28h47.04v7.41h-47.04v3.28h-7.41v-3.28h-13.21v-7.41Zm0,21.98h13.21v-3.28h7.41v3.28h47.04v7.41h-47.04v3.28h-7.41v-3.28h-13.21v-7.41Zm-85.02,29.39c-34.04,0-61.73-27.69-61.73-61.73s27.69-61.73,61.73-61.73,61.73,27.69,61.73,61.73-27.69,61.73-61.73,61.73Zm152.68,0h-47.04v3.28h-7.41v-3.28h-13.21v-7.41h13.21v-3.28h7.41v3.28h47.04v7.41Zm-27.59-73.03c-16.29,0-29.54-13.25-29.54-29.54s13.25-29.54,29.54-29.54,29.54,13.25,29.54,29.54-13.25,29.54-29.54,29.54Z"/>
                      <path class="cls-2 pictogram--primary" d="m326.53,239.61c-12.21,0-22.14,9.93-22.14,22.14s9.93,22.13,22.14,22.13,22.14-9.93,22.14-22.13-9.93-22.14-22.14-22.14Zm-8.31,33.49c-2.55,0-4.61-2.06-4.61-4.61s2.06-4.61,4.61-4.61,4.6,2.06,4.6,4.61-2.06,4.61-4.6,4.61Z"/>
                    </g>
                    <g class="cls-3">
                      <path class="cls-2 pictogram--primary" d="m300.69,182.38c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m350.03,169.47c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m313.61,114.86c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.61-2.06,4.61-4.61-2.06-4.61-4.61-4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m203.54,138.05c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m151.86,127c2.55,0,4.6-2.06,4.6-4.61s-2.06-4.61-4.6-4.61-4.61,2.06-4.61,4.61,2.06,4.61,4.61,4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m126.02,188.35c-2.55,0-4.61,2.06-4.61,4.61s2.06,4.61,4.61,4.61,4.6-2.06,4.6-4.61-2.06-4.61-4.6-4.61Z"/>
                      <path class="cls-2 pictogram--primary" d="m258.83,122.4c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                      <path class="cls-2 pictogram--primary" d="m154.26,164.21l-15.22-4.23,9.1-10.26c.87-.99,1.16-2.36.75-3.61-.41-1.25-1.46-2.19-2.74-2.47l-26.07-5.58c-2-.42-3.97.85-4.4,2.85s.85,3.97,2.85,4.4l19.87,4.25-8.82,9.95c-.85.96-1.14,2.28-.78,3.5.36,1.23,1.33,2.18,2.56,2.52l20.93,5.82c.33.09.67.14.99.14,1.62,0,3.11-1.07,3.57-2.71.55-1.97-.61-4.01-2.58-4.56Z"/>
                      <path class="cls-2 pictogram--primary" d="m383.47,161.33l9.39-11.76c1.21-1.51,1.05-3.7-.37-5.02l-9.52-8.86,11.85-12.58c1.4-1.49,1.33-3.83-.16-5.23-1.49-1.4-3.83-1.33-5.23.16l-14.41,15.29c-.68.72-1.04,1.67-1.01,2.66s.46,1.92,1.18,2.59l9.73,9.06-9.33,11.68c-1.18,1.48-1.06,3.62.29,4.95l12.63,12.45c.72.71,1.66,1.07,2.6,1.07s1.91-.37,2.64-1.1c1.44-1.46,1.42-3.8-.04-5.24l-10.26-10.11Z"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        <div class="plan-benefits__plans">
            <div class="plan-benefits__plan">
                <h3>Proč SOLO?</h3>
                <p>
                Kadeřnictví, butik, nebo fitness centrum - SOLO je jako stvořené pro menší provozovny, které chtějí vytvořit osobitou hudební atmosféru, aniž by se musely starat o licenční poplatky.
                </p>
                <br>
                <h3>Co dostanete?</h3>
                <ul class="plan-benefits__features">
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M183.83 480a55.2 55.2 0 01-32.36-10.55A56.64 56.64 0 01128 423.58a50.26 50.26 0 0134.14-47.73L213 358.73a16.25 16.25 0 0011-15.49V92a32.1 32.1 0 0124.09-31.15l108.39-28.14A22 22 0 01384 54v57.75a32.09 32.09 0 01-24.2 31.19l-91.65 23.13A16.24 16.24 0 00256 181.91V424a48.22 48.22 0 01-32.78 45.81l-21.47 7.23a56 56 0 01-17.92 2.96z"></path></svg>
                    <strong> 14 dní ZDARMA</strong> na vyzkoušení Dirigenta.
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M183.83 480a55.2 55.2 0 01-32.36-10.55A56.64 56.64 0 01128 423.58a50.26 50.26 0 0134.14-47.73L213 358.73a16.25 16.25 0 0011-15.49V92a32.1 32.1 0 0124.09-31.15l108.39-28.14A22 22 0 01384 54v57.75a32.09 32.09 0 01-24.2 31.19l-91.65 23.13A16.24 16.24 0 00256 181.91V424a48.22 48.22 0 01-32.78 45.81l-21.47 7.23a56 56 0 01-17.92 2.96z"></path></svg>
                    <strong>Snadná online smlouva. </strong>Žádné komplikace, vše zařídíte jednoduše online.
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M183.83 480a55.2 55.2 0 01-32.36-10.55A56.64 56.64 0 01128 423.58a50.26 50.26 0 0134.14-47.73L213 358.73a16.25 16.25 0 0011-15.49V92a32.1 32.1 0 0124.09-31.15l108.39-28.14A22 22 0 01384 54v57.75a32.09 32.09 0 01-24.2 31.19l-91.65 23.13A16.24 16.24 0 00256 181.91V424a48.22 48.22 0 01-32.78 45.81l-21.47 7.23a56 56 0 01-17.92 2.96z"></path></svg>
                    <strong>Ušetříte na poplatcích.</strong>  S naší licencovanou hudbou můžete ušetřit až 80 % na poplatcích OSA a Intergram.
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M183.83 480a55.2 55.2 0 01-32.36-10.55A56.64 56.64 0 01128 423.58a50.26 50.26 0 0134.14-47.73L213 358.73a16.25 16.25 0 0011-15.49V92a32.1 32.1 0 0124.09-31.15l108.39-28.14A22 22 0 01384 54v57.75a32.09 32.09 0 01-24.2 31.19l-91.65 23.13A16.24 16.24 0 00256 181.91V424a48.22 48.22 0 01-32.78 45.81l-21.47 7.23a56 56 0 01-17.92 2.96z"></path></svg>
                    <strong>Skvělou hudební atmosféru.</strong> Mějte vše pod taktovkou a sestavujte vlastní playlisty, které vám a vašim zákazníkům budou vyhovovat.
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M183.83 480a55.2 55.2 0 01-32.36-10.55A56.64 56.64 0 01128 423.58a50.26 50.26 0 0134.14-47.73L213 358.73a16.25 16.25 0 0011-15.49V92a32.1 32.1 0 0124.09-31.15l108.39-28.14A22 22 0 01384 54v57.75a32.09 32.09 0 01-24.2 31.19l-91.65 23.13A16.24 16.24 0 00256 181.91V424a48.22 48.22 0 01-32.78 45.81l-21.47 7.23a56 56 0 01-17.92 2.96z"></path></svg>
                    <strong> E-mailovou servisní podporu</strong>
                </li>
                </ul>
            </div>
            <div class="plan-benefits__plan">
                <h3>Proč OPUS?</h3>
                <p>
                Vlastníte více prodejen a chcete je naladit na stejnou hudební vlnu? S OPUS tarifem to bude hračka.
                </p>
                <br>
                <h3>Co dostanete?</h3>
                <ul class="plan-benefits__features">
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M421.84 37.37a25.86 25.86 0 00-22.6-4.46L199.92 86.49A32.3 32.3 0 00176 118v226c0 6.74-4.36 12.56-11.11 14.83l-.12.05-52 18C92.88 383.53 80 402 80 423.91a55.54 55.54 0 0023.23 45.63A54.78 54.78 0 00135.34 480a55.82 55.82 0 0017.75-2.93l.38-.13 21.84-7.94A47.84 47.84 0 00208 423.91v-212c0-7.29 4.77-13.21 12.16-15.07l.21-.06L395 150.14a4 4 0 015 3.86v141.93c0 6.75-4.25 12.38-11.11 14.68l-.25.09-50.89 18.11A49.09 49.09 0 00304 375.92a55.67 55.67 0 0023.23 45.8 54.63 54.63 0 0049.88 7.35l.36-.12 21.84-7.95A47.83 47.83 0 00432 375.92V58a25.74 25.74 0 00-10.16-20.63z"></path></svg>
                    <strong>Vše z tarifu SOLO,</strong> a k tomu navíc:
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M421.84 37.37a25.86 25.86 0 00-22.6-4.46L199.92 86.49A32.3 32.3 0 00176 118v226c0 6.74-4.36 12.56-11.11 14.83l-.12.05-52 18C92.88 383.53 80 402 80 423.91a55.54 55.54 0 0023.23 45.63A54.78 54.78 0 00135.34 480a55.82 55.82 0 0017.75-2.93l.38-.13 21.84-7.94A47.84 47.84 0 00208 423.91v-212c0-7.29 4.77-13.21 12.16-15.07l.21-.06L395 150.14a4 4 0 015 3.86v141.93c0 6.75-4.25 12.38-11.11 14.68l-.25.09-50.89 18.11A49.09 49.09 0 00304 375.92a55.67 55.67 0 0023.23 45.8 54.63 54.63 0 0049.88 7.35l.36-.12 21.84-7.95A47.83 47.83 0 00432 375.92V58a25.74 25.74 0 00-10.16-20.63z"></path></svg>
                    <strong>Možnost reklamních spotů.</strong> Podpořte váš marketing v místě setkávání se zákazníky vlastními reklamními spoty a znělkami.
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M421.84 37.37a25.86 25.86 0 00-22.6-4.46L199.92 86.49A32.3 32.3 0 00176 118v226c0 6.74-4.36 12.56-11.11 14.83l-.12.05-52 18C92.88 383.53 80 402 80 423.91a55.54 55.54 0 0023.23 45.63A54.78 54.78 0 00135.34 480a55.82 55.82 0 0017.75-2.93l.38-.13 21.84-7.94A47.84 47.84 0 00208 423.91v-212c0-7.29 4.77-13.21 12.16-15.07l.21-.06L395 150.14a4 4 0 015 3.86v141.93c0 6.75-4.25 12.38-11.11 14.68l-.25.09-50.89 18.11A49.09 49.09 0 00304 375.92a55.67 55.67 0 0023.23 45.8 54.63 54.63 0 0049.88 7.35l.36-.12 21.84-7.95A47.83 47.83 0 00432 375.92V58a25.74 25.74 0 00-10.16-20.63z"></path></svg>
                    <strong>Hromadná správa.</strong> Máte víc prodejen? Žádný problém, dostanete nástroj na jejich hromadnou správu.
                </li>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><path d="M421.84 37.37a25.86 25.86 0 00-22.6-4.46L199.92 86.49A32.3 32.3 0 00176 118v226c0 6.74-4.36 12.56-11.11 14.83l-.12.05-52 18C92.88 383.53 80 402 80 423.91a55.54 55.54 0 0023.23 45.63A54.78 54.78 0 00135.34 480a55.82 55.82 0 0017.75-2.93l.38-.13 21.84-7.94A47.84 47.84 0 00208 423.91v-212c0-7.29 4.77-13.21 12.16-15.07l.21-.06L395 150.14a4 4 0 015 3.86v141.93c0 6.75-4.25 12.38-11.11 14.68l-.25.09-50.89 18.11A49.09 49.09 0 00304 375.92a55.67 55.67 0 0023.23 45.8 54.63 54.63 0 0049.88 7.35l.36-.12 21.84-7.95A47.83 47.83 0 00432 375.92V58a25.74 25.74 0 00-10.16-20.63z"></path></svg>
                    <strong>Telefonickou servisní podporu.</strong> Když je třeba vyřešit něco rychle, jsme tu pro vás na telefonu.
                </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="dialog__footer dialog__footer--reverse">
        <button mat-raised-button color="primary" class="mat-mdc-raised-button--shadow"  mat-dialog-close >Rozumím</button>
    </div>
</div>
