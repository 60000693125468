import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CompanyUser } from 'src/app/models/company.model';
import { UsersService } from '../users.service';
import { UserRole } from 'src/app/models/user.model';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent {
  displayedColumns!: string[];
  dataSource = new MatTableDataSource<CompanyUser>();
  companyUsers: CompanyUser[] = [];
  userRole: typeof UserRole = UserRole;

  constructor(private usersService: UsersService) {}
  ngOnInit() {
    this.displayedColumns = ['name', 'email', 'userRole'];
    this.fetchUsers();
  }
  fetchUsers() {
    this.usersService.fetchCompanyUsers().subscribe({
      next: (data) => {
        if (data) {
          this.companyUsers = data;
          this.dataSource.data = this.companyUsers;
        }
      },
      error: (HttpErrorResponse: any) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
}
