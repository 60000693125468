<div class="file-upload mat-elevation-z8">
  <input id="file-upload-ref" type="file" (change)="addFile($event)"  [accept]="acceptFiles"  class="file-upload__input" i18n-placeholder placeholder="file upload">
  <div class="file-upload__preview" > 
    <img [src]=" preview">
  </div>
  <div class="file-upload__icon">
    <mat-icon>edit</mat-icon>
  </div>
  <div class="file-upload__wrapper">
    <label for="file-upload-ref">{{ placeholder }}</label>
  </div>
</div>
