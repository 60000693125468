<mat-card class="company-settings card card--large card--soft-shadow">
  <mat-card-title>
    <mat-icon color="primary">settings_applications</mat-icon>
    <span i18n>Nastavení společnosti</span>
  </mat-card-title>
  <div class="company-settings__container flex" *ngIf="companySettings">
    <form [formGroup]="companyFormGroup" class="company-settings__forms" >

      <mat-accordion class="company-settings__accordion" >
        <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
          <mat-expansion-panel-header>
            <mat-panel-title><mat-icon>edit</mat-icon><p i18n>Obecné nastavení</p></mat-panel-title>
            <mat-panel-description></mat-panel-description>
          </mat-expansion-panel-header>

          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n> Název společnosti</mat-label>
              <input matInput i18n-placeholder placeholder="Jméno" formControlName="name" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>IČO</mat-label>
              <input matInput i18n-placeholder placeholder="IČO" formControlName="regnum" >
            </mat-form-field>
            
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>DIČ</mat-label>
              <input matInput i18n-placeholder placeholder="DIČ" formControlName="vatnum" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Ulice</mat-label>
              <input type="text" matInput i18n-placeholder placeholder="Ulice" formControlName="street" >
            </mat-form-field>

            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Město</mat-label>
              <input  type="text" matInput i18n-placeholder placeholder="Město" formControlName="city" >
            </mat-form-field>

            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>PSČ</mat-label>
              <input matInput i18n-placeholder placeholder="PSČ" formControlName="zip" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group"> 
            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Kontaktní email</mat-label>
              <input  type="email" matInput i18n-placeholder placeholder="Kontaktní email" formControlName="emailPrimary" >
            </mat-form-field>

            <mat-form-field class="u__flex--halfrow ">
              <mat-label i18n>Fakturační email</mat-label>
              <input matInput i18n-placeholder placeholder="Fakturační email" formControlName="emailInvoice" >
            </mat-form-field>
          </div>
          <div class="company-settings__form-group flex-col mb-5"> 
            <h4 i18n>Logo společnosti</h4>
            <div>
              <app-file-upload [preview]="companySettings.logo?.toString()" i18n-placeholder placeholder="Nahrejte nové logo" (fileChange)="onLogoChange($event)"  [progress]="progress"></app-file-upload>
            </div>
          </div>

          <mat-action-row>
            <button (click)="updateCompanyData()" color="primary" mat-raised-button i18n>Uložit změny</button>
          </mat-action-row>
        </mat-expansion-panel>
      
      </mat-accordion>
    </form>
    <div class="pictogram pictogram--vertical pictogram--hcentered">
      <div>
          <svg id="Vrstva_2" data-name="Vrstva 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs>
              <style>
                  .opacity-25 {
                    opacity: .25;
                  }
            
                  .opacity-0 {
                    opacity: 0;
                  }
            
                  .opacity-50 {
                    opacity: .5;
                  }
              </style>
            </defs>
            <g id="Vrstva_1-2" data-name="Vrstva 1">
              <g>
                <g>
                  <path class="pictogram--primary" d="m397.96,249.86c-3.9-3.41-7.26-4.8-11.63-4.8s-8.39,1.57-11.49,4.67l-15.97,15.98v-101.41c0-.6-.56-1.81-1.1-2.39-.29-.44-.67-.83-1.11-1.12l-43.06-43.06c-1.02-1.02-1.82-1.16-2.47-1.16h-148.14c-1.89,0-3.62,1.73-3.62,3.62v269.87c0,1.89,1.73,3.62,3.62,3.62h191.75c1.88,0,3.73-1.75,4.12-3.85v-78.4l38.82-38.57c3.18-3.64,4.8-7.56,4.8-11.63s-1.74-8.56-4.52-11.35Zm-46.33,137.06h-185.02V123.8h140.4v39.99c0,1.89,1.73,3.62,3.62,3.62h40.99v105.53l-56.47,56.48c-.2.23-.6,1.02-.88,1.59l-7.98,23.47-24.57-10.97c-.33-.16-.65-.24-1-.24-.63,0-1.42.24-2.42.74-1.3.65-1.41,2.02-1.41,2.42v13.21l-20.47-10.23-.21-.16c-.26-.2-.59-.29-.98-.29-.77,0-1.61.39-2.25,1.03l-12.53,12.53c-1.38,1.38-1.38,3.55,0,4.93.67.67,1.54,1.03,2.47,1.03s1.8-.37,2.52-1.09l11-10.42,22.87,11.44c.37.19.86.19,1.71.19.17,0,.58-.12.84-.2.13-.04.26-.08.39-.11.9-.82,1.38-1.81,1.38-2.81v-13.49l23.08,10.33c.55.24,1.1.36,1.64.36.8,0,1.54-.27,2.13-.72l25.55-8.67c.56-.28,1.36-.69,1.58-.88l34.01-33.79v68.32Zm-37.37-258.7l31.95,31.95h-31.95v-31.95Zm40.45,177.74l-41,41-19.68,6.56,6.56-19.68,54.12-54.12,9.32-9.32,13.12,13.12-22.44,22.44Zm38.19-38.19l-10.82,10.82-13.03-13.04,10.32-10.32c2-2.2,4.43-3.35,6.95-3.35,2.35,0,4.66,1.01,6.5,2.85,1.82,1.82,2.66,4.03,2.66,6.98,0,.94-.13,1.86-.35,2.74-.48,1.2-1.22,2.31-2.22,3.32Z"/>
                  <path class="pictogram--primary" d="m188.06,284.17h141.62c1.89,0,3.62-1.73,3.62-3.62s-1.72-3.62-3.62-3.62h-141.62c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                  <path class="pictogram--primary" d="m188.06,302.72h123.05c1.8,0,3.62-1.12,3.62-3.62,0-1.89-1.72-3.62-3.62-3.62h-123.05c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62Z"/>
                  <path class="pictogram--primary" d="m252.1,318.15c0-1.89-1.73-3.62-3.62-3.62h-60.42c-1.89,0-3.62,1.73-3.62,3.62s1.73,3.62,3.62,3.62h60.42c1.51,0,3.62-1.82,3.62-3.62Z"/>
                  <rect class="pictogram--primary" x="251.15" y="209.11" width="7.12" height="13.29"/>
                  <rect class="pictogram--primary" x="251.15" y="185.37" width="7.12" height="13.29"/>
                  <rect class="pictogram--primary" x="251.15" y="161.63" width="7.12" height="13.29"/>
                  <rect class="pictogram--primary" x="233.1" y="209.11" width="7.12" height="13.29"/>
                  <rect class="pictogram--primary" x="233.1" y="185.37" width="7.12" height="13.29"/>
                  <rect class="pictogram--primary" x="233.1" y="161.63" width="7.12" height="13.29"/>
                  <path class="pictogram--primary" d="m213.16,248.44c-2.02,0-3.8,1.78-3.8,3.8s1.77,3.8,3.8,3.8h94.15c2.02,0,3.8-1.77,3.8-3.8s-1.78-3.8-3.8-3.8h-2.02l-.02-14.47v-45.27c0-1.75-.73-2.87-2.3-3.49l-26.66-10.58v-23.91h1.9c2.02,0,3.8-1.77,3.8-3.8s-1.78-3.8-3.8-3.8h-65.04c-2.02,0-3.8,1.78-3.8,3.8s1.77,3.8,3.8,3.8h1.9v97.73h-1.9Zm9.5-97.73h46.06v97.73h-46.06v-97.73Zm75.49,97.73h-21.84v-66.29l21.84,8.73v57.56Z"/>
                </g>
                <g class="opacity-50">
                  <path class="pictogram--primary" d="m379.81,201.09c2.47,0,4.47-2,4.47-4.47s-2-4.47-4.47-4.47-4.47,2-4.47,4.47,2,4.47,4.47,4.47Z"/>
                  <path class="pictogram--primary" d="m379.94,331.11c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47Z"/>
                  <path class="pictogram--primary" d="m137.94,361.23c-2.47,0-4.47,2-4.47,4.47s2,4.47,4.47,4.47,4.47-2,4.47-4.47-2-4.47-4.47-4.47Z"/>
                  <path class="pictogram--primary" d="m133.47,226.13c0,2.47,2,4.47,4.47,4.47s4.47-2,4.47-4.47-2-4.47-4.47-4.47-4.47,2-4.47,4.47Z"/>
                  <path class="pictogram--primary" d="m131.41,183.15c2.47,0,4.47-2,4.47-4.47s-2-4.47-4.47-4.47-4.47,2-4.47,4.47,2,4.47,4.47,4.47Z"/>
                  <path class="pictogram--primary" d="m384.76,116.56c0-9.32-7.58-16.9-16.9-16.9s-16.89,7.58-16.89,16.9,7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9Zm-16.9,10.24c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                  <path class="pictogram--primary" d="m126.39,303.66c-9.31,0-16.89,7.58-16.89,16.9s7.58,16.9,16.89,16.9,16.9-7.58,16.9-16.9-7.58-16.9-16.9-16.9Zm0,27.13c-5.74,0-10.24-4.49-10.24-10.24s4.5-10.24,10.24-10.24,10.24,4.5,10.24,10.24-4.5,10.24-10.24,10.24Z"/>
                </g>
                <path class="opacity-25 pictogram--primary" d="m232.98,349.98c.64-.64,1.49-1.03,2.25-1.03.39,0,.72.1.98.29l.21.16,20.47,10.23v-13.21c0-.4.1-1.77,1.41-2.42,1-.5,1.79-.74,2.42-.74.36,0,.67.08,1,.24l24.57,10.97,7.98-23.47c.29-.57.69-1.36.88-1.59l56.47-56.48v-105.53h-40.99c-1.9,0-3.62-1.73-3.62-3.62v-39.99h-140.4v263.12h185.02v-68.32l-34.01,33.79c-.22.19-1.02.6-1.58.88l-25.55,8.67c-.59.45-1.33.72-2.13.72-.54,0-1.09-.12-1.64-.36l-23.08-10.33v13.49c0,1.01-.49,1.99-1.38,2.81-.13.04-.26.07-.39.11-.27.08-.67.2-.84.2-.84,0-1.34,0-1.71-.19l-22.87-11.44-11,10.42c-.72.72-1.6,1.09-2.52,1.09s-1.8-.37-2.47-1.03c-1.38-1.38-1.38-3.54,0-4.93l12.53-12.53Zm-19.81-199.27c-2.02,0-3.8-1.77-3.8-3.8s1.77-3.8,3.8-3.8h65.04c2.02,0,3.8,1.78,3.8,3.8s-1.78,3.8-3.8,3.8h-1.9v23.91l26.66,10.58c1.57.63,2.3,1.74,2.3,3.49v45.27l.02,14.47h2.02c2.02,0,3.8,1.78,3.8,3.8s-1.78,3.8-3.8,3.8h-94.15c-2.02,0-3.8-1.77-3.8-3.8s1.77-3.8,3.8-3.8h1.9v-97.73h-1.9Zm-25.1,126.22h141.62c1.89,0,3.62,1.73,3.62,3.62s-1.72,3.62-3.62,3.62h-141.62c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,18.55h123.05c1.89,0,3.62,1.73,3.62,3.62,0,2.5-1.82,3.62-3.62,3.62h-123.05c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62Zm0,26.29c-1.89,0-3.62-1.73-3.62-3.62s1.73-3.62,3.62-3.62h60.42c1.89,0,3.62,1.73,3.62,3.62,0,1.8-2.11,3.62-3.62,3.62h-60.42Z"/>
              </g>
            </g>
          </svg>
      </div>
      <div class="pictogram__text">
        <h3 i18n>Vaše firemní údaje</h3>
        <div i18n>Nastavte si prosím emailovou adresu pro snadnější komunikaci s Vámi.</div>
      </div>
    </div>
  </div>
  <div *ngIf="!companySettings">
    <h2 i18n>Nebyla nalezena Vaše firma</h2>
    <p i18n>Prosim vyplňte Vaše firemní údaje.</p>
    <br>
    <button (click)="openCompanyRegistration()" mat-raised-button color="primary" i18n>Přidat firemní údaje</button>
  </div>
</mat-card>
