import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { OrderItem, OrderType } from 'src/app/models/orders.model';
import { License } from 'src/app/models/license.model';
import { KeyFilter } from 'src/app/models/filters.model';
import { PlanItem } from 'src/app/models/plan.model';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-order-extend',
  templateUrl: './order-extend.component.html',
  styleUrls: ['./order-extend.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderExtendComponent implements OnChanges{
  public extendFormGroup: FormGroup;
  licenseFilters: KeyFilter[] | null = [];
  @Output() onValidation = new EventEmitter<any>();
  @Output() orderItemUpdate = new EventEmitter<OrderItem[]>();
  @Input() plan?: PlanItem | null;
  @Input() orderItems: OrderItem[]  = [];
  @Input() orderType: OrderType = OrderType.EXTEND;

  noLicenses: boolean = false;
  selectedLicensesId:string[] = [];

  constructor(private fb: FormBuilder) {
    this.extendFormGroup = this.fb.group({
      subscriptionLength: new FormControl('12', Validators.required),
      autoRenew: new FormControl(true, Validators.required),
      subscriptionAlign: new FormControl(false, Validators.required),
    });
    this.extendFormGroup.valueChanges.subscribe((value) => {
      this.updateDataModel(value);
    });
  }
  ngOnInit() {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    this.licenseFilters = [];
    
    if (changes['plan']) {
      this.plan = changes['plan'].currentValue;
    }

    if (changes['orderItems']) {
      this.orderItems = changes['orderItems'].currentValue;
    }

    if (changes['orderType']) {
      this.orderType = changes['orderType'].currentValue;
    }


    if(this.orderItems.length > 0) {
      if (!this.plan) this.plan = this.orderItems[0].license.plan;

      if (this.orderType == OrderType.CHANGE) {
          // create filter for licenses list to view only demo plans
          let planFilter: KeyFilter;
          planFilter = {
            key: 'planId',
            value: null,
          };

          if(this.orderItems[0].license.plan) {
            this.licenseFilters = [...[planFilter]];
          }
      }

      this.selectedLicensesId = this.orderItems.map((item) => {return item.license.id});
      setTimeout(() => {
        this.validateForm();
      
      }, 0);
    }

     // create filter for licenses list to view only same plans
    if (this.orderType == OrderType.EXTEND && this.plan) {
      let planFilter: KeyFilter;
      planFilter = {
        key: 'planId',
        value: this.plan.id,
      };

      if(this.plan) {
        this.licenseFilters = [...[planFilter]];
      }
    } 
  }

  getLastLicenceDate() {
    let lastDate = this.orderItems[0].license.endDate;
    this.orderItems.forEach(item => {
        if(dayjs(lastDate).isBefore(dayjs(item.license.endDate))) {
          lastDate = item.license.endDate;
        }
    });
    return lastDate;
  }

  onLicenseSelection(selectedLicense: any) {
    this.orderItems = selectedLicense.map((license: License) => {
      let newOrderItem = new OrderItem(license);
      if (this.plan) newOrderItem.license.plan = this.plan;
      newOrderItem.license.planId = this.plan!.id;
      newOrderItem.orderType = this.orderType;
      newOrderItem.license.subscriptionLength =
        this.extendFormGroup.get('subscriptionLength')?.value;
      newOrderItem.license.autoRenew =
        this.extendFormGroup.get('autoRenew')?.value;
      newOrderItem.license.subscriptionAlign =
        this.extendFormGroup.get('subscriptionAlign')?.value;
      return newOrderItem;
    });
    let groupName = "";

    if (this.extendFormGroup.get('subscriptionAlign')?.value) {
      groupName = '_' + Math.random().toString(36).substring(2, 9);
    }
    
    this.orderItems.forEach(item => {
      item.license.alignGroup = groupName;
    });

    this.orderItemUpdate.emit(this.orderItems);
    this.validateForm();
  }
  updateDataModel(value: any) {
    if (this.orderItems.length > 0) {
      this.orderItems.forEach((item) => {
        if (value.subscriptionLength) {
          item.license.subscriptionLength = parseInt(value.subscriptionLength);
        }
        item.license.autoRenew = this.extendFormGroup.get('autoRenew')?.value;
        item.license.subscriptionAlign =
          this.extendFormGroup.get('subscriptionAlign')?.value;
      });
      
      this.orderItemUpdate.emit(this.orderItems);
    }
    this.validateForm();
  }
  validateForm() {
    this.onValidation.emit(
      this.extendFormGroup.valid && this.orderItems.length > 0
    );
  }
  isEmptyList(isEmpty: boolean) {
    this.noLicenses = isEmpty;
    if (isEmpty) {
      this.onValidation.emit(false);
    }
  }
}
