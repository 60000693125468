<div class="user-cookies-consent mat-elevation-z6" *ngIf="!cookieConsentValues">
    <div class="user-cookies-consent__wrapper">
        <div class="user-cookies-consent__header">
            <h2 i18n>Nastavení cookies</h2>
        </div>
        <div class="user-cookies-consent__content">
            <div class="user-cookies-consent__message">
                <h3 i18n>Tato stránka používá cookies</h3>
                <p  i18n>Používáme cookies, abychom zajistili co nejlepší provoz na našich webových stránkách. Kliknutím na "Přijmout" souhlasíte s používáním cookies.</p>
            </div>
            <div class="user-cookies-consent__toggles">
                <mat-slide-toggle color="primary" [(ngModel)]="functional" i18n>Fukční</mat-slide-toggle>
                <mat-slide-toggle color="primary" [(ngModel)]="analytics" i18n>Analytické</mat-slide-toggle>
                <mat-slide-toggle color="primary" [(ngModel)]="advertisement" i18n>Marketingové</mat-slide-toggle>
            </div>
        </div>
        <div class="user-cookies-consent__footer">
            <button class="mt-2" color="primary" (click)="acceptAll()" mat-raised-button i18n>Přijmout vše</button>
            <button class="mt-2" color="primary" (click)="close()" mat-raised-button i18n>Přijmout</button>
            <button class="mt-2" color="transparent" (click)="close()" mat-raised-button  i18n>Odmítnout</button>
        </div>
    </div>
</div>
