import { Component, ViewEncapsulation } from '@angular/core';
import { PlanTemplate } from '../models/plan.model';
import { TraceClass } from '@sentry/angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@TraceClass()
export class DashboardComponent {
  planTemplateEnum: typeof PlanTemplate = PlanTemplate;
  public throwTestError(): void {
    throw new Error("Sentry Test Error");
  }
}
