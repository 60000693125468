<div class="login-screen login-recovery">
  <div class="login-screen__illustration" >
    <app-login-illustration></app-login-illustration>
  </div>
  <div class="login-screen__forms">
    <div *ngIf="!loading; else loadingTemplate" class="login-screen__container">
      <form  *ngIf="!emailSent"  [formGroup]="recoveryRequest" (ngSubmit)="onSubmit()">
        <h2 class="login-screen__title" i18n>Reset hesla</h2>
        <p i18n>Zadejte emailovou adresu pro reset hesla.</p>
        <mat-form-field appearance="outline" class="login-screen__input mat-mdc-form-field--fullwidth">
            <mat-label i18n>Přihlašovací email</mat-label>
            <input matInput required  type="email" i18n-placeholder placeholder="Zadejte váš email" name="userEmail" email="true" formControlName="email">
            <mat-error *ngIf="!isValid" i18n>*Vyplňte email ve správném formátu.</mat-error>
        </mat-form-field>
        <div class="login-screen__actions">
          <button type="submit" mat-raised-button color="primary" i18n>Zaslat žádost o nové heslo</button>
        </div>
      </form>
   
      <div *ngIf="emailSent" >
        <h2 class="login-screen__title" i18n>Žádost o změnu hesla</h2>
        <p i18n>Na váš email byl odeslán odkaz pro nastavení nového hesla.</p>
        <p i18n>Prosíme zkontrolujte vaši emailovou schránku a pokračujte kliknutím na odkaz ve zprávě.</p>
        <button  (click)="router.navigate(['/login']);" type="button" mat-raised-button color="primary" i18n>Rozumím</button>
      </div>
    </div>
    <app-login-contact class="login-screen__contact"></app-login-contact>

  </div>
</div>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
    