import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params,
} from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthorizationService } from '../services/authorization.service';
import { Company, CompanyUser } from '../models/company.model';
import { reject } from 'lodash';

@Injectable({ providedIn: 'root' })
export class GuardsComponent {
  constructor(private router: Router, private userSevice: UserService, private authorizationService: AuthorizationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
 
      /*
        const temporaryToken =  await new Promise<string>((resolve, reject) => { // for testing only
          this.authorizationService.getTemporaryToken().subscribe(
            {
              next :(data) => {
                
                resolve(data.data.reloginToken);
            }, error: () => {
              console.log("token error");
              reject(false);
            }
          })
        })  
        console.log("temporaryToken");
        console.log(temporaryToken);
      */

    if (this.userSevice.user) {
      if(route.queryParams['token']) {
        await this.loginUserByToken(route.queryParams['token']).then(()=> {
          return true
        }).catch(()=> {
          this.redirectForUnauthorized()
        });
      } else {
        return true
      }
    } else {
      if(route.queryParams['token']) {
        await this.loginUserByToken(route.queryParams['token']).then(() => 
          {return true}
        ).catch(()=> {
          this.redirectForUnauthorized();
        });
      } else {
        this.redirectForUnauthorized();
        return false
      }
    }
    return false
  }

  loginUserByToken(token: string) {
    return new Promise((resolve, reject) => {
      this.authorizationService.reloginPersonbByToken(token).subscribe({ 
        next: (data)=> {
          this.userSevice.saveLocalUser(data.data);

          if(this.userSevice.user!.person.companies.length>0) {
            let foundCompany = this.userSevice.user?.person.companies.find((companyUser: CompanyUser) => {return companyUser.company.companyId == data.data.companyId})
            this.userSevice.activeCompany = foundCompany ? foundCompany.company as Company : this.userSevice.user?.person.companies[0].company as Company;
          }
          this.router.navigate(['/']);
          resolve(true);
        },
        error: () => {
          console.log("error autorization");
          reject(false);
        },
      })
    })
  }
  authorize() {}
  redirectForUnauthorized() {
    this.router.navigate(['/login']);
  }
}
