

<div  *ngIf="orderPrice" class="order-calculator" [ngClass]="{'order-calculator--highlighted' : theme == 'highlighted' }">
  <div class="order-calculator__column">
      <div class="flex flex-col">
        <span class="order-calculator__item-head" i18n>Sazba DPH</span>
        <span class="order-calculator__item">{{orderPrice.vatRate }}%</span>
      </div>
      <div class="flex flex-col" >
        <span class="order-calculator__item-head" i18n>cena bez DPH</span>
        <span class="order-calculator__item">{{ orderPrice.totalPrice | currency: orderPrice.currency }}</span>
      </div>
      <div class="flex flex-col">
        <span class="order-calculator__item-head" i18n>cena s DPH</span>
        <span class="order-calculator__item">{{ orderPrice.totalPriceWithVat | currency: orderPrice.currency }}</span>
      </div>
  </div>
  <div class="order-calculator__column flex-col">
    <div>
      <div  *ngIf="orderPrice.orderItems[0].priceSummary?.discountPer! > 0">
        <div class="order-calculator__discount">
          <span i18n>Původní cena:</span>
          <span style="text-decoration: line-through;">{{ totalBasePrice | currency: orderPrice.currency }}</span><span i18n>bez DPH</span>
        </div> 
      </div>
      <div class="order-calculator__summary">
        <span *ngIf="!orderPrice.orderItems[0].priceSummary?.discountPer!" class="order-calculator__pricetitle" i18n>Cena celkem:</span>
        <span *ngIf="orderPrice.orderItems[0].priceSummary?.discountPer! > 0" i18n>Cena k úhradě: </span>
        <span class="order-calculator__amount">{{ orderPrice.totalPrice  | currency: orderPrice.currency}} </span><span i18n>bez DPH</span>
      </div>
      <div class="order-calculator__summary">
        <span *ngIf="!orderPrice.orderItems[0].priceSummary?.discountPer!" class="order-calculator__pricetitle" i18n>Cena celkem:</span>
        <span *ngIf="orderPrice.orderItems[0].priceSummary?.discountPer! > 0" i18n>Cena k úhradě: </span>
        <h3 class="order-calculator__amount">{{ orderPrice.totalPriceWithVat  | currency: orderPrice.currency}} </h3><span i18n>s DPH</span>
      </div>
    </div>
  </div>
</div>