import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderItem } from 'src/app/models/orders.model';
import { PlanItem, PlanTemplate } from 'src/app/models/plan.model';
import { Player, PlayerCategory } from 'src/app/models/player.model';
import { PlansService } from 'src/app/plans/plans.service';
import { PlayersService } from 'src/app/players/players.service';
import { CustomUtils } from 'src/app/utils/custom-utils.component';

@Component({
  selector: 'app-order-change',
  templateUrl: './order-change.component.html',
  styleUrls: ['./order-change.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderChangeComponent {
  @Input() orderItem!: OrderItem;
  @Input() plan?: PlanItem | null;
  @Output() orderItemUpdate = new EventEmitter<OrderItem>();
  @Output() onValidation = new EventEmitter<any>();
  validState: any;
  availablePlans: PlanItem[] = [];
  playerCategoryEnum: typeof PlayerCategory = PlayerCategory;
  planTemplateEnum: typeof PlanTemplate = PlanTemplate;
  
  constructor(private fb: FormBuilder, private plansService: PlansService, @Inject(LOCALE_ID) public locale: string) {
  }
  ngOnInit() {
    this.fetchPlans();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['orderItem']) {
      this.orderItem = changes['orderItem'].currentValue;
    }
  }
  fetchPlans() {
    let countryCode = CustomUtils.getCountryCode(this.locale);
    this.plansService.fetchPlans(countryCode, "unknown").subscribe({
      next: (data) => {
        this.availablePlans = data.data.plans as PlanItem[];
      },
      error: (HttpErrorResponse) => {
        console.log(HttpErrorResponse.message);
      },
    });
  }
  choosePlan(plan: PlanItem) {
    this.plan = plan;
    this.orderItem.license.plan = plan;
    this.orderItem.license.planId = plan.id;
    
  }
  onOrdersItemUpdate(orderItems: OrderItem) {
    this.orderItem = orderItems;
    this.orderItemUpdate.emit(this.orderItem);
  }

  validateProductStep(state: any) {
    this.validState = state;
    this.onValidation.emit(state);
  }
}
