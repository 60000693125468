import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-company-error',
  templateUrl: './company-error.component.html',
  styleUrls: ['./company-error.component.scss']
})
export class CompanyErrorComponent {
  isOwner = false;
  errorCode = 400;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) {
    this.errorCode = data.errorCode;
  }
}
