import { getCurrencySymbol, getLocaleCurrencyName } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySelection'
})
export class CurrencySelectionPipe implements PipeTransform {

  transform(object: any, currencyId: string | null): string {
    if (!currencyId) {
      return "Chybí měna";
    }
    let returnedObject = object[currencyId];
    returnedObject = returnedObject + getCurrencySymbol(currencyId, "narrow");
    return  returnedObject
  }

}
