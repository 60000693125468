<div class="login-screen first-registration">
    <div class="login-screen__illustration" >
      <app-login-illustration></app-login-illustration>
    </div>
    <div class="login-screen__forms" *ngIf="!user">
        <form class="login-screen__container" [formGroup]="registration" *ngIf="!loading; else loadingTemplate" (ngSubmit)="onSubmit()">
          <h2 class="text-center" i18n>Registrace</h2> 
          <div class="login-screen__row" >
            <mat-form-field appearance="outline" class="login-screen__input mat-mdc-form-field--half mat-mdc-form-field--rounded" >
              <mat-label i18n>Jméno</mat-label>
              <input matInput type="text"  i18n-placeholder placeholder="Jméno" name="firstname" formControlName="firstname">
              <mat-error class="login-screen__error" *ngIf="f['firstname'].hasError('require')" i18n>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="login-screen__input mat-mdc-form-field--half mat-mdc-form-field--rounded" >
              <mat-label i18n>Příjmení</mat-label>
              <input matInput type="text"  i18n-placeholder placeholder="Příjmení" name="lastname" formControlName="lastname">
              <mat-error class="login-screen__error" *ngIf="f['lastname'].hasError('require')" i18n>{{ getErrorMessage() }}</mat-error>
            </mat-form-field>
          </div>
          <div class="login-screen__row">
            <mat-form-field appearance="outline" class="login-screen__input mat-mdc-form-field--fullwidth mat-mdc-form-field--rounded">
                <mat-label i18n>Přihlašovací email</mat-label>
                <input matInput type="email"  i18n-placeholder placeholder="Přihlašovací email" name="email" formControlName="email">
                <mat-error class="login-screen__error" *ngIf="formError && errorCode == 400" i18n>Váš email je již zaregistrováný.</mat-error>
                <mat-error class="login-screen__error" *ngIf="f['email'].hasError('email') || f['email'].hasError('require')" i18n>Váš email není platný.</mat-error>
            </mat-form-field>
          </div>
          <div class="login-screen__row">
            <mat-form-field appearance="outline" class="mat-mdc-form-field--half mat-mdc-form-field--rounded">
                <mat-label i18n>Heslo</mat-label>
                <input autocomplete="current-password" type="password" minlength="8" required matInput i18n-placeholder placeholder="Heslo"  formControlName="newPassword">
                <mat-error class="login-screen__error" *ngIf="f['newPassword'].hasError('minlength')" i18n>Zadejte heslo minimálně 8 znaků</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="mat-mdc-form-field--half mat-mdc-form-field--rounded">
                <mat-label i18n>Heslo pro ověření</mat-label>
                <input autocomplete="current-password" type="password" matInput i18n-placeholder placeholder="Heslo pro ověření"  formControlName="confirmPassword">
                <mat-error *ngIf="f['confirmPassword'].hasError('mismatchedPasswords')" class="login-screen__error"  i18n>Hesla se neshodují</mat-error>
            </mat-form-field>
        </div>
          <div  class="login-screen__actions">
              <div class="login-screen__agreements mb-5">
                  <mat-checkbox color="primary" formControlName="agreements" required i18n>Souhlasím s <button  mat-button color="primary" (click)="openAgreementsModal()">obchodními podmínkami</button></mat-checkbox>
                  <mat-error class="login-screen__error" *ngIf="f['agreements'].hasError('requiredTrue')" i18n>Musíte souhlasit s podmínkami</mat-error>
              </div>
              <button type="submit" mat-raised-button color="primary" i18n [disabled]="!registration.valid" i18n>Registrovat</button>
              <div class="login-screen__divider  mb-5 mt-5 "><span></span><p>nebo</p><span></span></div>
              <div class="login-screen__registration flex">
                <a  mat-raised-button href="/login" >Přihlásit se</a>
              </div>
            </div>
        </form>
        <app-login-contact class="login-screen__contact"></app-login-contact>

    </div>
    <div class="login-screen__forms" *ngIf="user">
      <div class="login-screen__message">
        <h2 class="login-screen__title" i18n>Děkujeme za vaši registraci</h2>
        <p i18n>Na vaši emailovou schránku <strong> {{ user.email }} </strong>byly odeslány instrukce pro dokončení registrace.</p>
        <p i18n>Prosím zkontrolujte váši emailovou schránku a pokračujte v registraci.</p>
        <a  mat-raised-button href="/login" color="primary">Přihlásit se</a>
      </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>
    